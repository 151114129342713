<mat-toolbar class="toolbar" >
    <mat-toolbar-row class="top-row" [style.height.px]="collapsed ? 0 : null">
        <app-app-menu #appMenu></app-app-menu>
        @if(!deviceDetector.isInstalledApp()){
            <div style="width: 50px; height: 1px;"></div>
        }
        <div class="flex-expand"></div>
        <img alt="DankTank Logo" (click)="goHome()" #logoImg [style.display]="isLandingToolbar ? 'none' : null" class="fade-img pointer main-logo" (load)="logoImg.style.opacity = '1'" width="65px" height="65px" src="https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/media.danktank.cloud/logos/logo-200_x_200.png">
        <div class="flex-expand"></div>
        @if(!deviceDetector.isInstalledApp()){
            <mat-icon (click)="install()" style="margin-right: 10px;" class="pointer fade-in">{{deviceDetector.isTouchOnlyDevice() ? 'install_mobile' : 'install_desktop'}}</mat-icon>
        }
        <app-logged-in-user></app-logged-in-user>
    </mat-toolbar-row>
    <mat-divider></mat-divider>
        <mat-toolbar-row class="bottom-row" [style.height.px]="collapsed ? 0 : null" [style.display]="isLandingToolbar ? 'none' : null">
            <mat-icon (click)="historyService.undo()" class="pointer fade-in"
                [ngClass]="{ 'disabled': historyService.historyIndex <= 0,
                            'undo-redo' : true,
                            'undo' : true
                            }"
                matTooltip="Undo ({{ historyService.historyIndex < 0 ? 0 : historyService.historyIndex }})" matTooltipPosition="below">
                undo
            </mat-icon> 
            <mat-icon (click)="historyService.redo()" class="pointer fade-in"
                [ngClass]="{ 'disabled': historyService.historyIndex >= historyService.history.length - 1,
                            'undo-redo' : true
                            }"
                matTooltip="Redo ({{ historyService.historyIndex < historyService.history.length - 1 ? historyService.history.length - historyService.historyIndex - 1 : 0 }})" matTooltipPosition="below">
                redo
            </mat-icon> 
            <div class="flex-expand"></div>
            @if(appService.gridSection){
                <div class="info-holder fade-in" (click)="appService.gridSection.mode = 2">
                    <h1 class="focus-text-color" style="margin-top: -8px;">{{appService.gridSection.name || 'undefined'}}</h1>
                    @if((appService.gridSection.rootGridSectionWidth || 0) == 0 || (appService.gridSection.rootGridSectionHeight || 0) > 0){
                        <div class="muted-text-color" style="font-size: 12px;">{{Math.round(appService.gridSection.rootGridSectionWidth || 0)}} x {{Math.round(appService.gridSection.rootGridSectionHeight || 0)}}</div>
                    }
                </div>
            }
            <div class="flex-expand"></div>
            @if(supportsFullscreen() && !deviceDetector.isTouchOnlyDevice() && isStable){
                @if(isFullScreen){
                    <mat-icon class="fade-in pointer" style="margin-right: 10px;" (click)="normal()">fullscreen_exit</mat-icon>
                }@else{
                    <mat-icon class="fade-in pointer" style="margin-right: 10px;" (click)="fullScreen()">fullscreen</mat-icon>
                }
            }@else {
                <div style="width: 30px !important;"></div>
            }
            <app-save-and-generate (isSaving)="appMenu.saving = $event" (savedMemesAmount)="appMenu.savedMemes = $event"></app-save-and-generate>
        </mat-toolbar-row>
        <mat-toolbar-row class="expand-row pointer" (click)="collapsed = !collapsed" [style.display]="isLandingToolbar ? 'none' : null">
            <div class="flex-expand"></div>
            <mat-icon 
                class="pointer rotate-icon" 
                [class.rotated]="!collapsed" 
                >
                expand_more
            </mat-icon>
            <div class="flex-expand"></div>
        </mat-toolbar-row>
</mat-toolbar>