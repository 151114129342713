import { Injectable, PLATFORM_ID, Inject, NgZone, RendererFactory2, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';

export enum AppScreen {
  HOME = "HOME",
  DOWNLOAD_SCREEN = "DOWNLOAD SCREEN",
  LANDING_PAGE = "LANDING PAGE",
  ASSET = "ASSET",
  ASSET_CHOOSER = "ASSET BROWSER"
}

export enum ScreenAction {
  CLICK = "CLICK",
  SEARCH = "SEARCH",
  SAVE = "SAVE",
  OPEN = "OPEN",
  APP_MENU_OPEN = "APP MENU OPEN",
  MENU_OPENED = 'Menu Opened',
  MODAL_OPENED = "MODAL OPENED",
  GO_HOME = "GO HOME",
  DOWNLOAD = "DOWNLOAD",
  EDIT = "EDIT",
  LOAD = "LOAD",
  LOADED = "LOADED",
  ERROR = "ERROR",
  SET_PROPERTY = "SET PROPERTY",
  CAPTURE = "CAPTURE SCREENSHOT",
  CANCEL = "CANCEL",
  DELETE = "DELETE",
  CREATE = "CREATE",
}

export interface Info {
  name: string,
  data?: any
}

export interface Action {
  appScreen: AppScreen,
  action: ScreenAction,
  info?: any
}

declare let gtag: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private renderer!: Renderer2;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    rendererFactory: RendererFactory2,
    private zone : NgZone
  ) { 
    if (isPlatformBrowser(this.platformId)) {
      this.renderer = rendererFactory.createRenderer(null, null);
      this.injectTag()
    }
  }

  private injectTag(){
    // Create the script element for the async src
    const analyticsId : string = environment.analyticsId
    const scriptElement = this.renderer.createElement('script');
    scriptElement.async = true;
    scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`

    // Inject the script element into the head
    this.renderer.appendChild(document.head, scriptElement);

    // Create the inline script element
    const inlineScript = this.renderer.createElement('script')
    inlineScript.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${analyticsId}');
    `;

    // Inject the inline script element into the head
    this.renderer.appendChild(document.head, inlineScript)
  }

  track(action: Action) {
    if (isPlatformBrowser(this.platformId) && typeof gtag === 'function') {
      this.zone.runOutsideAngular(() => {
        const toTrack : any = {
          event_category: action.appScreen,
          event_label: action.action, 
          value: action.info
        }
        //console.log(toTrack)
        gtag('event', action.action.toString(), toTrack);
      });
    } else {
      console.warn('Google Analytics is not available in SSR mode.');
    }
  }
}