import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, ElementRef, EventEmitter, HostListener, Inject, Input, Output, PLATFORM_ID, ViewChild, ViewContainerRef } from '@angular/core';
import { Asset, AssetType, Mode } from '../../vo/GridSection';
import { NgxMoveableModule, NgxMoveableComponent } from 'ngx-moveable';
import { EditMode, ToolbarService } from '../../service/toolbar.service';
import { debounceTime, distinctUntilChanged, filter, fromEvent, interval, map, mapTo, merge, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { observeObject } from '../../util/ObserveUtil';
import { AnalyticsService, AppScreen, ScreenAction, Action } from '../../service/analytics.service';
import { HistoryRecordType, HistoryService } from '../../service/history.service';
import { DeviceDetectorService } from '../../service/device-detector.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip, MatTooltipModule, TooltipComponent } from '@angular/material/tooltip';
import { KeyboardService, KeyboardShortcut } from '../../service/keyboard.service';
import { ScrubberService } from '../../service/scrubber.service';
import { HapticService } from '../../service/haptic.service';
import { AppService } from '../../service/app.service';
import { PresetsService } from '../../service/presets.service';
import { ContextMenuAction, ContextMenuService } from '../../service/context-menu.service';

@Component({
  selector: 'app-asset',
  standalone: true,
  imports: [NgxMoveableModule, MatButtonModule, MatIconModule, CommonModule, MatTooltipModule],
  templateUrl: './asset.component.html',
  styleUrl: './asset.component.scss'
})
export class AssetComponent implements AfterViewInit {

  //this needs to be set by the child component
  public moveableAsset ?: HTMLElement

  private _data ?: Asset
  @Input() public set data(asset : Asset){
    this._data = asset
    //this.registerKeyboardActions()
    if(asset?.section?.activeAsset == asset)this.updateActiveAsset()
  }

  public get data(): Asset | undefined{
    return this._data
  }


  readonly AssetType = AssetType

  readonly Mode = Mode

  public keepRatio : boolean = false

  constructor(private vcRef : ViewContainerRef, private contextMenuService : ContextMenuService, private presetsService : PresetsService, private presetService : PresetsService, private historyService : HistoryService, private appService : AppService, private hapticService : HapticService, private scrubberService: ScrubberService, protected cdr : ChangeDetectorRef, @Inject(PLATFORM_ID) protected platformId: Object, private history : HistoryService, private analyticsService : AnalyticsService, public toolbarService : ToolbarService, protected elRef : ElementRef, protected destroyRef : DestroyRef, private deviceDetector : DeviceDetectorService, protected bottomSheet : MatBottomSheet, private keyboardService : KeyboardService){
    
  }
  
  @ViewChild('moveable') moveable ?: NgxMoveableComponent;
  //@ViewChild('assetChild') assetChild ?: AssetComponent;

  private _assetChild ?: AssetComponent
  public set assetChild(c : any){
    this._assetChild = c
  }
  public get assetChild() : AssetComponent | undefined{
    return this._assetChild
  }

  @Output() onMoveableAssetSet = new EventEmitter<HTMLElement>();
  @Output() executeMethod = new EventEmitter<string>();

  private lastClickTime : number = 0; // Track time of last click

  ngAfterViewInit(): void {
    fromEvent<MouseEvent>(this.elRef.nativeElement.parentElement.parentElement, 'click').pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((e : MouseEvent) => {
      const t = e.target
      if(Array.from((e?.target as HTMLElement)?.classList).some((className : string) => className.includes('scrub')))return
      if (this.data?.section?.mode === Mode.VIEWING) return;
      this.resetActiveAsset();
    });
  
    const click = fromEvent(this.elRef.nativeElement, 'click')
    const touch = fromEvent(this.elRef.nativeElement, 'touchstart')
    
    //const m = merge(click, touch)
    
    //const click = fromEvent(this.elRef.nativeElement, 'click')
    const m = merge(click, touch)
    .pipe(
        map((clickEvent: any) => {
          clickEvent.stopPropagation();
          //clickEvent.preventDefault()
          const currentTime = Date.now();
          const timeDiff = currentTime - this.lastClickTime;
          this.lastClickTime = currentTime; // Update last click time
          if (timeDiff < (this.isMobile() ? 500 : 200)) {
            this.edit()
            //this.executeMethod.emit('edit')
            return 'doubleClick';
          } else {
            this.updateActiveAsset()
            //this.executeMethod.emit('updateActiveAsset')
            return 'singleClick';
          }
        }),
        debounceTime( // Debounce with mobile/PC logic (adjust times as needed)
          this.isMobile() ? 300 : 50
        ),
        distinctUntilChanged() // Emit only if click timestamp or type changes
      );
      
  
    m.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event : unknown) => {
      console.log(event)
      if (event === 'doubleClick') { 
        console.log('Double Click Detected!');
        //this.edit()
      } else {
        console.log('Single Click!');
        //this.updateActiveAsset()
      }
    });

    fromEvent(this.elRef.nativeElement.parentElement, 'resize').pipe(takeUntilDestroyed(this.destroyRef)).subscribe(d =>{
      this.moveable?.updateRect()
    })

    fromEvent<MouseEvent>(this.elRef.nativeElement, 'contextmenu')
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((event: MouseEvent) => {
      this.contextMenuService.removeMenu()
      event.preventDefault();
      event.stopImmediatePropagation()
  
      const actions : ContextMenuAction[] = [
        {label : "Clone", scope : this, callback : this.cloneMe, icon : 'control_point_duplicate'},
        {label : "Delete", scope : this, callback : this.delete, icon : 'delete'}
      ]
      

      const transform = this?.data?.data.transform || '';
      const matrix = new DOMMatrix(transform);
      let translateX = matrix.m41 + event.offsetX
      let translateY = matrix.m42 + event.offsetY

      const coordinates = { x: translateX, y: translateY };
  
      this.contextMenuService.showContextMenuWithElement(this.vcRef, actions, coordinates)
    })
    
  }

 protected updateActiveAsset(){
    //if(this?.data?.section && this.data.section.activeAsset == this.data)return
    if(this.data?.section?.mode == Mode.VIEWING)return
    console.log("BEFORE EDITING", this.data?.data.editing)
    if(this.data?.data.editing)return
    console.log("AFTER EDITING", this.data?.data.editing)
    this.resetActiveAsset()
    if(this.data?.section){
      this.data.section.activeAsset = this.data
      this.presetService.activeAssetElement = this.elRef.nativeElement
      this.scrubberService.setupScrubbers.next(this.data)
    }
    this.registerKeyboardActions()
  }

  private registerKeyboardActions(){
      this.keyboardService.registerCallback(KeyboardShortcut.PASTE, this.cloneMe, this)
      this.keyboardService.registerCallback(KeyboardShortcut.ARROW_RIGHT, this.moveRight, this)
      this.keyboardService.registerCallback(KeyboardShortcut.ARROW_LEFT, this.moveLeft, this)
      this.keyboardService.registerCallback(KeyboardShortcut.ARROW_UP, this.moveUp, this)
      this.keyboardService.registerCallback(KeyboardShortcut.ARROW_DOWN, this.moveDown, this)
      this.keyboardService.registerCallback(KeyboardShortcut.SIZE_UP, this.sizeUp, this)
      this.keyboardService.registerCallback(KeyboardShortcut.SIZE_DOWN, this.sizeDown, this)
      this.keyboardService.registerCallback(KeyboardShortcut.DELETE, this.delete, this)
  }

  private unregisterKeyboardActions(){
    this.keyboardService.unregisterCallback(KeyboardShortcut.PASTE, this.cloneMe)
    this.keyboardService.unregisterCallback(KeyboardShortcut.ARROW_RIGHT, this.moveRight)    
    this.keyboardService.unregisterCallback(KeyboardShortcut.ARROW_LEFT, this.moveLeft)
    this.keyboardService.unregisterCallback(KeyboardShortcut.ARROW_UP, this.moveUp)
    this.keyboardService.unregisterCallback(KeyboardShortcut.ARROW_DOWN, this.moveDown) 
    this.keyboardService.unregisterCallback(KeyboardShortcut.SIZE_UP, this.sizeUp)
    this.keyboardService.unregisterCallback(KeyboardShortcut.SIZE_DOWN, this.sizeDown)
    this.keyboardService.unregisterCallback(KeyboardShortcut.DELETE, this.delete)
  }

  private delete(){
    //this.appService.gridSection?.removeAsset(this.historyService, this.appService.gridSection.activeAsset)
    //this.toolbarService.editModeSubject.next({ mode: EditMode.BASIC })
    this.presetService.delete(this.data)
    setTimeout(() => {
      this.resetActiveAsset()
    }, 300);
  }

  private cloneMe(){
    if(this.data)this.data.section?.cloneAsset(this.data, this.toolbarService)
  }

  private moveRight(){
    this.updateTransformDirection('right', 2)
  }

  private moveLeft(){
    this.updateTransformDirection('left', 2)
  }

  private moveUp(){
    this.updateTransformDirection('up', 2)
  }

  private moveDown(){
    this.updateTransformDirection('down', 2)
  }

  private sizeUp() {
    if(this?.data){
      if(this.data.type == AssetType.TEXT && this.data.data.size){
        this.data.data.size +=4
      }else{
        if(this.data.data.width && this.data.data.height)this.data.data.width = this.data.data.height += 8
      }
    }
  }

  private sizeDown() {
    if(this?.data){
      if(this.data.type == AssetType.TEXT && this.data.data.size){
        this.data.data.size -=4
      }else{
        if(this.data.data.width && this.data.data.height)this.data.data.width = this.data.data.height -= 8
      }
    }
  }

  protected resetActiveAsset(skipEmission : boolean = false){
    this.unregisterKeyboardActions()
    if(this.data?.section?.mode == Mode.VIEWING)return
    if(this.data?.section){
      this.data.section.activeAsset = undefined
    }
    if(this.data?.data)this.data.data.editing = false
    if(!skipEmission)this.toolbarService.editModeSubject.next({mode : EditMode.BASIC})
  }

  protected edit(){
    if(this.data?.section?.mode == Mode.VIEWING)return
    if(this.data?.data)this.data.data.editing = true
    console.log("set editing to true")
    this.assetChild?.edit()
  }

  public show(tooltip : MatTooltip){
    const transform = this?.data?.data.transform || '';
    const matrix = new DOMMatrix(transform);
    let translateX = Math.round(matrix.m41);
    let translateY = Math.round(matrix.m42);
    const tt : TooltipComponent | null = tooltip._tooltipInstance
    const ttWidth : number = tt ? tt._tooltip.nativeElement.clientWidth / 2 : 0
    tooltip.show(0)
    if(tt){
      const ttWidth : number = tt._tooltip.nativeElement.clientWidth / 2
      tt._tooltip.nativeElement.style.left = `${ttWidth * -1}px`
      //tt._tooltip.nativeElement.style.left = `${translateX - ttWidth}px`
      //tt._tooltip.nativeElement.style.top = `$-10px`
    }
    //console.log()
  }
  public get tooltip() : string{
    if(this.interaction == 'drag'){
      const transform = this?.data?.data.transform || '';
      const matrix = new DOMMatrix(transform);
      let translateX = Math.round(matrix.m41);
      let translateY = Math.round(matrix.m42);
      return `${translateX}px:${translateY}px`
    }else if(this.interaction == 'resize'){
      return `${Math.round(this.data?.data.width || 0)}px:${Math.round(this.data?.data.height || 0)}px`
    }else{
      return ''
    }
  }

  public interacting : boolean = false
  public snapDirections: any = { left: true, top: true, right: true, bottom: true, center: true, middle: true };

  public get verticalGuidelines(): number[] {
    if (this.data && this.data.section?.activeAsset) {
      const asset: Asset = this.data.section.activeAsset;
      const x: number = asset.data.x * -1; // Starting point (negative)
      const h: number = this.data.section.rootGridSectionWidth + x; // Max width including y offset
  
      const guidelines: number[] = [
        x,                                // 0%
        x + (h - x) * 0.25,               // 25%
        x + (h - x) * 0.5,                // 50%
        x + (h - x) * 0.75,                // 50%
        x + (h - x)                       // 100%
      ];
  
      return guidelines;
    } else {
      return [];
    }
  }

  public get horizontalGuidelines(): number[] {
    if (this.data && this.data.section?.activeAsset) {
      const asset: Asset = this.data.section.activeAsset;
      const y: number = asset.data.y * -1; // Starting point (negative)
      const w: number = this.data.section.rootGridSectionHeight + y; // Max width including y offset
  
      const guidelines: number[] = [
        y,                                // 0%
        y + (w - y) * 0.25,               // 25%
        y + (w - y) * 0.5,                // 50%
        y + (w - y) * 0.75,                // 50%
        y + (w - y)                       // 100%
      ];
  
      return guidelines;
    } else {
      return [];
    }
  }


  onResize(e : any) {
    //e.target.style.width = `${e.width}px`;
    //e.target.style.height = `${e.height}px`;
    //e.target.style.transform = e.drag.transform;
    if(this.data?.data)this.data.data.transform = e.transform
    if(this.data?.data)this.data.data.width = e.width
    if(this.data?.data)this.data.data.height = e.height
    this.assetChild?.onResize(e)
    this.historyService.createHistoryEntry(HistoryRecordType.ASSET_PROPERTY, 'resize')
  }
  onDrag(e : any) {
    if(this.data?.data)this.data.data.transform = e.transform
    this.assetChild?.onDrag(e)
   // e.target.style.transform = e.transform;
   this.historyService.createHistoryEntry(HistoryRecordType.ASSET_PROPERTY, 'drag')
  }

  onPinch(e : any){
    this.onResize(e)
    this.historyService.createHistoryEntry(HistoryRecordType.ASSET_PROPERTY, 'pinch')
  }

  onRotate(e : any) {
    if(this.data?.data)this.data.data.transform = e.transform
    this.assetChild?.onRotate(e)
    //e.target.style.transform = e.drag.transform;
    this.historyService.createHistoryEntry(HistoryRecordType.ASSET_PROPERTY, 'rotate')
  } 

  private interaction ?: 'drag' | 'resize' | undefined
  startInteracting(interaction : 'drag' | 'resize' | undefined = undefined){
    this.interaction = interaction
    //this.history.queueHistoryEntry(HistoryRecordType.ASSET_PROPERTY, 'transform')
    this.assetChild?.startInteracting()
    this.interacting = true
  }

  endInteracting(){
    this.interaction = undefined
    //this.history.applyQueuedHistoryEntry('transform')
    this.assetChild?.endInteracting()
    this.interacting = false
  }

  /*
  onKeydown(event: any) {
    if (this?.data?.section?.activeAsset === this.data) {
      if (event.key === 'Delete') {
        // Handle the Backspace and Delete key strokes here
        this.data?.section?.removeAsset(this.history, this.data)
        this.toolbarService.editModeSubject.next({mode : EditMode.BASIC})
      }
    }
  }
    */

  updateTransformDirection(direction: 'up' | 'down' | 'left' | 'right', pixels: number): void {
    // Get the current transform string
    const transform = this?.data?.data.transform || '';
  
    // Parse the transform matrix
    const matrix = new DOMMatrix(transform);
  
    // Extract current translate values
    let translateX = matrix.m41;
    let translateY = matrix.m42;
  
    // Update translate values based on direction
    switch (direction) {
      case 'right':
        translateX += pixels;
        break;
      case 'left':
        translateX -= pixels;
        break;
      case 'down':
        translateY += pixels;
        break;
      case 'up':
        translateY -= pixels;
        break;
    }
    
    /*
    // Create a new matrix with updated translation values
    const newMatrix = new DOMMatrix()
      .translateSelf(translateX, translateY);
  
    // Convert matrix to transform string
    const newTransform = newMatrix.toString();
  
    // Update the transform property
    if(this.data)this.data.data.transform = newTransform;
    */
   if(this.data)this.data.section?.updateMatrixOnAsset(this.data, translateX, translateY)

  }

  getTransform(): string {
    const transform = this.data?.data.transform;
  
    // Parse the transform matrix
    const matrix = new DOMMatrix(transform);
  
    // Extract translation and rotation
    let translateX = matrix.m41 + (this.moveableAsset?.clientWidth || 0);
    let translateY = matrix.m42 - 50;
    const rotation = Math.atan2(matrix.m21, matrix.m11); // In radians
  
    // Check for bounds
    const buttonWidth : number = 35
    const actualWidth : number = this.elRef.nativeElement.clientWidth - this.elRef.nativeElement.offsetLeft - buttonWidth
    const lessThanVerticalBounds = translateY < -(this.elRef.nativeElement.offsetTop);
    const greaterThanHorizontalBounds = translateX > actualWidth;
    //console.log('translateY', translateY)
    //console.log('val', -(this.elRef.nativeElement.offsetTop - buttonWidth))

    if(greaterThanHorizontalBounds){
      const toSet : number = matrix.m41 - buttonWidth
      translateX = toSet >= 0 ? toSet : 0
    }
    if(lessThanVerticalBounds)translateY = matrix.m42 + (this.moveableAsset?.clientHeight || 0)

  
    // Apply only the location transforms
    //const toReturn: string = `translate(${translateX}px, ${translateY}px) rotate(${rotation}rad)`;
    const toReturn: string = `translate(${translateX}px, ${translateY}px)`;
  
    // You can use the variables as needed, for example:
    //console.log(`Vertical Bounds Exceeded: ${lessThanVerticalBounds}`);
    //console.log(`Horizontal Bounds Exceeded: ${greaterThanHorizontalBounds}`);
  
    return toReturn;
  }




isMobile() {
  return this.deviceDetector.isTouchOnlyDevice()
  // Detect mobile environment (replace with your preferred method)
  return /iPhone|iPad|iPod|Android|BlackBerry|IEMobile/i.test(navigator.userAgent);
}


////////////////Analytics stuff
readonly ScreenAction = ScreenAction
a(n : string, d ?: string, sa ?: ScreenAction){
  const action : Action = { appScreen : AppScreen.ASSET, action : sa || ScreenAction.SET_PROPERTY, info : { name : n, data: d }}
  this.analyticsService.track(action)
}


//context menu







}
