<mat-icon class="pointer" #infoButton="matMenuTrigger" #menuTrigger="matMenuTrigger" mat-mini-fab [matMenuTriggerFor]="infoMenu" (menuOpened)="menuOpened()">
menu
</mat-icon>
<mat-menu #infoMenu [hasBackdrop]="!appService.tourMode">
    @if(showDeveloperTools()){
    <button [disabled]="appService.tourMode" mat-menu-item matTooltip="Copy Data Model" matTooltipPosition="left" [cdkCopyToClipboard]="getDataModel()">
        <mat-icon>terminal</mat-icon>
        <span class="subtle-gradient-text">Copy Data Model</span>
    </button>
    <mat-divider></mat-divider>
    }
    <button [disabled]="appService.tourMode" mat-menu-item matTooltip="Contact Us / Submit a meme" matTooltipPosition="left" (click)="contactUs()">
        <mat-icon>mail</mat-icon>
        <span class="subtle-gradient-text">Contact Us / Submit a meme</span>
    </button>
    <mat-divider></mat-divider>
    <button [disabled]="appService.tourMode" mat-menu-item matTooltip="How To Videos" matTooltipPosition="left" (click)="showHowTo()">
        <mat-icon>play_circle</mat-icon>
        <span class="subtle-gradient-text">How To Videos</span>
    </button>
    <mat-divider></mat-divider>
    <button [disabled]="appService.tourMode" mat-menu-item matTooltip="About" matTooltipPosition="left" (click)="showAbout()">
        <mat-icon>info</mat-icon>
        <span class="subtle-gradient-text">About</span>
    </button>
    <mat-divider></mat-divider>
    <!--
    <button [disabled]="appService.tourMode" mat-menu-item matTooltip="Guided Tour" matTooltipPosition="left" (click)="startGuidedTour()">
        <mat-icon>tour</mat-icon>
        <span class="subtle-gradient-text">Guided Tour</span>
    </button>
    <mat-divider></mat-divider>
    -->
    @if(!isLandingToolbar){
        <button [disabled]="appService.tourMode" mat-menu-item matTooltip="Drafts" matTooltipPosition="left" (click)="authService.authThen(showSavedMemes, this, this.paymentService.getAllPaid(), PaidFeature.DRAFTS)">
            <div class="flex-button">
                <mat-icon>edit_square</mat-icon>
                <span class="subtle-gradient-text">Drafts</span>
                @if(!saving){
                    <mat-chip-set style="margin-left: 10px;">
                        <mat-chip color="primary" >
                            {{savedMemes}}
                        </mat-chip> 
                    </mat-chip-set>
                }
                @if(saving){<mat-spinner [diameter]="25" style="margin-left: 10px;"></mat-spinner>}
            </div>
        </button>
        <mat-divider></mat-divider>
    }
    <button [disabled]="appService.tourMode" mat-menu-item matTooltip="Templates Popular with our Users" matTooltipPosition="left" (click)="showPopularMemes()">
        <mat-icon>add_reaction</mat-icon>
        <span class="subtle-gradient-text">Popular Templates</span>
    </button>
    <mat-divider></mat-divider>
    <button [disabled]="appService.tourMode" mat-menu-item matTooltip="All Templates" matTooltipPosition="left" (click)="showMemes()">
        <mat-icon>apps</mat-icon>
        <span class="subtle-gradient-text">All Templates</span>
    </button>
    <mat-divider></mat-divider>
    <button [disabled]="appService.tourMode" mat-menu-item matTooltip="GIFS" matTooltipPosition="left" (click)="authService.authThen(openGif, this, paymentService.getPro(), PaidFeature.GIFS)">
        <mat-icon>gif_box</mat-icon>
        <span class="subtle-gradient-text">GIFS</span>
    </button>
    <mat-divider></mat-divider>
    <button [disabled]="appService.tourMode" mat-menu-item matTooltip="Privacy Policy & Terms of Service" matTooltipPosition="left" (click)="showLegal()">
        <mat-icon>policy</mat-icon>
        <span class="subtle-gradient-text">Privacy Policy & Terms of Service</span>
    </button>
</mat-menu>