<mat-icon class="pointer" #saveAndShareButton="matMenuTrigger" mat-mini-fab  matTooltip="Save & Share" [matMenuTriggerFor]="saveAndShareMenu" (menuOpened)="menuOpened()">
    ios_share
</mat-icon>
<mat-menu #saveAndShareMenu [hasBackdrop]="!appService.tourMode" class="save-dropdown">
    <strong class="subtle-gradient-text">{{showSaveInput ? 'Save Draft' : 'Save and share this meme'}}</strong>
    <mat-divider></mat-divider>
    <button class="saveMenuButton save-trigger" [style.height.px]="showSaveInput ? 0 : 50" [disabled]="appService.tourMode" mat-menu-item matTooltip="Save Draft" matTooltipPosition="left" (click)="$event.stopPropagation(); authService.authThen(saveLater2, this, paymentService.getAllPaid(), PaidFeature.DRAFTS)">
        <mat-icon>save</mat-icon>
        <span class="subtle-gradient-text">Save Draft</span>
    </button>
    <div [style.width.px]="showSaveInput ? 400 : 200" [style.height.px]="showSaveInput ? 125 : 0" class="save-input">
            <mat-form-field class="save-field" appearance="outline" (click)="$event.stopPropagation()">
                <mat-label>Name</mat-label>
                <input style="padding-bottom: 0px !important;" #gridNameInput matInput (keydown.enter)="gridNameInput.value && save(gridNameInput.value)" [ngModel]="appService.gridSection?.name">
            </mat-form-field>
            <div class="save-button-holder">
                @if(!saving){
                    <!--
                    <button class="toolbar-button" class="save-draft-button" mat-mini-fab (click)="$event.stopPropagation();showSaveInput = false">
                        <mat-icon>cancel</mat-icon>
                    </button>
                    -->
                    <mat-icon class="save-draft-button pointer" mat-mini-fab (click)="$event.stopPropagation();showSaveInput = false">cancel</mat-icon>
                    <mat-icon class="save-draft-button pointer"  [style.display]="!gridNameInput.value || (gridNameInput.value && gridNameInput.value.length == 0) ? 'none' : null" class="save-draft-button" mat-mini-fab (click)="$event.stopPropagation(); save(gridNameInput.value)">check_circle</mat-icon>
                    <!--
                    <button class="toolbar-button"  [disabled]="!gridNameInput.value || (gridNameInput.value && gridNameInput.value.length == 0)" class="save-draft-button" mat-mini-fab (click)="$event.stopPropagation(); save(gridNameInput.value)">
                        <mat-icon>check_circle</mat-icon>
                    </button>
                    -->
                }@else {
                    <mat-spinner [diameter]="25" style="margin-left: 10px;"></mat-spinner>
                }
            </div>
    </div>
    @if(!showSaveInput){
        <mat-divider></mat-divider>
    }
    <button (click)="captureScreenshot()" class="saveMenuButton" [style.height.px]="showSaveInput ? 0 : 50" [disabled]="appService.tourMode" mat-menu-item matTooltip="Save Draft" matTooltipPosition="left">
        <mat-icon>share</mat-icon>
        <span class="subtle-gradient-text">Generate & Share</span>
    </button>
</mat-menu>