/**
 * @license
 * Copyright 2019 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const t$3 = window,
  e$5 = t$3.ShadowRoot && (void 0 === t$3.ShadyCSS || t$3.ShadyCSS.nativeShadow) && "adoptedStyleSheets" in Document.prototype && "replace" in CSSStyleSheet.prototype,
  s$3 = Symbol(),
  n$5 = /* @__PURE__ */new WeakMap();
let o$5 = class o {
  constructor(t2, e2, n2) {
    if (this._$cssResult$ = true, n2 !== s$3) throw Error("CSSResult is not constructable. Use `unsafeCSS` or `css` instead.");
    this.cssText = t2, this.t = e2;
  }
  get styleSheet() {
    let t2 = this.o;
    const s2 = this.t;
    if (e$5 && void 0 === t2) {
      const e2 = void 0 !== s2 && 1 === s2.length;
      e2 && (t2 = n$5.get(s2)), void 0 === t2 && ((this.o = t2 = new CSSStyleSheet()).replaceSync(this.cssText), e2 && n$5.set(s2, t2));
    }
    return t2;
  }
  toString() {
    return this.cssText;
  }
};
const r$2 = t2 => new o$5("string" == typeof t2 ? t2 : t2 + "", void 0, s$3),
  i$4 = (t2, ...e2) => {
    const n2 = 1 === t2.length ? t2[0] : e2.reduce((e3, s2, n3) => e3 + (t3 => {
      if (true === t3._$cssResult$) return t3.cssText;
      if ("number" == typeof t3) return t3;
      throw Error("Value passed to 'css' function must be a 'css' function result: " + t3 + ". Use 'unsafeCSS' to pass non-literal values, but take care to ensure page security.");
    })(s2) + t2[n3 + 1], t2[0]);
    return new o$5(n2, t2, s$3);
  },
  S$1 = (s2, n2) => {
    e$5 ? s2.adoptedStyleSheets = n2.map(t2 => t2 instanceof CSSStyleSheet ? t2 : t2.styleSheet) : n2.forEach(e2 => {
      const n3 = document.createElement("style"),
        o3 = t$3.litNonce;
      void 0 !== o3 && n3.setAttribute("nonce", o3), n3.textContent = e2.cssText, s2.appendChild(n3);
    });
  },
  c$1 = e$5 ? t2 => t2 : t2 => t2 instanceof CSSStyleSheet ? (t3 => {
    let e2 = "";
    for (const s2 of t3.cssRules) e2 += s2.cssText;
    return r$2(e2);
  })(t2) : t2;
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var s$2;
const e$4 = window,
  r$1 = e$4.trustedTypes,
  h$1 = r$1 ? r$1.emptyScript : "",
  o$4 = e$4.reactiveElementPolyfillSupport,
  n$4 = {
    toAttribute(t2, i2) {
      switch (i2) {
        case Boolean:
          t2 = t2 ? h$1 : null;
          break;
        case Object:
        case Array:
          t2 = null == t2 ? t2 : JSON.stringify(t2);
      }
      return t2;
    },
    fromAttribute(t2, i2) {
      let s2 = t2;
      switch (i2) {
        case Boolean:
          s2 = null !== t2;
          break;
        case Number:
          s2 = null === t2 ? null : Number(t2);
          break;
        case Object:
        case Array:
          try {
            s2 = JSON.parse(t2);
          } catch (t3) {
            s2 = null;
          }
      }
      return s2;
    }
  },
  a$1 = (t2, i2) => i2 !== t2 && (i2 == i2 || t2 == t2),
  l$2 = {
    attribute: true,
    type: String,
    converter: n$4,
    reflect: false,
    hasChanged: a$1
  },
  d$1 = "finalized";
let u$1 = class u extends HTMLElement {
  constructor() {
    super(), this._$Ei = /* @__PURE__ */new Map(), this.isUpdatePending = false, this.hasUpdated = false, this._$El = null, this._$Eu();
  }
  static addInitializer(t2) {
    var i2;
    this.finalize(), (null !== (i2 = this.h) && void 0 !== i2 ? i2 : this.h = []).push(t2);
  }
  static get observedAttributes() {
    this.finalize();
    const t2 = [];
    return this.elementProperties.forEach((i2, s2) => {
      const e2 = this._$Ep(s2, i2);
      void 0 !== e2 && (this._$Ev.set(e2, s2), t2.push(e2));
    }), t2;
  }
  static createProperty(t2, i2 = l$2) {
    if (i2.state && (i2.attribute = false), this.finalize(), this.elementProperties.set(t2, i2), !i2.noAccessor && !this.prototype.hasOwnProperty(t2)) {
      const s2 = "symbol" == typeof t2 ? Symbol() : "__" + t2,
        e2 = this.getPropertyDescriptor(t2, s2, i2);
      void 0 !== e2 && Object.defineProperty(this.prototype, t2, e2);
    }
  }
  static getPropertyDescriptor(t2, i2, s2) {
    return {
      get() {
        return this[i2];
      },
      set(e2) {
        const r2 = this[t2];
        this[i2] = e2, this.requestUpdate(t2, r2, s2);
      },
      configurable: true,
      enumerable: true
    };
  }
  static getPropertyOptions(t2) {
    return this.elementProperties.get(t2) || l$2;
  }
  static finalize() {
    if (this.hasOwnProperty(d$1)) return false;
    this[d$1] = true;
    const t2 = Object.getPrototypeOf(this);
    if (t2.finalize(), void 0 !== t2.h && (this.h = [...t2.h]), this.elementProperties = new Map(t2.elementProperties), this._$Ev = /* @__PURE__ */new Map(), this.hasOwnProperty("properties")) {
      const t3 = this.properties,
        i2 = [...Object.getOwnPropertyNames(t3), ...Object.getOwnPropertySymbols(t3)];
      for (const s2 of i2) this.createProperty(s2, t3[s2]);
    }
    return this.elementStyles = this.finalizeStyles(this.styles), true;
  }
  static finalizeStyles(i2) {
    const s2 = [];
    if (Array.isArray(i2)) {
      const e2 = new Set(i2.flat(1 / 0).reverse());
      for (const i3 of e2) s2.unshift(c$1(i3));
    } else void 0 !== i2 && s2.push(c$1(i2));
    return s2;
  }
  static _$Ep(t2, i2) {
    const s2 = i2.attribute;
    return false === s2 ? void 0 : "string" == typeof s2 ? s2 : "string" == typeof t2 ? t2.toLowerCase() : void 0;
  }
  _$Eu() {
    var t2;
    this._$E_ = new Promise(t3 => this.enableUpdating = t3), this._$AL = /* @__PURE__ */new Map(), this._$Eg(), this.requestUpdate(), null === (t2 = this.constructor.h) || void 0 === t2 || t2.forEach(t3 => t3(this));
  }
  addController(t2) {
    var i2, s2;
    (null !== (i2 = this._$ES) && void 0 !== i2 ? i2 : this._$ES = []).push(t2), void 0 !== this.renderRoot && this.isConnected && (null === (s2 = t2.hostConnected) || void 0 === s2 || s2.call(t2));
  }
  removeController(t2) {
    var i2;
    null === (i2 = this._$ES) || void 0 === i2 || i2.splice(this._$ES.indexOf(t2) >>> 0, 1);
  }
  _$Eg() {
    this.constructor.elementProperties.forEach((t2, i2) => {
      this.hasOwnProperty(i2) && (this._$Ei.set(i2, this[i2]), delete this[i2]);
    });
  }
  createRenderRoot() {
    var t2;
    const s2 = null !== (t2 = this.shadowRoot) && void 0 !== t2 ? t2 : this.attachShadow(this.constructor.shadowRootOptions);
    return S$1(s2, this.constructor.elementStyles), s2;
  }
  connectedCallback() {
    var t2;
    void 0 === this.renderRoot && (this.renderRoot = this.createRenderRoot()), this.enableUpdating(true), null === (t2 = this._$ES) || void 0 === t2 || t2.forEach(t3 => {
      var i2;
      return null === (i2 = t3.hostConnected) || void 0 === i2 ? void 0 : i2.call(t3);
    });
  }
  enableUpdating(t2) {}
  disconnectedCallback() {
    var t2;
    null === (t2 = this._$ES) || void 0 === t2 || t2.forEach(t3 => {
      var i2;
      return null === (i2 = t3.hostDisconnected) || void 0 === i2 ? void 0 : i2.call(t3);
    });
  }
  attributeChangedCallback(t2, i2, s2) {
    this._$AK(t2, s2);
  }
  _$EO(t2, i2, s2 = l$2) {
    var e2;
    const r2 = this.constructor._$Ep(t2, s2);
    if (void 0 !== r2 && true === s2.reflect) {
      const h2 = (void 0 !== (null === (e2 = s2.converter) || void 0 === e2 ? void 0 : e2.toAttribute) ? s2.converter : n$4).toAttribute(i2, s2.type);
      this._$El = t2, null == h2 ? this.removeAttribute(r2) : this.setAttribute(r2, h2), this._$El = null;
    }
  }
  _$AK(t2, i2) {
    var s2;
    const e2 = this.constructor,
      r2 = e2._$Ev.get(t2);
    if (void 0 !== r2 && this._$El !== r2) {
      const t3 = e2.getPropertyOptions(r2),
        h2 = "function" == typeof t3.converter ? {
          fromAttribute: t3.converter
        } : void 0 !== (null === (s2 = t3.converter) || void 0 === s2 ? void 0 : s2.fromAttribute) ? t3.converter : n$4;
      this._$El = r2, this[r2] = h2.fromAttribute(i2, t3.type), this._$El = null;
    }
  }
  requestUpdate(t2, i2, s2) {
    let e2 = true;
    void 0 !== t2 && (((s2 = s2 || this.constructor.getPropertyOptions(t2)).hasChanged || a$1)(this[t2], i2) ? (this._$AL.has(t2) || this._$AL.set(t2, i2), true === s2.reflect && this._$El !== t2 && (void 0 === this._$EC && (this._$EC = /* @__PURE__ */new Map()), this._$EC.set(t2, s2))) : e2 = false), !this.isUpdatePending && e2 && (this._$E_ = this._$Ej());
  }
  async _$Ej() {
    this.isUpdatePending = true;
    try {
      await this._$E_;
    } catch (t3) {
      Promise.reject(t3);
    }
    const t2 = this.scheduleUpdate();
    return null != t2 && (await t2), !this.isUpdatePending;
  }
  scheduleUpdate() {
    return this.performUpdate();
  }
  performUpdate() {
    var t2;
    if (!this.isUpdatePending) return;
    this.hasUpdated, this._$Ei && (this._$Ei.forEach((t3, i3) => this[i3] = t3), this._$Ei = void 0);
    let i2 = false;
    const s2 = this._$AL;
    try {
      i2 = this.shouldUpdate(s2), i2 ? (this.willUpdate(s2), null === (t2 = this._$ES) || void 0 === t2 || t2.forEach(t3 => {
        var i3;
        return null === (i3 = t3.hostUpdate) || void 0 === i3 ? void 0 : i3.call(t3);
      }), this.update(s2)) : this._$Ek();
    } catch (t3) {
      throw i2 = false, this._$Ek(), t3;
    }
    i2 && this._$AE(s2);
  }
  willUpdate(t2) {}
  _$AE(t2) {
    var i2;
    null === (i2 = this._$ES) || void 0 === i2 || i2.forEach(t3 => {
      var i3;
      return null === (i3 = t3.hostUpdated) || void 0 === i3 ? void 0 : i3.call(t3);
    }), this.hasUpdated || (this.hasUpdated = true, this.firstUpdated(t2)), this.updated(t2);
  }
  _$Ek() {
    this._$AL = /* @__PURE__ */new Map(), this.isUpdatePending = false;
  }
  get updateComplete() {
    return this.getUpdateComplete();
  }
  getUpdateComplete() {
    return this._$E_;
  }
  shouldUpdate(t2) {
    return true;
  }
  update(t2) {
    void 0 !== this._$EC && (this._$EC.forEach((t3, i2) => this._$EO(i2, this[i2], t3)), this._$EC = void 0), this._$Ek();
  }
  updated(t2) {}
  firstUpdated(t2) {}
};
u$1[d$1] = true, u$1.elementProperties = /* @__PURE__ */new Map(), u$1.elementStyles = [], u$1.shadowRootOptions = {
  mode: "open"
}, null == o$4 || o$4({
  ReactiveElement: u$1
}), (null !== (s$2 = e$4.reactiveElementVersions) && void 0 !== s$2 ? s$2 : e$4.reactiveElementVersions = []).push("1.6.3");
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var t$2;
const i$3 = window,
  s$1 = i$3.trustedTypes,
  e$3 = s$1 ? s$1.createPolicy("lit-html", {
    createHTML: t2 => t2
  }) : void 0,
  o$3 = "$lit$",
  n$3 = `lit$${(Math.random() + "").slice(9)}$`,
  l$1 = "?" + n$3,
  h = `<${l$1}>`,
  r = document,
  u2 = () => r.createComment(""),
  d = t2 => null === t2 || "object" != typeof t2 && "function" != typeof t2,
  c = Array.isArray,
  v = t2 => c(t2) || "function" == typeof (null == t2 ? void 0 : t2[Symbol.iterator]),
  a = "[ 	\n\f\r]",
  f = /<(?:(!--|\/[^a-zA-Z])|(\/?[a-zA-Z][^>\s]*)|(\/?$))/g,
  _ = /-->/g,
  m = />/g,
  p = RegExp(`>|${a}(?:([^\\s"'>=/]+)(${a}*=${a}*(?:[^ 	
\f\r"'\`<>=]|("|')|))|$)`, "g"),
  g = /'/g,
  $ = /"/g,
  y = /^(?:script|style|textarea|title)$/i,
  w = t2 => (i2, ...s2) => ({
    _$litType$: t2,
    strings: i2,
    values: s2
  }),
  x = w(1),
  T = Symbol.for("lit-noChange"),
  A = Symbol.for("lit-nothing"),
  E = /* @__PURE__ */new WeakMap(),
  C = r.createTreeWalker(r, 129, null, false);
function P(t2, i2) {
  if (!Array.isArray(t2) || !t2.hasOwnProperty("raw")) throw Error("invalid template strings array");
  return void 0 !== e$3 ? e$3.createHTML(i2) : i2;
}
const V = (t2, i2) => {
  const s2 = t2.length - 1,
    e2 = [];
  let l2,
    r2 = 2 === i2 ? "<svg>" : "",
    u3 = f;
  for (let i3 = 0; i3 < s2; i3++) {
    const s3 = t2[i3];
    let d2,
      c2,
      v2 = -1,
      a2 = 0;
    for (; a2 < s3.length && (u3.lastIndex = a2, c2 = u3.exec(s3), null !== c2);) a2 = u3.lastIndex, u3 === f ? "!--" === c2[1] ? u3 = _ : void 0 !== c2[1] ? u3 = m : void 0 !== c2[2] ? (y.test(c2[2]) && (l2 = RegExp("</" + c2[2], "g")), u3 = p) : void 0 !== c2[3] && (u3 = p) : u3 === p ? ">" === c2[0] ? (u3 = null != l2 ? l2 : f, v2 = -1) : void 0 === c2[1] ? v2 = -2 : (v2 = u3.lastIndex - c2[2].length, d2 = c2[1], u3 = void 0 === c2[3] ? p : '"' === c2[3] ? $ : g) : u3 === $ || u3 === g ? u3 = p : u3 === _ || u3 === m ? u3 = f : (u3 = p, l2 = void 0);
    const w2 = u3 === p && t2[i3 + 1].startsWith("/>") ? " " : "";
    r2 += u3 === f ? s3 + h : v2 >= 0 ? (e2.push(d2), s3.slice(0, v2) + o$3 + s3.slice(v2) + n$3 + w2) : s3 + n$3 + (-2 === v2 ? (e2.push(void 0), i3) : w2);
  }
  return [P(t2, r2 + (t2[s2] || "<?>") + (2 === i2 ? "</svg>" : "")), e2];
};
class N {
  constructor({
    strings: t2,
    _$litType$: i2
  }, e2) {
    let h2;
    this.parts = [];
    let r2 = 0,
      d2 = 0;
    const c2 = t2.length - 1,
      v2 = this.parts,
      [a2, f2] = V(t2, i2);
    if (this.el = N.createElement(a2, e2), C.currentNode = this.el.content, 2 === i2) {
      const t3 = this.el.content,
        i3 = t3.firstChild;
      i3.remove(), t3.append(...i3.childNodes);
    }
    for (; null !== (h2 = C.nextNode()) && v2.length < c2;) {
      if (1 === h2.nodeType) {
        if (h2.hasAttributes()) {
          const t3 = [];
          for (const i3 of h2.getAttributeNames()) if (i3.endsWith(o$3) || i3.startsWith(n$3)) {
            const s2 = f2[d2++];
            if (t3.push(i3), void 0 !== s2) {
              const t4 = h2.getAttribute(s2.toLowerCase() + o$3).split(n$3),
                i4 = /([.?@])?(.*)/.exec(s2);
              v2.push({
                type: 1,
                index: r2,
                name: i4[2],
                strings: t4,
                ctor: "." === i4[1] ? H : "?" === i4[1] ? L : "@" === i4[1] ? z : k
              });
            } else v2.push({
              type: 6,
              index: r2
            });
          }
          for (const i3 of t3) h2.removeAttribute(i3);
        }
        if (y.test(h2.tagName)) {
          const t3 = h2.textContent.split(n$3),
            i3 = t3.length - 1;
          if (i3 > 0) {
            h2.textContent = s$1 ? s$1.emptyScript : "";
            for (let s2 = 0; s2 < i3; s2++) h2.append(t3[s2], u2()), C.nextNode(), v2.push({
              type: 2,
              index: ++r2
            });
            h2.append(t3[i3], u2());
          }
        }
      } else if (8 === h2.nodeType) if (h2.data === l$1) v2.push({
        type: 2,
        index: r2
      });else {
        let t3 = -1;
        for (; -1 !== (t3 = h2.data.indexOf(n$3, t3 + 1));) v2.push({
          type: 7,
          index: r2
        }), t3 += n$3.length - 1;
      }
      r2++;
    }
  }
  static createElement(t2, i2) {
    const s2 = r.createElement("template");
    return s2.innerHTML = t2, s2;
  }
}
function S(t2, i2, s2 = t2, e2) {
  var o3, n2, l2, h2;
  if (i2 === T) return i2;
  let r2 = void 0 !== e2 ? null === (o3 = s2._$Co) || void 0 === o3 ? void 0 : o3[e2] : s2._$Cl;
  const u3 = d(i2) ? void 0 : i2._$litDirective$;
  return (null == r2 ? void 0 : r2.constructor) !== u3 && (null === (n2 = null == r2 ? void 0 : r2._$AO) || void 0 === n2 || n2.call(r2, false), void 0 === u3 ? r2 = void 0 : (r2 = new u3(t2), r2._$AT(t2, s2, e2)), void 0 !== e2 ? (null !== (l2 = (h2 = s2)._$Co) && void 0 !== l2 ? l2 : h2._$Co = [])[e2] = r2 : s2._$Cl = r2), void 0 !== r2 && (i2 = S(t2, r2._$AS(t2, i2.values), r2, e2)), i2;
}
class M {
  constructor(t2, i2) {
    this._$AV = [], this._$AN = void 0, this._$AD = t2, this._$AM = i2;
  }
  get parentNode() {
    return this._$AM.parentNode;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  u(t2) {
    var i2;
    const {
        el: {
          content: s2
        },
        parts: e2
      } = this._$AD,
      o3 = (null !== (i2 = null == t2 ? void 0 : t2.creationScope) && void 0 !== i2 ? i2 : r).importNode(s2, true);
    C.currentNode = o3;
    let n2 = C.nextNode(),
      l2 = 0,
      h2 = 0,
      u3 = e2[0];
    for (; void 0 !== u3;) {
      if (l2 === u3.index) {
        let i3;
        2 === u3.type ? i3 = new R(n2, n2.nextSibling, this, t2) : 1 === u3.type ? i3 = new u3.ctor(n2, u3.name, u3.strings, this, t2) : 6 === u3.type && (i3 = new Z(n2, this, t2)), this._$AV.push(i3), u3 = e2[++h2];
      }
      l2 !== (null == u3 ? void 0 : u3.index) && (n2 = C.nextNode(), l2++);
    }
    return C.currentNode = r, o3;
  }
  v(t2) {
    let i2 = 0;
    for (const s2 of this._$AV) void 0 !== s2 && (void 0 !== s2.strings ? (s2._$AI(t2, s2, i2), i2 += s2.strings.length - 2) : s2._$AI(t2[i2])), i2++;
  }
}
class R {
  constructor(t2, i2, s2, e2) {
    var o3;
    this.type = 2, this._$AH = A, this._$AN = void 0, this._$AA = t2, this._$AB = i2, this._$AM = s2, this.options = e2, this._$Cp = null === (o3 = null == e2 ? void 0 : e2.isConnected) || void 0 === o3 || o3;
  }
  get _$AU() {
    var t2, i2;
    return null !== (i2 = null === (t2 = this._$AM) || void 0 === t2 ? void 0 : t2._$AU) && void 0 !== i2 ? i2 : this._$Cp;
  }
  get parentNode() {
    let t2 = this._$AA.parentNode;
    const i2 = this._$AM;
    return void 0 !== i2 && 11 === (null == t2 ? void 0 : t2.nodeType) && (t2 = i2.parentNode), t2;
  }
  get startNode() {
    return this._$AA;
  }
  get endNode() {
    return this._$AB;
  }
  _$AI(t2, i2 = this) {
    t2 = S(this, t2, i2), d(t2) ? t2 === A || null == t2 || "" === t2 ? (this._$AH !== A && this._$AR(), this._$AH = A) : t2 !== this._$AH && t2 !== T && this._(t2) : void 0 !== t2._$litType$ ? this.g(t2) : void 0 !== t2.nodeType ? this.$(t2) : v(t2) ? this.T(t2) : this._(t2);
  }
  k(t2) {
    return this._$AA.parentNode.insertBefore(t2, this._$AB);
  }
  $(t2) {
    this._$AH !== t2 && (this._$AR(), this._$AH = this.k(t2));
  }
  _(t2) {
    this._$AH !== A && d(this._$AH) ? this._$AA.nextSibling.data = t2 : this.$(r.createTextNode(t2)), this._$AH = t2;
  }
  g(t2) {
    var i2;
    const {
        values: s2,
        _$litType$: e2
      } = t2,
      o3 = "number" == typeof e2 ? this._$AC(t2) : (void 0 === e2.el && (e2.el = N.createElement(P(e2.h, e2.h[0]), this.options)), e2);
    if ((null === (i2 = this._$AH) || void 0 === i2 ? void 0 : i2._$AD) === o3) this._$AH.v(s2);else {
      const t3 = new M(o3, this),
        i3 = t3.u(this.options);
      t3.v(s2), this.$(i3), this._$AH = t3;
    }
  }
  _$AC(t2) {
    let i2 = E.get(t2.strings);
    return void 0 === i2 && E.set(t2.strings, i2 = new N(t2)), i2;
  }
  T(t2) {
    c(this._$AH) || (this._$AH = [], this._$AR());
    const i2 = this._$AH;
    let s2,
      e2 = 0;
    for (const o3 of t2) e2 === i2.length ? i2.push(s2 = new R(this.k(u2()), this.k(u2()), this, this.options)) : s2 = i2[e2], s2._$AI(o3), e2++;
    e2 < i2.length && (this._$AR(s2 && s2._$AB.nextSibling, e2), i2.length = e2);
  }
  _$AR(t2 = this._$AA.nextSibling, i2) {
    var s2;
    for (null === (s2 = this._$AP) || void 0 === s2 || s2.call(this, false, true, i2); t2 && t2 !== this._$AB;) {
      const i3 = t2.nextSibling;
      t2.remove(), t2 = i3;
    }
  }
  setConnected(t2) {
    var i2;
    void 0 === this._$AM && (this._$Cp = t2, null === (i2 = this._$AP) || void 0 === i2 || i2.call(this, t2));
  }
}
class k {
  constructor(t2, i2, s2, e2, o3) {
    this.type = 1, this._$AH = A, this._$AN = void 0, this.element = t2, this.name = i2, this._$AM = e2, this.options = o3, s2.length > 2 || "" !== s2[0] || "" !== s2[1] ? (this._$AH = Array(s2.length - 1).fill(new String()), this.strings = s2) : this._$AH = A;
  }
  get tagName() {
    return this.element.tagName;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  _$AI(t2, i2 = this, s2, e2) {
    const o3 = this.strings;
    let n2 = false;
    if (void 0 === o3) t2 = S(this, t2, i2, 0), n2 = !d(t2) || t2 !== this._$AH && t2 !== T, n2 && (this._$AH = t2);else {
      const e3 = t2;
      let l2, h2;
      for (t2 = o3[0], l2 = 0; l2 < o3.length - 1; l2++) h2 = S(this, e3[s2 + l2], i2, l2), h2 === T && (h2 = this._$AH[l2]), n2 || (n2 = !d(h2) || h2 !== this._$AH[l2]), h2 === A ? t2 = A : t2 !== A && (t2 += (null != h2 ? h2 : "") + o3[l2 + 1]), this._$AH[l2] = h2;
    }
    n2 && !e2 && this.j(t2);
  }
  j(t2) {
    t2 === A ? this.element.removeAttribute(this.name) : this.element.setAttribute(this.name, null != t2 ? t2 : "");
  }
}
class H extends k {
  constructor() {
    super(...arguments), this.type = 3;
  }
  j(t2) {
    this.element[this.name] = t2 === A ? void 0 : t2;
  }
}
const I = s$1 ? s$1.emptyScript : "";
class L extends k {
  constructor() {
    super(...arguments), this.type = 4;
  }
  j(t2) {
    t2 && t2 !== A ? this.element.setAttribute(this.name, I) : this.element.removeAttribute(this.name);
  }
}
class z extends k {
  constructor(t2, i2, s2, e2, o3) {
    super(t2, i2, s2, e2, o3), this.type = 5;
  }
  _$AI(t2, i2 = this) {
    var s2;
    if ((t2 = null !== (s2 = S(this, t2, i2, 0)) && void 0 !== s2 ? s2 : A) === T) return;
    const e2 = this._$AH,
      o3 = t2 === A && e2 !== A || t2.capture !== e2.capture || t2.once !== e2.once || t2.passive !== e2.passive,
      n2 = t2 !== A && (e2 === A || o3);
    o3 && this.element.removeEventListener(this.name, this, e2), n2 && this.element.addEventListener(this.name, this, t2), this._$AH = t2;
  }
  handleEvent(t2) {
    var i2, s2;
    "function" == typeof this._$AH ? this._$AH.call(null !== (s2 = null === (i2 = this.options) || void 0 === i2 ? void 0 : i2.host) && void 0 !== s2 ? s2 : this.element, t2) : this._$AH.handleEvent(t2);
  }
}
class Z {
  constructor(t2, i2, s2) {
    this.element = t2, this.type = 6, this._$AN = void 0, this._$AM = i2, this.options = s2;
  }
  get _$AU() {
    return this._$AM._$AU;
  }
  _$AI(t2) {
    S(this, t2);
  }
}
const B = i$3.litHtmlPolyfillSupport;
null == B || B(N, R), (null !== (t$2 = i$3.litHtmlVersions) && void 0 !== t$2 ? t$2 : i$3.litHtmlVersions = []).push("2.8.0");
const D = (t2, i2, s2) => {
  var e2, o3;
  const n2 = null !== (e2 = null == s2 ? void 0 : s2.renderBefore) && void 0 !== e2 ? e2 : i2;
  let l2 = n2._$litPart$;
  if (void 0 === l2) {
    const t3 = null !== (o3 = null == s2 ? void 0 : s2.renderBefore) && void 0 !== o3 ? o3 : null;
    n2._$litPart$ = l2 = new R(i2.insertBefore(u2(), t3), t3, void 0, null != s2 ? s2 : {});
  }
  return l2._$AI(t2), l2;
};
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var l, o$2;
class s extends u$1 {
  constructor() {
    super(...arguments), this.renderOptions = {
      host: this
    }, this._$Do = void 0;
  }
  createRenderRoot() {
    var t2, e2;
    const i2 = super.createRenderRoot();
    return null !== (t2 = (e2 = this.renderOptions).renderBefore) && void 0 !== t2 || (e2.renderBefore = i2.firstChild), i2;
  }
  update(t2) {
    const i2 = this.render();
    this.hasUpdated || (this.renderOptions.isConnected = this.isConnected), super.update(t2), this._$Do = D(i2, this.renderRoot, this.renderOptions);
  }
  connectedCallback() {
    var t2;
    super.connectedCallback(), null === (t2 = this._$Do) || void 0 === t2 || t2.setConnected(true);
  }
  disconnectedCallback() {
    var t2;
    super.disconnectedCallback(), null === (t2 = this._$Do) || void 0 === t2 || t2.setConnected(false);
  }
  render() {
    return T;
  }
}
s.finalized = true, s._$litElement$ = true, null === (l = globalThis.litElementHydrateSupport) || void 0 === l || l.call(globalThis, {
  LitElement: s
});
const n$2 = globalThis.litElementPolyfillSupport;
null == n$2 || n$2({
  LitElement: s
});
(null !== (o$2 = globalThis.litElementVersions) && void 0 !== o$2 ? o$2 : globalThis.litElementVersions = []).push("3.3.3");
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const e$2 = e2 => n2 => "function" == typeof n2 ? ((e3, n3) => (customElements.define(e3, n3), n3))(e2, n2) : ((e3, n3) => {
  const {
    kind: t2,
    elements: s2
  } = n3;
  return {
    kind: t2,
    elements: s2,
    finisher(n4) {
      customElements.define(e3, n4);
    }
  };
})(e2, n2);
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const i$2 = (i2, e2) => "method" === e2.kind && e2.descriptor && !("value" in e2.descriptor) ? {
    ...e2,
    finisher(n2) {
      n2.createProperty(e2.key, i2);
    }
  } : {
    kind: "field",
    key: Symbol(),
    placement: "own",
    descriptor: {},
    originalKey: e2.key,
    initializer() {
      "function" == typeof e2.initializer && (this[e2.key] = e2.initializer.call(this));
    },
    finisher(n2) {
      n2.createProperty(e2.key, i2);
    }
  },
  e$1 = (i2, e2, n2) => {
    e2.constructor.createProperty(n2, i2);
  };
function n$1(n2) {
  return (t2, o3) => void 0 !== o3 ? e$1(n2, t2, o3) : i$2(n2, t2);
}
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function t$1(t2) {
  return n$1({
    ...t2,
    state: true
  });
}
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const o$1 = ({
  finisher: e2,
  descriptor: t2
}) => (o3, n2) => {
  var r2;
  if (void 0 === n2) {
    const n3 = null !== (r2 = o3.originalKey) && void 0 !== r2 ? r2 : o3.key,
      i2 = null != t2 ? {
        kind: "method",
        placement: "prototype",
        key: n3,
        descriptor: t2(o3.key)
      } : {
        ...o3,
        key: n3
      };
    return null != e2 && (i2.finisher = function (t3) {
      e2(t3, n3);
    }), i2;
  }
  {
    const r3 = o3.constructor;
    void 0 !== t2 && Object.defineProperty(o3, n2, t2(n2)), null == e2 || e2(r3, n2);
  }
};
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function i$1(i2, n2) {
  return o$1({
    descriptor: o3 => {
      const t2 = {
        get() {
          var o4, n3;
          return null !== (n3 = null === (o4 = this.renderRoot) || void 0 === o4 ? void 0 : o4.querySelector(i2)) && void 0 !== n3 ? n3 : null;
        },
        enumerable: true,
        configurable: true
      };
      if (n2) {
        const n3 = "symbol" == typeof o3 ? Symbol() : "__" + o3;
        t2.get = function () {
          var o4, t3;
          return void 0 === this[n3] && (this[n3] = null !== (t3 = null === (o4 = this.renderRoot) || void 0 === o4 ? void 0 : o4.querySelector(i2)) && void 0 !== t3 ? t3 : null), this[n3];
        };
      }
      return t2;
    }
  });
}
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
var n;
null != (null === (n = window.HTMLSlotElement) || void 0 === n ? void 0 : n.prototype.assignedElements) ? (o3, n2) => o3.assignedElements(n2) : (o3, n2) => o3.assignedNodes(n2).filter(o4 => o4.nodeType === Node.ELEMENT_NODE);
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const t = {
    ATTRIBUTE: 1,
    CHILD: 2,
    PROPERTY: 3,
    BOOLEAN_ATTRIBUTE: 4,
    EVENT: 5,
    ELEMENT: 6
  },
  e = t2 => (...e2) => ({
    _$litDirective$: t2,
    values: e2
  });
class i {
  constructor(t2) {}
  get _$AU() {
    return this._$AM._$AU;
  }
  _$AT(t2, e2, i2) {
    this._$Ct = t2, this._$AM = e2, this._$Ci = i2;
  }
  _$AS(t2, e2) {
    return this.update(t2, e2);
  }
  update(t2, e2) {
    return this.render(...e2);
  }
}
/**
 * @license
 * Copyright 2018 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const o2 = e(class extends i {
  constructor(t$12) {
    var i2;
    if (super(t$12), t$12.type !== t.ATTRIBUTE || "class" !== t$12.name || (null === (i2 = t$12.strings) || void 0 === i2 ? void 0 : i2.length) > 2) throw Error("`classMap()` can only be used in the `class` attribute and must be the only part in the attribute.");
  }
  render(t2) {
    return " " + Object.keys(t2).filter(i2 => t2[i2]).join(" ") + " ";
  }
  update(i2, [s2]) {
    var r2, o3;
    if (void 0 === this.it) {
      this.it = /* @__PURE__ */new Set(), void 0 !== i2.strings && (this.nt = new Set(i2.strings.join(" ").split(/\s/).filter(t2 => "" !== t2)));
      for (const t2 in s2) s2[t2] && !(null === (r2 = this.nt) || void 0 === r2 ? void 0 : r2.has(t2)) && this.it.add(t2);
      return this.render(s2);
    }
    const e2 = i2.element.classList;
    this.it.forEach(t2 => {
      t2 in s2 || (e2.remove(t2), this.it.delete(t2));
    });
    for (const t2 in s2) {
      const i3 = !!s2[t2];
      i3 === this.it.has(t2) || (null === (o3 = this.nt) || void 0 === o3 ? void 0 : o3.has(t2)) || (i3 ? (e2.add(t2), this.it.add(t2)) : (e2.remove(t2), this.it.delete(t2)));
    }
    return T;
  }
});
const lightBgColor = i$4`#F7F7F7`;
const darkBgColor = i$4`#121826`;
const styles = i$4`
  :host {
    display: contents;
  }

  iframe {
    border: none;
    overflow: hidden;
  }

  @keyframes slide-in {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes slide-out {
    to {
      transform: translateY(100%);
    }
  }
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    to {
      opacity: 0;
    }
  }

  .dialog {
    position: fixed;
    inset: 0;
    padding: 16px;
    margin: auto;
    min-width: 500px;
    width: fit-content;
    height: fit-content;
    border-radius: 0.5rem;
    background-color: ${lightBgColor};
    border: 0;
    box-sizing: border-box;
    font-family: ui-sans-serif, system-ui;
    display: none;
    z-index: 60;
  }

  .dialog.init {
    bottom: -300%;
    display: block;
  }

  .dialog.dark,
  .dialog.dark .dialog__panel {
    background-color: ${darkBgColor};
    color: #f9fafb;
  }

  .dialog.dialog--open.sliding-out {
    animation: fade-out 0.5s ease normal;
  }

  .dialog.dialog--open {
    animation: 0.5s fade-in;
    display: block;
  }

  .backdrop:not(.dialog--open) {
    display: none;
  }

  .backdrop.dialog--open {
    position: fixed;
    inset: 0;
    background-color: rgba(50, 50, 50, 0.5);
    z-index: 40;
    display: block;
    animation: fade-in 0.5s;
  }

  .backdrop.dialog--open.fading-out {
    animation: fade-out 0.5s ease normal;
  }

  .dialog__grip {
    display: none;
  }

  @media (max-width: 640px) {
    .dialog {
      transform: translateY(100%);
      bottom: 0;
      top: unset;
      width: 100%;
      min-width: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      padding-top: 24px;
    }

    .dialog.dialog--open {
      animation: 0.5s slide-in;
      transform: translateY(0);
    }

    .dialog.dialog--open.sliding-out {
      animation: slide-out 0.5s ease normal;
    }

    .dialog__grip {
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      top: 0.625rem;
      margin: auto;
      border-radius: 0.125rem;
      width: 4rem;
      height: 0.25rem;
      background-color: #9ca3af;
    }
  }

  .dialog__panel .dialog__content {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    background-color: ${lightBgColor};
    height: fit-content;
    overflow: hidden;
  }

  .dark .dialog__panel .dialog__content {
    background-color: ${darkBgColor};
  }
`;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i2 = decorators.length - 1, decorator; i2 >= 0; i2--) if (decorator = decorators[i2]) result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};
class InteractEvent extends CustomEvent {
  constructor(payload) {
    super("interact", {
      detail: payload
    });
  }
}
let PwaInstallDialog = class extends s {
  constructor() {
    super(...arguments);
    this.firstOpened = false;
    this.interceptPrompt = event => {
      event.preventDefault();
      this.installPrompt = event;
    };
    this.app = "";
    this.theme = "light";
    this.open = false;
    this.delaying = true;
    this.ready = false;
  }
  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener("beforeinstallprompt", this.interceptPrompt);
    await (async () => new Promise(resolve => setTimeout(resolve, 1e3)))();
    this.delaying = false;
  }
  disconnectedCallback() {
    var _a, _b;
    super.disconnectedCallback();
    (_a = this.channel) == null ? void 0 : _a.port1.close();
    (_b = this.channel) == null ? void 0 : _b.port2.close();
    window.removeEventListener("beforeinstallprompt", this.interceptPrompt);
    this.delaying = true;
  }
  updated(changedProperties) {
    super.updated(changedProperties);
    if (changedProperties.has("theme")) this.controlTheme();
  }
  controlTheme() {
    var _a;
    if (this.theme === void 0 || !["light", "dark"].includes(this.theme)) {
      this.theme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    }
    (_a = this.channel) == null ? void 0 : _a.port1.postMessage(this.theme === "dark" ? "darkTheme" : "lightTheme");
  }
  show() {
    if (this.open) return;
    this.open = true;
    if (!this.firstOpened) {
      this.dispatchEvent(new InteractEvent({
        interaction: "first-open",
        mode: this.mode
      }));
      this.firstOpened = true;
    }
  }
  hide() {
    var _a, _b, _c;
    if (!this.open) return;
    const cleanup = () => {
      var _a2, _b2;
      (_a2 = this.dialog) == null ? void 0 : _a2.classList.remove("sliding-out");
      (_b2 = this.backdrop) == null ? void 0 : _b2.classList.remove("fading-out");
      this.open = false;
    };
    (_a = this.dialog) == null ? void 0 : _a.addEventListener("animationend", cleanup, {
      capture: false,
      once: true
    });
    (_b = this.dialog) == null ? void 0 : _b.classList.add("sliding-out");
    (_c = this.backdrop) == null ? void 0 : _c.classList.add("fading-out");
  }
  initChannel() {
    var _a, _b, _c;
    this.channel = new MessageChannel();
    this.channel.port1.onmessage = message => {
      var _a2, _b2, _c2;
      switch (message.data.topic) {
        case "mode":
          {
            this.mode = message.data.payload;
            (_a2 = this.channel) == null ? void 0 : _a2.port1.postMessage("height");
            this.controlTheme();
            break;
          }
        case "triggerInstall":
          {
            (_b2 = this.installPrompt) == null ? void 0 : _b2.prompt();
            this.dispatchEvent(new InteractEvent({
              interaction: "install"
            }));
            break;
          }
        case "height":
          {
            const height = +message.data.payload;
            if (this.iframe) {
              this.iframe.height = height > 0 ? `${height}` : "300";
            }
            (_c2 = this.dialog) == null ? void 0 : _c2.classList.remove("init");
            this.ready = true;
            this.dispatchEvent(new CustomEvent("ready"));
            break;
          }
        case "interaction":
          {
            this.dispatchEvent(new InteractEvent(message.data.payload));
            break;
          }
        case "open":
          {
            const url = new URL(document.location.href);
            if (url.searchParams.get("utm_source") === "store.app") this.show();
            break;
          }
      }
    };
    (_a = this.dialog) == null ? void 0 : _a.classList.add("init");
    (_c = (_b = this.iframe) == null ? void 0 : _b.contentWindow) == null ? void 0 : _c.postMessage("init", "*", [this.channel.port2]);
  }
  render() {
    if (this.delaying) return;
    const iframeUrl = new URL("https://edge.store.app/v0/widgets/install-modal");
    iframeUrl.searchParams.set("site-path", this.app);
    iframeUrl.searchParams.set("prompt-support", this.installPrompt === void 0 ? "0" : "1");
    return x`
      <div
        part="backdrop"
        class=${o2({
      backdrop: true,
      "dialog--open": this.open && this.ready
    })}
        @click="${this.hide}"
      ></div>
      <div
        part="dialog"
        class=${o2({
      dialog: true,
      "dialog--open": this.open && this.ready,
      dark: this.theme === "dark"
    })}
      >
        <div part="panel" class="dialog__panel">
          <div part="grip" class="dialog__grip"></div>
          <div class="dialog__content">
            <iframe
              src="${iframeUrl.toString()}"
              @load=${this.initChannel}
            ></iframe>
          </div>
        </div>
      </div>
    `;
  }
};
PwaInstallDialog.styles = styles;
__decorateClass([n$1({
  type: String
})], PwaInstallDialog.prototype, "app", 2);
__decorateClass([n$1({
  type: String
})], PwaInstallDialog.prototype, "theme", 2);
__decorateClass([n$1({
  type: Boolean
})], PwaInstallDialog.prototype, "open", 2);
__decorateClass([t$1()], PwaInstallDialog.prototype, "installPrompt", 2);
__decorateClass([t$1()], PwaInstallDialog.prototype, "delaying", 2);
__decorateClass([t$1()], PwaInstallDialog.prototype, "ready", 2);
__decorateClass([i$1(".dialog")], PwaInstallDialog.prototype, "dialog", 2);
__decorateClass([i$1(".backdrop")], PwaInstallDialog.prototype, "backdrop", 2);
__decorateClass([i$1("iframe")], PwaInstallDialog.prototype, "iframe", 2);
PwaInstallDialog = __decorateClass([e$2("pwa-install-dialog")], PwaInstallDialog);
export { PwaInstallDialog };