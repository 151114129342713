import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { PaidFeature } from '../../service/payment.service';
import { Action, AnalyticsService, AppScreen, ScreenAction } from '../../service/analytics.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIconModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {


  public readonly PaidFeature = PaidFeature
  public feature?: PaidFeature

  constructor (private analytics : AnalyticsService, private ref : MatDialogRef<LoginComponent>, private authService : DankTankAuthService){
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.MODAL_OPENED, info : "Login"}
    this.analytics.track(action)
  }

  public signInWithGoogle(){
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.CLICK, info : "Login with Google"}
    this.analytics.track(action)
    this.authService.loginWithProvider('google')
    this.ref.close('closed_by_code')
  }

  public signInWithFacebook(){
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.CLICK, info : "Login with Facebook"}
    this.analytics.track(action)
    this.authService.loginWithProvider('facebook')
    this.ref.close('closed_by_code')
  }
  
  public signInWithApple(){
    
  }

}
