<div  #imgAsset
    class=""
    [style.transform]="data?.data?.transform"
    [style.width.px]="data?.data?.width"
    [style.height.px]="data?.data?.height"
    [style.opacity]="(data?.data?.opacity || 0) * .01"
    [class]="interacting ? 'noTransition' : 'transition'"
    [style.mix-blend-mode]="data?.data?.blendMode"
    [style.filter]="'grayscale('+data?.data?.grey+') sepia('+data?.data?.sepia+') hue-rotate('+data?.data?.hue+'deg) saturate('+data?.data?.saturation+') brightness('+data?.data?.brightness+') blur('+data?.data?.blur+'px)'"
>
@if(!data?.data?.location?.includes('.svg') || data?.section?.mode == Mode.VIEWING){
    <img #img
    [style.filter]="data?.type == AssetType.SHAPE ? data?.data?.filter : null"
    class="fade-img add-svg-pointer-events"
    (load)="data?.loaded?.emit(true); img.style.opacity = '1'; a(data?.type?.toString() || '' + 'Loaded', data?.data?.location, ScreenAction.LOAD)"
     [src]="data?.data?.location" style="object-fit: contain; width: 100%; height: 100%;" >
}@else{
    <app-inline-svg
        [svgUrl]="data?.data?.location"
        (load)="data?.loaded?.emit(true); a(data?.type?.toString() || '' + 'Loaded', data?.data?.location, ScreenAction.LOAD)">
    </app-inline-svg>
}
</div>