<h2 mat-dialog-title class="dialog-title">Resize</h2>
<mat-dialog-content class="content" style="margin-top: 10px;">
    @if(data){
        <div class="no-scroll image-holder" >
            @if(!loaded){<mat-spinner></mat-spinner>}
            <image-cropper 
                [hidden]="!loaded"
                [hideResizeSquares]="filtersOpened"
                [disabled]="filtersOpened"
                [style.filter]="'grayscale('+data.grey+') sepia('+data.sepia+') hue-rotate('+data.hue+'deg) saturate('+data.saturation+') brightness('+data.brightness+') blur('+data.blur+'px)'"
                class="image-cropper"
                [imageBase64]="data.mediaLocation.includes('http') ? undefined :data.mediaLocation" 
                [imageURL]="data.mediaLocation.includes('http') ? data.mediaLocation : undefined"
                [output]="'base64'" 
                [maintainAspectRatio]="false"
                [resizeToHeight]="1500"
                [resizeToWidth]="1500"
                [containWithinAspectRatio]="false"
                [backgroundColor]="'#1e1a1d'"
                (imageCropped)="imageCropped($event)" 
                (imageLoaded)="imageLoaded($event)" 
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed($event)"
                >
             </image-cropper>
        </div>
    }
</mat-dialog-content>
<mat-dialog-actions align="end">
    @if(imageChanged){<button mat-button mat-dialog-close (click)="cancel()">Cancel</button>}
   @if(data.mediaLocation != data.originalLocation && imageChanged){<button mat-button (click)="revert()">Revert to Original</button>}
    <button mat-button mat-dialog-close (click)="apply()">Ok!</button>
</mat-dialog-actions>