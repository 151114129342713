import { AfterViewInit, Component } from '@angular/core';
import { AppService } from '../../service/app.service';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { PaymentService } from '../../service/payment.service';
import { AdSize, DeviceDetectorService } from '../../service/device-detector.service';
import { EditMode, ToolbarService } from '../../service/toolbar.service';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AdEmbedComponent } from '../ad-embed/ad-embed.component';


@Component({
  selector: 'app-ad',
  standalone: true,
  imports: [CommonModule, AdEmbedComponent],
  templateUrl: './ad.component.html',
  styleUrl: './ad.component.scss'
})
export class AdComponent implements AfterViewInit {

  readonly AdSize = AdSize
  adSize?: Observable<AdSize>;

  //////advertisement stuff
  public readonly smallAdKey: string = environment.smallAdKey
  public readonly mediumAdKey: string = environment.mediumAdKey
  public readonly largeAdKey: string = environment.largeAdKey


  constructor(private toolbarService : ToolbarService, public appService : AppService, public authService : DankTankAuthService, public paymentService : PaymentService, public deviceDetector : DeviceDetectorService){
    
  }

  ngAfterViewInit(): void {
    this.adSize = this.deviceDetector.getAdSize()
  }

  resetAsset() {
    if (this.appService.gridSection) this.appService.gridSection.activeAsset = undefined
    this.toolbarService.editModeSubject.next({ mode: EditMode.BASIC })
  }
}
