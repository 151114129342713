<div mat-dialog-title class="dialog-title">
        <div class="title-holder">
           Upgrade
        </div>
</div>
<mat-dialog-content style="margin-top: 20px;">
    <mat-form-field class="pointer" appearance="outline" style="margin-top: 10px;">
        <mat-label>Discount Code</mat-label>
        @if(applyingDiscount){
        <mat-spinner
            matSuffix
            diameter="35"
            style="margin-right: 10px"
        ></mat-spinner>
        }@else{
        <mat-icon matSuffix (click)="$event.stopPropagation(); applyDiscount(discount.value)">
            {{ discountMessage?.includes('Invalid') ? 'cancel' : (discountMessage ? 'check_circle' : 'send') }}
        </mat-icon>
        }
        <input #discount matInput />
        <!--<mat-error>City is required</mat-error>-->
        @if(discountMessage){
        <div class="subtle-gradient-text">{{discountMessage}}</div>
        }
    </mat-form-field>
</mat-dialog-content>