import { Component, DestroyRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { Font, FontService } from '../../service/font.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppService } from '../../service/app.service';
import { FontFilterPipe, SafeStylePipe } from '../../util/util.pipe';
import { MatInputModule } from '@angular/material/input';
import { NgAutoAnimateDirective } from 'ng-auto-animate';

@Component({
  selector: 'app-font-picker',
  standalone: true,
  imports: [NgAutoAnimateDirective, FontFilterPipe, SafeStylePipe, MatButtonModule, MatIconModule, MatDividerModule, MatProgressBarModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatInputModule],
  templateUrl: './font-picker.component.html',
  styleUrl: './font-picker.component.scss'
})
export class FontPickerComponent {

  public fonts ?: Font[]
  public fontSearchTerm ?: string

  constructor(public fontService : FontService, private destroyRef : DestroyRef, private appService : AppService){

    fontService.fontSubject.asObservable().pipe(takeUntilDestroyed(destroyRef)).subscribe((value : Font[]) => {
      this.fonts = value
    })

    if(!this.fonts || (this.fonts && this.fonts.length == 0))fontService.loadSubset()
  }

  public loadedAllFonts : boolean = false
  public loadAllFonts(){
    this.fontService.loadFonts()
    this.loadedAllFonts = true
  }

  public setFont(fontName : string){
    if(this.appService.gridSection?.activeAsset)this.appService.gridSection.activeAsset.data.fontFamily = fontName
  }

}
