import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaidFeature, PaymentService, Subscription, SubscriptionStatus, SubscriptionType } from '../../service/payment.service';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { DialogRef } from '@angular/cdk/dialog';
import { ServiceResult } from '../../service/result';
import { take } from 'rxjs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-upgrade-modal',
  standalone: true,
  imports: [FormsModule, MatFormFieldModule, MatDialogModule, FormsModule, ReactiveFormsModule, MatInputModule, MatIconModule, MatProgressSpinnerModule],
  templateUrl: './upgrade-modal.component.html',
  styleUrl: './upgrade-modal.component.scss'
})
export class UpgradeModalComponent {

  constructor(private paymentService : PaymentService, private authService : DankTankAuthService, private dialogRef : DialogRef){

  }

  public applyingDiscount : boolean = false
  public discountMessage ?: string
  private code ?: string
  public applyDiscount(code : string){
    this.code = code
    this.authService.authThen(this.callback, this, this.paymentService.getAllPaid(), PaidFeature.UPGRADE, undefined, false)
  }

  public callback(){
    this.applyingDiscount = true
    if(this.code){
        this.paymentService.applyDiscountCode(this.code)?.pipe(take(1)).subscribe((result : ServiceResult<string>) =>{
        this.applyingDiscount = false
        if(result.data){
          this.discountMessage = "Congrats, you've been successfully upgraded, forwarding you in one second!"
          setTimeout(() => {
            this.continue()
          }, 1500);
        }else if(result.error){
          this.discountMessage = (result.error as unknown) as string
          setTimeout(() => {
            this.discountMessage = undefined
          }, 2500);
        }
      })
    }
  }
  
  public continue() {
    //when they pay we'll set a temporary fake membership to allow for webook delays
    this.authService.fakeSubscription = this.getNewSubscription()
    this.dialogRef.close()
    //this.authService.reauth()
  }

  private getNewSubscription() : Subscription {
    return {
      type: SubscriptionType.PRO_YEARLY,
      monthlyPrice: 0,
      status: SubscriptionStatus.ACTIVE
    }
  }
}
