<div mat-dialog-title class="dialog-title">
  <div class="title-holder">
    <h2>{{ type.toString() }}</h2>
  </div>
</div>
<mat-dialog-content class="dialog-content">
  <!--<mat-label>Legal Disclaimer</mat-label>-->
  <!--<textarea #legalTextArea matInput readonly [(ngModel)]="text" cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="15"></textarea>-->
  <div [innerHTML]="sanitizedContent"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-fab extended (click)="close()">
    {{ type == InfoDialogType.TOC ? "Accept" : "Got It!" }}
  </button>
</mat-dialog-actions>
