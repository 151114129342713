import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule, Directive, Output, HostListener, EventEmitter, isDevMode, forwardRef } from '@angular/core';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { distinctUntilChanged, debounceTime, tap } from 'rxjs/operators';
import { TinyColor } from '@ctrl/tinycolor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
function EditableInputComponent_span_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵlistener("mousedown", function EditableInputComponent_span_2_Template_span_mousedown_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleMousedown($event));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r1.uniqueId)("ngStyle", ctx_r1.labelStyle);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.label, " ");
  }
}
function HueComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelement(1, "div", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("left", ctx_r0.left)("top", ctx_r0.top);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngStyle", ctx_r0.pointer);
  }
}
const _c0 = ["*"];
function SwatchComponent_color_checkboard_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "color-checkboard", 2);
  }
}
const checkboardCache = {};
function render(c1, c2, size) {
  if (typeof document === 'undefined') {
    return null;
  }
  const canvas = document.createElement('canvas');
  canvas.width = size * 2;
  canvas.height = size * 2;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return null;
  } // If no context can be found, return early.
  ctx.fillStyle = c1;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = c2;
  ctx.fillRect(0, 0, size, size);
  ctx.translate(size, size);
  ctx.fillRect(0, 0, size, size);
  return canvas.toDataURL();
}
function getCheckerboard(c1, c2, size) {
  const key = `${c1}-${c2}-${size}`;
  if (checkboardCache[key]) {
    return checkboardCache[key];
  }
  const checkboard = render(c1, c2, size);
  if (!checkboard) {
    return null;
  }
  checkboardCache[key] = checkboard;
  return checkboard;
}
class CheckboardComponent {
  white = 'transparent';
  size = 8;
  grey = 'rgba(0,0,0,.08)';
  boxShadow;
  borderRadius;
  gridStyles;
  ngOnInit() {
    const background = getCheckerboard(this.white, this.grey, this.size);
    this.gridStyles = {
      borderRadius: this.borderRadius,
      boxShadow: this.boxShadow,
      background: `url(${background}) center left`
    };
  }
  static ɵfac = function CheckboardComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CheckboardComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: CheckboardComponent,
    selectors: [["color-checkboard"]],
    inputs: {
      white: "white",
      size: "size",
      grey: "grey",
      boxShadow: "boxShadow",
      borderRadius: "borderRadius"
    },
    decls: 1,
    vars: 1,
    consts: [[1, "grid", 3, "ngStyle"]],
    template: function CheckboardComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelement(0, "div", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngStyle", ctx.gridStyles);
      }
    },
    dependencies: [i1.NgStyle],
    styles: [".grid[_ngcontent-%COMP%]{inset:0;position:absolute}"],
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboardComponent, [{
    type: Component,
    args: [{
      selector: 'color-checkboard',
      template: `<div class="grid" [ngStyle]="gridStyles"></div>`,
      preserveWhitespaces: false,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [".grid{inset:0;position:absolute}\n"]
    }]
  }], null, {
    white: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    grey: [{
      type: Input
    }],
    boxShadow: [{
      type: Input
    }],
    borderRadius: [{
      type: Input
    }]
  });
})();
class CheckboardModule {
  static ɵfac = function CheckboardModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CheckboardModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: CheckboardModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboardModule, [{
    type: NgModule,
    args: [{
      declarations: [CheckboardComponent],
      exports: [CheckboardComponent],
      imports: [CommonModule]
    }]
  }], null, null);
})();
class CoordinatesDirective {
  el;
  coordinatesChange = new Subject();
  mousechange = new Subject();
  mouseListening = false;
  sub;
  mousemove($event, x, y, isTouch = false) {
    if (this.mouseListening) {
      $event.preventDefault();
      this.mousechange.next({
        $event,
        x,
        y,
        isTouch
      });
    }
  }
  mouseup() {
    this.mouseListening = false;
  }
  mousedown($event, x, y, isTouch = false) {
    $event.preventDefault();
    this.mouseListening = true;
    this.mousechange.next({
      $event,
      x,
      y,
      isTouch
    });
  }
  constructor(el) {
    this.el = el;
  }
  ngOnInit() {
    this.sub = this.mousechange.pipe(
    // limit times it is updated for the same area
    distinctUntilChanged((p, q) => p.x === q.x && p.y === q.y)).subscribe(n => this.handleChange(n.x, n.y, n.$event, n.isTouch));
  }
  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
  handleChange(x, y, $event, isTouch) {
    const containerWidth = this.el.nativeElement.clientWidth;
    const containerHeight = this.el.nativeElement.clientHeight;
    const left = x - (this.el.nativeElement.getBoundingClientRect().left + window.pageXOffset);
    let top = y - this.el.nativeElement.getBoundingClientRect().top;
    if (!isTouch) {
      top = top - window.pageYOffset;
    }
    this.coordinatesChange.next({
      x,
      y,
      top,
      left,
      containerWidth,
      containerHeight,
      $event
    });
  }
  static ɵfac = function CoordinatesDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CoordinatesDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: CoordinatesDirective,
    selectors: [["", "ngx-color-coordinates", ""]],
    hostBindings: function CoordinatesDirective_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("mousemove", function CoordinatesDirective_mousemove_HostBindingHandler($event) {
          return ctx.mousemove($event, $event.pageX, $event.pageY);
        }, false, i0.ɵɵresolveWindow)("touchmove", function CoordinatesDirective_touchmove_HostBindingHandler($event) {
          return ctx.mousemove($event, $event.touches[0].clientX, $event.touches[0].clientY, true);
        }, false, i0.ɵɵresolveWindow)("mouseup", function CoordinatesDirective_mouseup_HostBindingHandler() {
          return ctx.mouseup();
        }, false, i0.ɵɵresolveWindow)("touchend", function CoordinatesDirective_touchend_HostBindingHandler() {
          return ctx.mouseup();
        }, false, i0.ɵɵresolveWindow)("mousedown", function CoordinatesDirective_mousedown_HostBindingHandler($event) {
          return ctx.mousedown($event, $event.pageX, $event.pageY);
        })("touchstart", function CoordinatesDirective_touchstart_HostBindingHandler($event) {
          return ctx.mousedown($event, $event.touches[0].clientX, $event.touches[0].clientY, true);
        });
      }
    },
    outputs: {
      coordinatesChange: "coordinatesChange"
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoordinatesDirective, [{
    type: Directive,
    args: [{
      selector: '[ngx-color-coordinates]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    coordinatesChange: [{
      type: Output
    }],
    mousemove: [{
      type: HostListener,
      args: ['window:mousemove', ['$event', '$event.pageX', '$event.pageY']]
    }, {
      type: HostListener,
      args: ['window:touchmove', ['$event', '$event.touches[0].clientX', '$event.touches[0].clientY', 'true']]
    }],
    mouseup: [{
      type: HostListener,
      args: ['window:mouseup']
    }, {
      type: HostListener,
      args: ['window:touchend']
    }],
    mousedown: [{
      type: HostListener,
      args: ['mousedown', ['$event', '$event.pageX', '$event.pageY']]
    }, {
      type: HostListener,
      args: ['touchstart', ['$event', '$event.touches[0].clientX', '$event.touches[0].clientY', 'true']]
    }]
  });
})();
class CoordinatesModule {
  static ɵfac = function CoordinatesModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CoordinatesModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: CoordinatesModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoordinatesModule, [{
    type: NgModule,
    args: [{
      declarations: [CoordinatesDirective],
      exports: [CoordinatesDirective]
    }]
  }], null, null);
})();
class AlphaComponent {
  hsl;
  rgb;
  pointer;
  shadow;
  radius;
  direction = 'horizontal';
  onChange = new EventEmitter();
  gradient;
  pointerLeft;
  pointerTop;
  ngOnChanges() {
    if (this.direction === 'vertical') {
      this.pointerLeft = 0;
      this.pointerTop = this.rgb.a * 100;
      this.gradient = {
        background: `linear-gradient(to bottom, rgba(${this.rgb.r},${this.rgb.g},${this.rgb.b}, 0) 0%,
          rgba(${this.rgb.r},${this.rgb.g},${this.rgb.b}, 1) 100%)`
      };
    } else {
      this.gradient = {
        background: `linear-gradient(to right, rgba(${this.rgb.r},${this.rgb.g},${this.rgb.b}, 0) 0%,
          rgba(${this.rgb.r},${this.rgb.g},${this.rgb.b}, 1) 100%)`
      };
      this.pointerLeft = this.rgb.a * 100;
    }
  }
  handleChange({
    top,
    left,
    containerHeight,
    containerWidth,
    $event
  }) {
    let data;
    if (this.direction === 'vertical') {
      let a;
      if (top < 0) {
        a = 0;
      } else if (top > containerHeight) {
        a = 1;
      } else {
        a = Math.round(top * 100 / containerHeight) / 100;
      }
      if (this.hsl.a !== a) {
        data = {
          h: this.hsl.h,
          s: this.hsl.s,
          l: this.hsl.l,
          a,
          source: 'rgb'
        };
      }
    } else {
      let a;
      if (left < 0) {
        a = 0;
      } else if (left > containerWidth) {
        a = 1;
      } else {
        a = Math.round(left * 100 / containerWidth) / 100;
      }
      if (this.hsl.a !== a) {
        data = {
          h: this.hsl.h,
          s: this.hsl.s,
          l: this.hsl.l,
          a,
          source: 'rgb'
        };
      }
    }
    if (!data) {
      return;
    }
    this.onChange.emit({
      data,
      $event
    });
  }
  static ɵfac = function AlphaComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AlphaComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: AlphaComponent,
    selectors: [["color-alpha"]],
    inputs: {
      hsl: "hsl",
      rgb: "rgb",
      pointer: "pointer",
      shadow: "shadow",
      radius: "radius",
      direction: "direction"
    },
    outputs: {
      onChange: "onChange"
    },
    features: [i0.ɵɵNgOnChangesFeature],
    decls: 7,
    vars: 15,
    consts: [[1, "alpha"], [1, "alpha-checkboard"], [1, "alpha-gradient", 3, "ngStyle"], ["ngx-color-coordinates", "", 3, "coordinatesChange"], [1, "alpha-pointer"], [1, "alpha-slider", 3, "ngStyle"]],
    template: function AlphaComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
        i0.ɵɵelement(2, "color-checkboard");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(3, "div", 2);
        i0.ɵɵelementStart(4, "div", 3);
        i0.ɵɵlistener("coordinatesChange", function AlphaComponent_Template_div_coordinatesChange_4_listener($event) {
          return ctx.handleChange($event);
        });
        i0.ɵɵelementStart(5, "div", 4);
        i0.ɵɵelement(6, "div", 5);
        i0.ɵɵelementEnd()()();
      }
      if (rf & 2) {
        i0.ɵɵstyleProp("border-radius", ctx.radius);
        i0.ɵɵadvance(3);
        i0.ɵɵstyleProp("box-shadow", ctx.shadow)("border-radius", ctx.radius);
        i0.ɵɵproperty("ngStyle", ctx.gradient);
        i0.ɵɵadvance();
        i0.ɵɵclassMapInterpolate1("alpha-container color-alpha-", ctx.direction, "");
        i0.ɵɵadvance();
        i0.ɵɵstyleProp("left", ctx.pointerLeft, "%")("top", ctx.pointerTop, "%");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngStyle", ctx.pointer);
      }
    },
    dependencies: [i1.NgStyle, CheckboardComponent, CoordinatesDirective],
    styles: [".alpha[_ngcontent-%COMP%]{position:absolute;inset:0}.alpha-checkboard[_ngcontent-%COMP%]{position:absolute;inset:0;overflow:hidden}.alpha-gradient[_ngcontent-%COMP%]{position:absolute;inset:0}.alpha-container[_ngcontent-%COMP%]{position:relative;height:100%;margin:0 3px}.alpha-pointer[_ngcontent-%COMP%]{position:absolute}.alpha-slider[_ngcontent-%COMP%]{width:4px;border-radius:1px;height:8px;box-shadow:0 0 2px #0009;background:#fff;margin-top:1px;transform:translate(-2px)}"],
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlphaComponent, [{
    type: Component,
    args: [{
      selector: 'color-alpha',
      template: `
  <div class="alpha" [style.border-radius]="radius">
    <div class="alpha-checkboard">
      <color-checkboard></color-checkboard>
    </div>
    <div class="alpha-gradient" [ngStyle]="gradient" [style.box-shadow]="shadow" [style.border-radius]="radius"></div>
    <div ngx-color-coordinates (coordinatesChange)="handleChange($event)" class="alpha-container color-alpha-{{direction}}">
      <div class="alpha-pointer" [style.left.%]="pointerLeft" [style.top.%]="pointerTop">
        <div class="alpha-slider" [ngStyle]="pointer"></div>
      </div>
    </div>
  </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false,
      styles: [".alpha{position:absolute;inset:0}.alpha-checkboard{position:absolute;inset:0;overflow:hidden}.alpha-gradient{position:absolute;inset:0}.alpha-container{position:relative;height:100%;margin:0 3px}.alpha-pointer{position:absolute}.alpha-slider{width:4px;border-radius:1px;height:8px;box-shadow:0 0 2px #0009;background:#fff;margin-top:1px;transform:translate(-2px)}\n"]
    }]
  }], null, {
    hsl: [{
      type: Input
    }],
    rgb: [{
      type: Input
    }],
    pointer: [{
      type: Input
    }],
    shadow: [{
      type: Input
    }],
    radius: [{
      type: Input
    }],
    direction: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }]
  });
})();
class AlphaModule {
  static ɵfac = function AlphaModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AlphaModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AlphaModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, CheckboardModule, CoordinatesModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlphaModule, [{
    type: NgModule,
    args: [{
      declarations: [AlphaComponent],
      exports: [AlphaComponent],
      imports: [CommonModule, CheckboardModule, CoordinatesModule]
    }]
  }], null, null);
})();
function simpleCheckForValidColor(data) {
  const keysToCheck = ['r', 'g', 'b', 'a', 'h', 's', 'l', 'v'];
  let checked = 0;
  let passed = 0;
  keysToCheck.forEach(letter => {
    if (!data[letter]) {
      return;
    }
    checked += 1;
    if (!isNaN(data[letter])) {
      passed += 1;
    }
    if (letter === 's' || letter === 'l') {
      const percentPatt = /^\d+%$/;
      if (percentPatt.test(data[letter])) {
        passed += 1;
      }
    }
  });
  return checked === passed ? data : false;
}
function toState(data, oldHue, disableAlpha) {
  const color = data.hex ? new TinyColor(data.hex) : new TinyColor(data);
  if (disableAlpha) {
    color.setAlpha(1);
  }
  const hsl = color.toHsl();
  const hsv = color.toHsv();
  const rgb = color.toRgb();
  const hex = color.toHex();
  if (hsl.s === 0) {
    hsl.h = oldHue || 0;
    hsv.h = oldHue || 0;
  }
  const transparent = hex === '000000' && rgb.a === 0;
  return {
    hsl,
    hex: transparent ? 'transparent' : color.toHexString(),
    rgb,
    hsv,
    oldHue: data.h || oldHue || hsl.h,
    source: data.source
  };
}
function isValidHex(hex) {
  return new TinyColor(hex).isValid;
}
function getContrastingColor(data) {
  if (!data) {
    return '#fff';
  }
  const col = toState(data);
  if (col.hex === 'transparent') {
    return 'rgba(0,0,0,0.4)';
  }
  const yiq = (col.rgb.r * 299 + col.rgb.g * 587 + col.rgb.b * 114) / 1000;
  return yiq >= 128 ? '#000' : '#fff';
}
var ColorMode;
(function (ColorMode) {
  ColorMode["HEX"] = "hex";
  ColorMode["HSL"] = "hsl";
  ColorMode["HSV"] = "hsv";
  ColorMode["RGB"] = "rgb";
})(ColorMode || (ColorMode = {}));
class ColorWrap {
  className;
  /**
   * Descriptors the return color format if the component is used with two-way binding
   */
  mode = ColorMode.HEX;
  color = {
    h: 250,
    s: 0.5,
    l: 0.2,
    a: 1
  };
  colorChange = new EventEmitter();
  onChange = new EventEmitter();
  onChangeComplete = new EventEmitter();
  onSwatchHover = new EventEmitter();
  oldHue;
  hsl;
  hsv;
  rgb;
  hex;
  source;
  currentColor;
  changes;
  disableAlpha;
  _onChangeCompleteSubscription = new Subscription();
  _onSwatchHoverSubscription = new Subscription();
  ngOnInit() {
    this.changes = this.onChange.pipe(debounceTime(100), tap(event => {
      this.onChangeComplete.emit(event);
      switch (this.mode) {
        case ColorMode.HEX:
          this.colorChange.emit(event.color.hex);
          break;
        case ColorMode.HSL:
          this.colorChange.emit(event.color.hsl);
          break;
        case ColorMode.HSV:
          this.colorChange.emit(event.color.hsv);
          break;
        case ColorMode.RGB:
          this.colorChange.emit(event.color.rgb);
          break;
        default:
          const msg = `The mode '${this.mode}' is not supported`;
          if (isDevMode()) {
            throw new Error(msg);
          } else {
            console.warn(msg);
          }
          break;
      }
    })).subscribe();
    this.setState(toState(this.color, 0));
    this.currentColor = this.hex;
  }
  ngOnChanges() {
    this.setState(toState(this.color, this.oldHue));
  }
  ngOnDestroy() {
    this.changes?.unsubscribe();
    this._onChangeCompleteSubscription?.unsubscribe();
    this._onSwatchHoverSubscription?.unsubscribe();
  }
  setState(data) {
    this.oldHue = data.oldHue;
    this.hsl = data.hsl;
    this.hsv = data.hsv;
    this.rgb = data.rgb;
    this.hex = data.hex;
    this.source = data.source;
    this.afterValidChange();
  }
  handleChange(data, $event) {
    const isValidColor = simpleCheckForValidColor(data);
    if (isValidColor) {
      const color = toState(data, data.h || this.oldHue, this.disableAlpha);
      this.setState(color);
      this.onChange.emit({
        color,
        $event
      });
      this.afterValidChange();
    }
  }
  /** hook for components after a complete change */
  afterValidChange() {}
  handleSwatchHover(data, $event) {
    const isValidColor = simpleCheckForValidColor(data);
    if (isValidColor) {
      const color = toState(data, data.h || this.oldHue);
      this.setState(color);
      this.onSwatchHover.emit({
        color,
        $event
      });
    }
  }
  registerOnChange(fn) {
    this._onChangeCompleteSubscription.add(this.onChangeComplete.pipe(tap(event => fn(event.color.hex))).subscribe());
  }
  registerOnTouched(fn) {
    this._onSwatchHoverSubscription.add(this.onSwatchHover.pipe(tap(() => fn())).subscribe());
  }
  setDisabledState(isDisabled) {}
  writeValue(hex) {
    this.color = hex;
  }
  static ɵfac = function ColorWrap_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ColorWrap)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ColorWrap,
    selectors: [["color-wrap"]],
    inputs: {
      className: "className",
      mode: "mode",
      color: "color"
    },
    outputs: {
      colorChange: "colorChange",
      onChange: "onChange",
      onChangeComplete: "onChangeComplete",
      onSwatchHover: "onSwatchHover"
    },
    features: [i0.ɵɵProvidersFeature([{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorWrap),
      multi: true
    }]), i0.ɵɵNgOnChangesFeature],
    decls: 0,
    vars: 0,
    template: function ColorWrap_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorWrap, [{
    type: Component,
    args: [{
      // create seletor base for test override property
      selector: 'color-wrap',
      template: ``,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ColorWrap),
        multi: true
      }]
    }]
  }], null, {
    className: [{
      type: Input
    }],
    mode: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    colorChange: [{
      type: Output
    }],
    onChange: [{
      type: Output
    }],
    onChangeComplete: [{
      type: Output
    }],
    onSwatchHover: [{
      type: Output
    }]
  });
})();
class ColorWrapModule {
  static ɵfac = function ColorWrapModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ColorWrapModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ColorWrapModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorWrapModule, [{
    type: NgModule,
    args: [{
      declarations: [ColorWrap],
      exports: [ColorWrap],
      imports: [CommonModule]
    }]
  }], null, null);
})();
let nextUniqueId = 0;
class EditableInputComponent {
  style;
  label;
  value;
  arrowOffset;
  dragLabel;
  dragMax;
  placeholder = '';
  onChange = new EventEmitter();
  currentValue;
  blurValue;
  wrapStyle;
  inputStyle;
  labelStyle;
  focus = false;
  mousemove;
  mouseup;
  uniqueId = `editableInput-${++nextUniqueId}`;
  ngOnInit() {
    this.wrapStyle = this.style && this.style.wrap ? this.style.wrap : {};
    this.inputStyle = this.style && this.style.input ? this.style.input : {};
    this.labelStyle = this.style && this.style.label ? this.style.label : {};
    if (this.dragLabel) {
      this.labelStyle.cursor = 'ew-resize';
    }
  }
  handleFocus($event) {
    this.focus = true;
  }
  handleFocusOut($event) {
    this.focus = false;
    this.currentValue = this.blurValue;
  }
  handleKeydown($event) {
    // In case `e.target.value` is a percentage remove the `%` character
    // and update accordingly with a percentage
    // https://github.com/casesandberg/react-color/issues/383
    const stringValue = String($event.target.value);
    const isPercentage = stringValue.indexOf('%') > -1;
    const num = Number(stringValue.replace(/%/g, ''));
    if (isNaN(num)) {
      return;
    }
    const amount = this.arrowOffset || 1;
    // Up
    if ($event.keyCode === 38) {
      if (this.label) {
        this.onChange.emit({
          data: {
            [this.label]: num + amount
          },
          $event
        });
      } else {
        this.onChange.emit({
          data: num + amount,
          $event
        });
      }
      if (isPercentage) {
        this.currentValue = `${num + amount}%`;
      } else {
        this.currentValue = num + amount;
      }
    }
    // Down
    if ($event.keyCode === 40) {
      if (this.label) {
        this.onChange.emit({
          data: {
            [this.label]: num - amount
          },
          $event
        });
      } else {
        this.onChange.emit({
          data: num - amount,
          $event
        });
      }
      if (isPercentage) {
        this.currentValue = `${num - amount}%`;
      } else {
        this.currentValue = num - amount;
      }
    }
  }
  handleKeyup($event) {
    if ($event.keyCode === 40 || $event.keyCode === 38) {
      return;
    }
    if (`${this.currentValue}` === $event.target.value) {
      return;
    }
    if (this.label) {
      this.onChange.emit({
        data: {
          [this.label]: $event.target.value
        },
        $event
      });
    } else {
      this.onChange.emit({
        data: $event.target.value,
        $event
      });
    }
  }
  ngOnChanges() {
    if (!this.focus) {
      this.currentValue = String(this.value).toUpperCase();
      this.blurValue = String(this.value).toUpperCase();
    } else {
      this.blurValue = String(this.value).toUpperCase();
    }
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {
    this.mousemove = fromEvent(document, 'mousemove').subscribe(ev => this.handleDrag(ev));
    this.mouseup = fromEvent(document, 'mouseup').subscribe(() => this.unsubscribe());
  }
  unsubscribe() {
    this.mousemove?.unsubscribe();
    this.mouseup?.unsubscribe();
  }
  handleMousedown($event) {
    if (this.dragLabel) {
      $event.preventDefault();
      this.handleDrag($event);
      this.subscribe();
    }
  }
  handleDrag($event) {
    if (this.dragLabel) {
      const newValue = Math.round(this.value + $event.movementX);
      if (newValue >= 0 && newValue <= this.dragMax) {
        this.onChange.emit({
          data: {
            [this.label]: newValue
          },
          $event
        });
      }
    }
  }
  static ɵfac = function EditableInputComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || EditableInputComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: EditableInputComponent,
    selectors: [["color-editable-input"]],
    inputs: {
      style: "style",
      label: "label",
      value: "value",
      arrowOffset: "arrowOffset",
      dragLabel: "dragLabel",
      dragMax: "dragMax",
      placeholder: "placeholder"
    },
    outputs: {
      onChange: "onChange"
    },
    features: [i0.ɵɵNgOnChangesFeature],
    decls: 3,
    vars: 6,
    consts: [[1, "wrap", 3, "ngStyle"], ["spellCheck", "false", 3, "keydown", "keyup", "focus", "focusout", "ngStyle", "value", "placeholder"], [3, "id", "ngStyle", "mousedown", 4, "ngIf"], [3, "mousedown", "id", "ngStyle"]],
    template: function EditableInputComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0)(1, "input", 1);
        i0.ɵɵlistener("keydown", function EditableInputComponent_Template_input_keydown_1_listener($event) {
          return ctx.handleKeydown($event);
        })("keyup", function EditableInputComponent_Template_input_keyup_1_listener($event) {
          return ctx.handleKeyup($event);
        })("focus", function EditableInputComponent_Template_input_focus_1_listener($event) {
          return ctx.handleFocus($event);
        })("focusout", function EditableInputComponent_Template_input_focusout_1_listener($event) {
          return ctx.handleFocusOut($event);
        });
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(2, EditableInputComponent_span_2_Template, 2, 3, "span", 2);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngStyle", ctx.wrapStyle);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngStyle", ctx.inputStyle)("value", ctx.currentValue)("placeholder", ctx.placeholder);
        i0.ɵɵattribute("aria-labelledby", ctx.uniqueId);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.label);
      }
    },
    dependencies: [i1.NgIf, i1.NgStyle],
    styles: ["[_nghost-%COMP%]{display:flex}.wrap[_ngcontent-%COMP%]{position:relative}"],
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditableInputComponent, [{
    type: Component,
    args: [{
      selector: 'color-editable-input',
      template: `
    <div class="wrap" [ngStyle]="wrapStyle">
      <input
        [ngStyle]="inputStyle"
        spellCheck="false"
        [value]="currentValue"
        [placeholder]="placeholder"
        (keydown)="handleKeydown($event)"
        (keyup)="handleKeyup($event)"
        (focus)="handleFocus($event)"
        (focusout)="handleFocusOut($event)"
        [attr.aria-labelledby]="uniqueId"
      />
      <span [id]="uniqueId" *ngIf="label" [ngStyle]="labelStyle" (mousedown)="handleMousedown($event)">
        {{ label }}
      </span>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [":host{display:flex}.wrap{position:relative}\n"]
    }]
  }], null, {
    style: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    arrowOffset: [{
      type: Input
    }],
    dragLabel: [{
      type: Input
    }],
    dragMax: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }]
  });
})();
class EditableInputModule {
  static ɵfac = function EditableInputModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || EditableInputModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: EditableInputModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditableInputModule, [{
    type: NgModule,
    args: [{
      declarations: [EditableInputComponent],
      exports: [EditableInputComponent],
      imports: [CommonModule]
    }]
  }], null, null);
})();
class HueComponent {
  hsl;
  pointer;
  radius;
  shadow;
  hidePointer = false;
  direction = 'horizontal';
  onChange = new EventEmitter();
  left = '0px';
  top = '';
  ngOnChanges() {
    if (this.direction === 'horizontal') {
      this.left = `${this.hsl.h * 100 / 360}%`;
    } else {
      this.top = `${-(this.hsl.h * 100 / 360) + 100}%`;
    }
  }
  handleChange({
    top,
    left,
    containerHeight,
    containerWidth,
    $event
  }) {
    let data;
    if (this.direction === 'vertical') {
      let h;
      if (top < 0) {
        h = 359;
      } else if (top > containerHeight) {
        h = 0;
      } else {
        const percent = -(top * 100 / containerHeight) + 100;
        h = 360 * percent / 100;
      }
      if (this.hsl.h !== h) {
        data = {
          h,
          s: this.hsl.s,
          l: this.hsl.l,
          a: this.hsl.a,
          source: 'rgb'
        };
      }
    } else {
      let h;
      if (left < 0) {
        h = 0;
      } else if (left > containerWidth) {
        h = 359;
      } else {
        const percent = left * 100 / containerWidth;
        h = 360 * percent / 100;
      }
      if (this.hsl.h !== h) {
        data = {
          h,
          s: this.hsl.s,
          l: this.hsl.l,
          a: this.hsl.a,
          source: 'rgb'
        };
      }
    }
    if (!data) {
      return;
    }
    this.onChange.emit({
      data,
      $event
    });
  }
  static ɵfac = function HueComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || HueComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: HueComponent,
    selectors: [["color-hue"]],
    inputs: {
      hsl: "hsl",
      pointer: "pointer",
      radius: "radius",
      shadow: "shadow",
      hidePointer: "hidePointer",
      direction: "direction"
    },
    outputs: {
      onChange: "onChange"
    },
    features: [i0.ɵɵNgOnChangesFeature],
    decls: 3,
    vars: 8,
    consts: [["ngx-color-coordinates", "", 1, "color-hue-container", 3, "coordinatesChange"], ["class", "color-hue-pointer", 3, "left", "top", 4, "ngIf"], [1, "color-hue-pointer"], [1, "color-hue-slider", 3, "ngStyle"]],
    template: function HueComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div")(1, "div", 0);
        i0.ɵɵlistener("coordinatesChange", function HueComponent_Template_div_coordinatesChange_1_listener($event) {
          return ctx.handleChange($event);
        });
        i0.ɵɵtemplate(2, HueComponent_div_2_Template, 2, 5, "div", 1);
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵclassMapInterpolate1("color-hue color-hue-", ctx.direction, "");
        i0.ɵɵstyleProp("border-radius", ctx.radius, "px")("box-shadow", ctx.shadow);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.hidePointer);
      }
    },
    dependencies: [i1.NgIf, i1.NgStyle, CoordinatesDirective],
    styles: [".color-hue[_ngcontent-%COMP%]{position:absolute;inset:0}.color-hue-container[_ngcontent-%COMP%]{margin:0 2px;position:relative;height:100%}.color-hue-pointer[_ngcontent-%COMP%]{position:absolute}.color-hue-slider[_ngcontent-%COMP%]{margin-top:1px;width:4px;border-radius:1px;height:8px;box-shadow:0 0 2px #0009;background:#fff;transform:translate(-2px)}.color-hue-horizontal[_ngcontent-%COMP%]{background:linear-gradient(to right,#f00 0%,#ff0 17%,#0f0 33%,#0ff 50%,#00f 67%,#f0f 83%,#f00 100%)}.color-hue-vertical[_ngcontent-%COMP%]{background:linear-gradient(to top,#f00 0%,#ff0 17%,#0f0 33%,#0ff 50%,#00f 67%,#f0f 83%,#f00 100%)}"],
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HueComponent, [{
    type: Component,
    args: [{
      selector: 'color-hue',
      template: `
  <div class="color-hue color-hue-{{direction}}" [style.border-radius.px]="radius" [style.box-shadow]="shadow">
    <div ngx-color-coordinates (coordinatesChange)="handleChange($event)" class="color-hue-container">
      <div class="color-hue-pointer" [style.left]="left" [style.top]="top" *ngIf="!hidePointer">
        <div class="color-hue-slider" [ngStyle]="pointer"></div>
      </div>
    </div>
  </div>
  `,
      preserveWhitespaces: false,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [".color-hue{position:absolute;inset:0}.color-hue-container{margin:0 2px;position:relative;height:100%}.color-hue-pointer{position:absolute}.color-hue-slider{margin-top:1px;width:4px;border-radius:1px;height:8px;box-shadow:0 0 2px #0009;background:#fff;transform:translate(-2px)}.color-hue-horizontal{background:linear-gradient(to right,#f00 0%,#ff0 17%,#0f0 33%,#0ff 50%,#00f 67%,#f0f 83%,#f00 100%)}.color-hue-vertical{background:linear-gradient(to top,#f00 0%,#ff0 17%,#0f0 33%,#0ff 50%,#00f 67%,#f0f 83%,#f00 100%)}\n"]
    }]
  }], null, {
    hsl: [{
      type: Input
    }],
    pointer: [{
      type: Input
    }],
    radius: [{
      type: Input
    }],
    shadow: [{
      type: Input
    }],
    hidePointer: [{
      type: Input
    }],
    direction: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }]
  });
})();
class HueModule {
  static ɵfac = function HueModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || HueModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: HueModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, CoordinatesModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HueModule, [{
    type: NgModule,
    args: [{
      declarations: [HueComponent],
      exports: [HueComponent],
      imports: [CommonModule, CoordinatesModule]
    }]
  }], null, null);
})();
class RaisedComponent {
  zDepth = 1;
  radius = 1;
  background = '#fff';
  static ɵfac = function RaisedComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RaisedComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: RaisedComponent,
    selectors: [["color-raised"]],
    inputs: {
      zDepth: "zDepth",
      radius: "radius",
      background: "background"
    },
    ngContentSelectors: _c0,
    decls: 4,
    vars: 5,
    consts: [[1, "raised-wrap"], [1, "raised-content"]],
    template: function RaisedComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "div");
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵprojection(3);
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵclassMapInterpolate1("raised-bg zDepth-", ctx.zDepth, "");
        i0.ɵɵstyleProp("background", ctx.background);
      }
    },
    styles: [".raised-wrap[_ngcontent-%COMP%]{position:relative;display:inline-block}.raised-bg[_ngcontent-%COMP%]{position:absolute;inset:0}.raised-content[_ngcontent-%COMP%]{position:relative}.zDepth-0[_ngcontent-%COMP%]{box-shadow:none}.zDepth-1[_ngcontent-%COMP%]{box-shadow:0 2px 10px #0000001f,0 2px 5px #00000029}.zDepth-2[_ngcontent-%COMP%]{box-shadow:0 6px 20px #00000030,0 8px 17px #0003}.zDepth-3[_ngcontent-%COMP%]{box-shadow:0 17px 50px #00000030,0 12px 15px #0000003d}.zDepth-4[_ngcontent-%COMP%]{box-shadow:0 25px 55px #00000036,0 16px 28px #00000038}.zDepth-5[_ngcontent-%COMP%]{box-shadow:0 40px 77px #00000038,0 27px 24px #0003}"],
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RaisedComponent, [{
    type: Component,
    args: [{
      selector: 'color-raised',
      template: `
  <div class="raised-wrap">
    <div class="raised-bg zDepth-{{zDepth}}" [style.background]="background"></div>
    <div class="raised-content">
      <ng-content></ng-content>
    </div>
  </div>
  `,
      preserveWhitespaces: false,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [".raised-wrap{position:relative;display:inline-block}.raised-bg{position:absolute;inset:0}.raised-content{position:relative}.zDepth-0{box-shadow:none}.zDepth-1{box-shadow:0 2px 10px #0000001f,0 2px 5px #00000029}.zDepth-2{box-shadow:0 6px 20px #00000030,0 8px 17px #0003}.zDepth-3{box-shadow:0 17px 50px #00000030,0 12px 15px #0000003d}.zDepth-4{box-shadow:0 25px 55px #00000036,0 16px 28px #00000038}.zDepth-5{box-shadow:0 40px 77px #00000038,0 27px 24px #0003}\n"]
    }]
  }], null, {
    zDepth: [{
      type: Input
    }],
    radius: [{
      type: Input
    }],
    background: [{
      type: Input
    }]
  });
})();
class RaisedModule {
  static ɵfac = function RaisedModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RaisedModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: RaisedModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RaisedModule, [{
    type: NgModule,
    args: [{
      declarations: [RaisedComponent],
      exports: [RaisedComponent],
      imports: [CommonModule]
    }]
  }], null, null);
})();
class SaturationComponent {
  hsl;
  hsv;
  radius;
  pointer;
  circle;
  onChange = new EventEmitter();
  background;
  pointerTop;
  pointerLeft;
  ngOnChanges() {
    this.background = `hsl(${this.hsl.h}, 100%, 50%)`;
    this.pointerTop = -(this.hsv.v * 100) + 1 + 100 + '%';
    this.pointerLeft = this.hsv.s * 100 + '%';
  }
  handleChange({
    top,
    left,
    containerHeight,
    containerWidth,
    $event
  }) {
    if (left < 0) {
      left = 0;
    } else if (left > containerWidth) {
      left = containerWidth;
    } else if (top < 0) {
      top = 0;
    } else if (top > containerHeight) {
      top = containerHeight;
    }
    const saturation = left / containerWidth;
    let bright = -(top / containerHeight) + 1;
    bright = bright > 0 ? bright : 0;
    bright = bright > 1 ? 1 : bright;
    const data = {
      h: this.hsl.h,
      s: saturation,
      v: bright,
      a: this.hsl.a,
      source: 'hsva'
    };
    this.onChange.emit({
      data,
      $event
    });
  }
  static ɵfac = function SaturationComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SaturationComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SaturationComponent,
    selectors: [["color-saturation"]],
    inputs: {
      hsl: "hsl",
      hsv: "hsv",
      radius: "radius",
      pointer: "pointer",
      circle: "circle"
    },
    outputs: {
      onChange: "onChange"
    },
    features: [i0.ɵɵNgOnChangesFeature],
    decls: 5,
    vars: 8,
    consts: [["ngx-color-coordinates", "", 1, "color-saturation", 3, "coordinatesChange"], [1, "saturation-white"], [1, "saturation-black"], [1, "saturation-pointer", 3, "ngStyle"], [1, "saturation-circle", 3, "ngStyle"]],
    template: function SaturationComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("coordinatesChange", function SaturationComponent_Template_div_coordinatesChange_0_listener($event) {
          return ctx.handleChange($event);
        });
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelement(2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelement(4, "div", 4);
        i0.ɵɵelementEnd()()();
      }
      if (rf & 2) {
        i0.ɵɵstyleProp("background", ctx.background);
        i0.ɵɵadvance(3);
        i0.ɵɵstyleProp("top", ctx.pointerTop)("left", ctx.pointerLeft);
        i0.ɵɵproperty("ngStyle", ctx.pointer);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngStyle", ctx.circle);
      }
    },
    dependencies: [i1.NgStyle, CoordinatesDirective],
    styles: [".saturation-white[_ngcontent-%COMP%]{background:linear-gradient(to right,#fff,rgba(255,255,255,0));position:absolute;inset:0}.saturation-black[_ngcontent-%COMP%]{background:linear-gradient(to top,#000,rgba(0,0,0,0));position:absolute;inset:0}.color-saturation[_ngcontent-%COMP%]{position:absolute;inset:0}.saturation-pointer[_ngcontent-%COMP%]{position:absolute;cursor:default}.saturation-circle[_ngcontent-%COMP%]{width:4px;height:4px;box-shadow:0 0 0 1.5px #fff,inset 0 0 1px 1px #0000004d,0 0 1px 2px #0006;border-radius:50%;cursor:hand;transform:translate(-2px,-4px)}"],
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SaturationComponent, [{
    type: Component,
    args: [{
      selector: 'color-saturation',
      template: `
  <div class="color-saturation" ngx-color-coordinates (coordinatesChange)="handleChange($event)" [style.background]="background">
    <div class="saturation-white">
      <div class="saturation-black"></div>
      <div class="saturation-pointer" [ngStyle]="pointer" [style.top]="pointerTop" [style.left]="pointerLeft">
        <div class="saturation-circle" [ngStyle]="circle"></div>
      </div>
    </div>
  </div>
  `,
      preserveWhitespaces: false,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [".saturation-white{background:linear-gradient(to right,#fff,rgba(255,255,255,0));position:absolute;inset:0}.saturation-black{background:linear-gradient(to top,#000,rgba(0,0,0,0));position:absolute;inset:0}.color-saturation{position:absolute;inset:0}.saturation-pointer{position:absolute;cursor:default}.saturation-circle{width:4px;height:4px;box-shadow:0 0 0 1.5px #fff,inset 0 0 1px 1px #0000004d,0 0 1px 2px #0006;border-radius:50%;cursor:hand;transform:translate(-2px,-4px)}\n"]
    }]
  }], null, {
    hsl: [{
      type: Input
    }],
    hsv: [{
      type: Input
    }],
    radius: [{
      type: Input
    }],
    pointer: [{
      type: Input
    }],
    circle: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }]
  });
})();
class SaturationModule {
  static ɵfac = function SaturationModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SaturationModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: SaturationModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, CoordinatesModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SaturationModule, [{
    type: NgModule,
    args: [{
      declarations: [SaturationComponent],
      exports: [SaturationComponent],
      imports: [CommonModule, CoordinatesModule]
    }]
  }], null, null);
})();
class SwatchComponent {
  color;
  style = {};
  focusStyle = {};
  focus;
  onClick = new EventEmitter();
  onHover = new EventEmitter();
  divStyles = {};
  focusStyles = {};
  inFocus = false;
  ngOnInit() {
    this.divStyles = {
      background: this.color,
      ...this.style
    };
  }
  currentStyles() {
    this.focusStyles = {
      ...this.divStyles,
      ...this.focusStyle
    };
    return this.focus || this.inFocus ? this.focusStyles : this.divStyles;
  }
  handleFocusOut() {
    this.inFocus = false;
  }
  handleFocus() {
    this.inFocus = true;
  }
  handleHover(hex, $event) {
    this.onHover.emit({
      hex,
      $event
    });
  }
  handleClick(hex, $event) {
    this.onClick.emit({
      hex,
      $event
    });
  }
  static ɵfac = function SwatchComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SwatchComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SwatchComponent,
    selectors: [["color-swatch"]],
    inputs: {
      color: "color",
      style: "style",
      focusStyle: "focusStyle",
      focus: "focus"
    },
    outputs: {
      onClick: "onClick",
      onHover: "onHover"
    },
    ngContentSelectors: _c0,
    decls: 3,
    vars: 3,
    consts: [["tabindex", "0", 1, "swatch", 3, "click", "keydown.enter", "focus", "blur", "mouseover", "ngStyle"], ["boxShadow", "inset 0 0 0 1px rgba(0,0,0,0.1)", 4, "ngIf"], ["boxShadow", "inset 0 0 0 1px rgba(0,0,0,0.1)"]],
    template: function SwatchComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("click", function SwatchComponent_Template_div_click_0_listener($event) {
          return ctx.handleClick(ctx.color, $event);
        })("keydown.enter", function SwatchComponent_Template_div_keydown_enter_0_listener($event) {
          return ctx.handleClick(ctx.color, $event);
        })("focus", function SwatchComponent_Template_div_focus_0_listener() {
          return ctx.handleFocus();
        })("blur", function SwatchComponent_Template_div_blur_0_listener() {
          return ctx.handleFocusOut();
        })("mouseover", function SwatchComponent_Template_div_mouseover_0_listener($event) {
          return ctx.handleHover(ctx.color, $event);
        });
        i0.ɵɵprojection(1);
        i0.ɵɵtemplate(2, SwatchComponent_color_checkboard_2_Template, 1, 0, "color-checkboard", 1);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngStyle", ctx.currentStyles());
        i0.ɵɵattribute("title", ctx.color);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.color === "transparent");
      }
    },
    dependencies: [i1.NgIf, i1.NgStyle, CheckboardComponent],
    styles: [".swatch[_ngcontent-%COMP%]{outline:none;height:100%;width:100%;cursor:pointer;position:relative}"],
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SwatchComponent, [{
    type: Component,
    args: [{
      selector: 'color-swatch',
      template: `
    <div
      class="swatch"
      [ngStyle]="currentStyles()"
      [attr.title]="color"
      (click)="handleClick(color, $event)"
      (keydown.enter)="handleClick(color, $event)"
      (focus)="handleFocus()"
      (blur)="handleFocusOut()"
      (mouseover)="handleHover(color, $event)"
      tabindex="0"
    >
      <ng-content></ng-content>
      <color-checkboard
        *ngIf="color === 'transparent'"
        boxShadow="inset 0 0 0 1px rgba(0,0,0,0.1)"
      ></color-checkboard>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [".swatch{outline:none;height:100%;width:100%;cursor:pointer;position:relative}\n"]
    }]
  }], null, {
    color: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    focusStyle: [{
      type: Input
    }],
    focus: [{
      type: Input
    }],
    onClick: [{
      type: Output
    }],
    onHover: [{
      type: Output
    }]
  });
})();
class SwatchModule {
  static ɵfac = function SwatchModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SwatchModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: SwatchModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, CheckboardModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SwatchModule, [{
    type: NgModule,
    args: [{
      declarations: [SwatchComponent],
      exports: [SwatchComponent],
      imports: [CommonModule, CheckboardModule]
    }]
  }], null, null);
})();
class ShadeComponent {
  hsl;
  rgb;
  pointer;
  shadow;
  radius;
  onChange = new EventEmitter();
  gradient;
  pointerLeft;
  pointerTop;
  ngOnChanges() {
    this.gradient = {
      background: `linear-gradient(to right,
          hsl(${this.hsl.h}, 90%, 55%),
          #000)`
    };
    const hsv = new TinyColor(this.hsl).toHsv();
    this.pointerLeft = 100 - hsv.v * 100;
  }
  handleChange({
    left,
    containerWidth,
    $event
  }) {
    let data;
    let v;
    if (left < 0) {
      v = 0;
    } else if (left > containerWidth) {
      v = 1;
    } else {
      v = Math.round(left * 100 / containerWidth) / 100;
    }
    const hsv = new TinyColor(this.hsl).toHsv();
    if (hsv.v !== v) {
      data = {
        h: this.hsl.h,
        s: 100,
        v: 1 - v,
        l: this.hsl.l,
        a: this.hsl.a,
        source: 'rgb'
      };
    }
    if (!data) {
      return;
    }
    this.onChange.emit({
      data,
      $event
    });
  }
  static ɵfac = function ShadeComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ShadeComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ShadeComponent,
    selectors: [["color-shade"]],
    inputs: {
      hsl: "hsl",
      rgb: "rgb",
      pointer: "pointer",
      shadow: "shadow",
      radius: "radius"
    },
    outputs: {
      onChange: "onChange"
    },
    features: [i0.ɵɵNgOnChangesFeature],
    decls: 5,
    vars: 12,
    consts: [[1, "shade"], [1, "shade-gradient", 3, "ngStyle"], ["ngx-color-coordinates", "", 1, "shade-container", 3, "coordinatesChange"], [1, "shade-pointer"], [1, "shade-slider", 3, "ngStyle"]],
    template: function ShadeComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵlistener("coordinatesChange", function ShadeComponent_Template_div_coordinatesChange_2_listener($event) {
          return ctx.handleChange($event);
        });
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelement(4, "div", 4);
        i0.ɵɵelementEnd()()();
      }
      if (rf & 2) {
        i0.ɵɵstyleProp("border-radius", ctx.radius);
        i0.ɵɵadvance();
        i0.ɵɵstyleProp("box-shadow", ctx.shadow)("border-radius", ctx.radius);
        i0.ɵɵproperty("ngStyle", ctx.gradient);
        i0.ɵɵadvance(2);
        i0.ɵɵstyleProp("left", ctx.pointerLeft, "%")("top", ctx.pointerTop, "%");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngStyle", ctx.pointer);
      }
    },
    dependencies: [i1.NgStyle, CoordinatesDirective],
    styles: [".shade[_ngcontent-%COMP%], .shade-gradient[_ngcontent-%COMP%]{position:absolute;inset:0}.shade-container[_ngcontent-%COMP%]{position:relative;height:100%;margin:0 3px}.shade-pointer[_ngcontent-%COMP%]{position:absolute}.shade-slider[_ngcontent-%COMP%]{width:4px;border-radius:1px;height:8px;box-shadow:0 0 2px #0009;background:#fff;margin-top:1px;transform:translate(-2px)}"],
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShadeComponent, [{
    type: Component,
    args: [{
      selector: 'color-shade',
      template: `
    <div class="shade" [style.border-radius]="radius">
      <div
        class="shade-gradient"
        [ngStyle]="gradient"
        [style.box-shadow]="shadow"
        [style.border-radius]="radius"
      ></div>
      <div
        ngx-color-coordinates
        (coordinatesChange)="handleChange($event)"
        class="shade-container"
      >
        <div
          class="shade-pointer"
          [style.left.%]="pointerLeft"
          [style.top.%]="pointerTop"
        >
          <div class="shade-slider" [ngStyle]="pointer"></div>
        </div>
      </div>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      preserveWhitespaces: false,
      styles: [".shade,.shade-gradient{position:absolute;inset:0}.shade-container{position:relative;height:100%;margin:0 3px}.shade-pointer{position:absolute}.shade-slider{width:4px;border-radius:1px;height:8px;box-shadow:0 0 2px #0009;background:#fff;margin-top:1px;transform:translate(-2px)}\n"]
    }]
  }], null, {
    hsl: [{
      type: Input
    }],
    rgb: [{
      type: Input
    }],
    pointer: [{
      type: Input
    }],
    shadow: [{
      type: Input
    }],
    radius: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }]
  });
})();
class ShadeModule {
  static ɵfac = function ShadeModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ShadeModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ShadeModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, CoordinatesModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShadeModule, [{
    type: NgModule,
    args: [{
      declarations: [ShadeComponent],
      exports: [ShadeComponent],
      imports: [CommonModule, CoordinatesModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AlphaComponent, AlphaModule, CheckboardComponent, CheckboardModule, ColorMode, ColorWrap, ColorWrapModule, CoordinatesDirective, CoordinatesModule, EditableInputComponent, EditableInputModule, HueComponent, HueModule, RaisedComponent, RaisedModule, SaturationComponent, SaturationModule, ShadeComponent, ShadeModule, SwatchComponent, SwatchModule, getCheckerboard, getContrastingColor, isValidHex, render, simpleCheckForValidColor, toState };
