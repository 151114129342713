<div
  #txtAsset
  class="pointer txt exclude-text"
  (blur)="updateText()"
  [style.opacity]="data?.data?.opacity"
  [style.fontFamily]="data?.data?.fontFamily"
  [style.color]="data?.data?.color"
  [style.fontSize.px]="data?.data?.size"
  [style.backgroundColor]="data?.data?.backgroundColor"
  [style.borderColor]="data?.data?.borderColor"
  [style.padding.px]="data?.data?.padding"
  [style.borderWidth.px]="data?.data?.borderWidth"
  [style.lineHeight]="data?.data?.lineHeight"
  [style.wordSpacing.px]="data?.data?.wordSpacing"
  [style.letterSpacing.px]="data?.data?.letterSpacing"
  [style.textDecorationLine]="data?.data?.textDecorationLine"
  [style.textDecorationStyle]="data?.data?.textDecorationStyle"
  [style.textDecorationColor]="data?.data?.color"
  [style.-webkit-text-stroke]="data?.data?.textStrokeWidth + 'px ' + data?.data?.textStrokeColor"
  [style.mix-blend-mode]="data?.data?.blendMode"
  [style.textDecorationThickness]="'from-font'"
  [style.transform]="data?.data?.transform"
  [style.width.px]="data?.data?.width"
  [style.height.px]="data?.data?.height"
  [style.box-sizing]="'border-box'"
  [style.fontWeight]="data?.data?.fontWeight"
  [class.text-with-shadow]="data?.data?.applyTextShadow"
  [style.display]="'flex'"
  [style.flexDirection]="'column'"
  [style.whiteSpace]="'pre-wrap'"
  [style.wordWrap]="'break-word'"
  [style.borderStyle]="'solid'"
  [style.pointerEvents]="data?.data?.backgroundColor == 'transparent' ? 'none' : 'all'"
  [style.cursor]="data?.data?.backgroundColor == 'transparent' ? undefined : 'pointer'"
>
  <span class="clickable-text exclude-text" 
    [style.color]="data?.data?.color"
    [style.alignItems]="data?.data?.verticalAlign"
    [style.justifyContent]="data?.data?.horizontalAlign"
    [style.textAlign]="data?.data?.horizontalAlign === 'center' ? 'center' : data?.data?.horizontalAlign === 'flex-end' ? 'right' : 'left'"
  >{{ (data?.data?.text && (data?.data?.text?.trim()?.length || 0 > 0)) ? data?.data?.text : 'EDIT ME' }}</span>
</div>
@if(data?.section?.mode == Mode.DEVELOPMENT && data?.data?.display != 'none' && data?.section?.activeAsset == data){
<div
  #menuTrigger="matMenuTrigger"
  (menuOpened)="focusMenu(ta); reselectText(); menuOpened = true;"
  (menuClosed)="menuOpened = false; reselectText()"
  [matMenuTriggerFor]="textMenu"
  [style.left.px]="triggerLocation.x"
  [style.top.px]="triggerLocation.y"
  style="width: 1px; height: 1px; position: absolute;"
></div>

<mat-menu #textMenu class="local-centered-dropdown" [overlapTrigger]="false" [xPosition]="'before'" [yPosition]="'below'">
  <mat-label style="color: white; margin-top: -50px;">Edit Text (shift + return  for line break)</mat-label>
  <mat-divider></mat-divider>
  <br>
  <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
    <mat-label>Text</mat-label>
    <textarea (keydown.enter)="onEnterPress($event)" (keydown.shift.enter)="onShiftEnterPress($event)"
     #ta (change)="updateText(ta.innerText)" [(ngModel)]="text" #gridNameInput matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3"></textarea>
  </mat-form-field>
  <mat-divider style="margin-top: -8px;"></mat-divider>
  <button mat-mini-fab style="float: right; transform : scale(.75)" class="toolbar-button" matTooltip="Looks Good" matTooltipPosition="below" (click)="$event.stopImmediatePropagation(); menuTrigger.closeMenu()">
      <mat-icon>check_circle</mat-icon>
  </button>
</mat-menu>
}
