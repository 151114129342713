import { DestroyRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { ToastService } from './toast.service';
import { Dialog } from '@angular/cdk/dialog';
import { DankTankAuthService } from './dank-tank-auth.service';
import { PaidFeature, PaymentService } from './payment.service';
import { isPlatformBrowser } from '@angular/common';
import { HowToComponent } from '../component/how-to/how-to.component';
import { InfoDialogType, LegalComponent } from '../component/legal/legal.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PopularMemesComponent } from '../component/popular-memes/popular-memes.component';
import { GuidedTourComponent } from '../component/guided-tour/guided-tour.component';
import { AppService } from './app.service';
import { LocalSaveService } from './local-save.service';
import { MatDialog } from '@angular/material/dialog';
import { SavedMemesComponent } from '../component/saved-memes/saved-memes.component';
import { GridSection, Mode } from '../vo/GridSection';
import { Action, AnalyticsService, AppScreen, ScreenAction } from './analytics.service';
import { MediaAttacherComponent } from '../component/media-attacher/media-attacher.component';
import { UploadType } from '../component/file-uploader/file-uploader.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ModalServiceService } from './modal-service.service';
import { UpgradeComponent } from '../component/upgrade/upgrade.component';
import { UpgradeModalComponent } from '../component/upgrade-modal/upgrade-modal.component';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {

  constructor(private modal : ModalServiceService, private bottomSheet : MatBottomSheet, private analytics : AnalyticsService, private localSaveService: LocalSaveService, private appService: AppService, private destroyRef: DestroyRef, private router: Router, @Inject(PLATFORM_ID) private platformId: Object, private toast: ToastService, private dialog: MatDialog, public authService: DankTankAuthService, public paymentService: PaymentService) { }

  private _route?: ActivatedRoute
  private subscriptions: Subscription[] = []
  public set route(route: ActivatedRoute) {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe()
    })
    this._route = route
    this.watchRoutes()
  }

  public get route() : ActivatedRoute | undefined{
    return this._route
  }

  private watchRoutes() {
    if (this.route) {
      if (isPlatformBrowser(this.platformId)) {
        this.subscriptions.push(this.route.queryParamMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
          const action: string | null = params.get('action');
          if (action) {
            this.doAction(action)
          }
        }))
      }
    }
  }

  public killAllPopOvers() {
    setTimeout(() => {
      this.toast.hideAll()
    })
  }

  private doAction(action: 'guidedTour' | 'about' | 'tos' | 'upgrade' | 'saved' | 'howto' | string) {
    setTimeout(() => {
      this.killAllPopOvers()
      if (this.dialog.openDialogs.length > 0){
        setTimeout(() => {
          this.doAction(action)
        }, 500);
      }else{
        switch (action) {
          case "guidedTour":
            this.startGuidedTour(true)
            const visitedKey: string = "firstVisit"
            localStorage.setItem(visitedKey, 'false')
            break
          case "about":
            this.showAbout(true)
            break
          case "tos":
            this.showLegal(true)
            break
          case "saved":
            this.showSaved(true)
            break
          case "howto":
            this.showHowTo(true)
            break
          case 'upload':
            this.showUpload()
            break
          case 'upgrade':
            this.showUpgrade()
            break
          default:
            this.removeQueryParam('action')
        }
      }
    })
  }

  private showUpgrade(){
    this.dialog.open(UpgradeModalComponent)
  }

  private callback() {

  }

  private showUpload(){
    this.bottomSheet.open(MediaAttacherComponent, { data: { uploadType: UploadType.IMAGE, section: this.appService.gridSection } })
  }

  private fakeAuthCallback() {
    //we need to have this because auththen requires a callback, we could change this with a little refactor
  }

  showLegal(doAction: boolean = false) {
    if (doAction) {
      const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.MODAL_OPENED, info : "Legal"}
      this.analytics.track(action)
      //this.secondaryReady = false
      this.modal.open(LegalComponent, {data: InfoDialogType.TOC }).afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(d => {
        this.removeQueryParam('action')
      })
    } else {
      this.addQueryParam('action', 'tos')
    }
  }

  showSaved(doAction: boolean = false) {
    if (doAction) {
      //this.secondaryReady = false
      this.authService.authThen(this.showSavedMemes, this, this.paymentService.getAllPaid(), PaidFeature.DRAFTS)
    } else {
      this.addQueryParam('action', 'saved')
    }
  }

  public savedMemes: number = 0
  public showSavedMemes() {
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.MODAL_OPENED, info : "Saved Memes"}
    this.analytics.track(action)
    this.modal.open(SavedMemesComponent, { data: this }).afterClosed().pipe(take(1)).subscribe(() => {
      this.getSaveMemesCount();
      if (this.appService.gridSection) this.appService.gridSection.mode = Mode.DEVELOPMENT
      this.removeQueryParam('action')
    })
  }

  private getSaveMemesCount() {
    this.localSaveService.getAll().pipe(take(1)).subscribe((val: GridSection[]) => { this.savedMemes = val.length })
  }


  private _showSaved() {

  }

  showAbout(doAction: boolean = false) {
    if (doAction) {
      //this.secondaryReady = false
      const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.MODAL_OPENED, info : "About"}
    this.analytics.track(action)
      this.modal.open(LegalComponent, { data: InfoDialogType.ABOUT }).afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(d => {
        this.removeQueryParam('action')
      })
    } else {
      this.addQueryParam('action', 'about')
    }
  }

  showHowTo(doAction: boolean = false) {
    if (doAction) {
      const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.MODAL_OPENED, info : "How To"}
      this.analytics.track(action)
      this.modal.open(HowToComponent).afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(d => {
        this.removeQueryParam('action')
      })
    } else {
      this.addQueryParam('action', 'howto')
    }
  }

  showPopularMemes() {
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.MODAL_OPENED, info : "Popular Memes"}
    this.analytics.track(action)
    this.modal.open(PopularMemesComponent, { data: this, })
  }

  showContactUs() {
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.MODAL_OPENED, info : "Contact Us"}
    this.analytics.track(action)
    window.open('https://danktank.app/contactus', '_blank');
  }

  getDataModel(): string {
    this.appService.gridSection?.prepareSerialize()
    const toSave = JSON.stringify(this.appService.gridSection)
    this.appService.gridSection?.reapplyCyclicals();
    return toSave
  }


  showDeveloperTools(): Boolean {
    return isPlatformBrowser(this.platformId) ? (window && (window.location.href.includes("127.0.0.1") || window.location.href.includes("localhost"))) : false
  }
  

  startGuidedTour(doAction: boolean = false) {
    if (this.dialog.openDialogs.length > 0) return
    if (doAction) {
      this.dialog.open(GuidedTourComponent, { data: this, panelClass: 'overflow-dialog', hasBackdrop: true, backdropClass: 'clear-backdrop', width: '400px', maxWidth: '95vw' }).afterClosed().pipe(take(1)).subscribe(() => this.removeQueryParam('action'))
    } else {
      this.addQueryParam('action', 'guidedTour')
    }
  }

  private removeQueryParam(paramName: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { [paramName]: null }, // Remove the specified parameter
      queryParamsHandling: 'merge' // Merge with existing query params
    });
  }

  private addQueryParam(paramName: string, paramValue: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { [paramName]: paramValue },
      queryParamsHandling: 'merge' // Merge with existing query params
    });
  }
}
