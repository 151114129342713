
    <div class="menu-input-container" (click)="$event.stopPropagation()" auto-animate>
      <mat-form-field appearance="fill">
        <mat-label>Search Fonts</mat-label>
        <input
          matInput
          [(ngModel)]="fontSearchTerm"
          placeholder="Type to search"
          autocomplete="off"
        />
          <mat-icon
            class="pointer cancel-icon"
            matSuffix
            (click)="$event.stopImmediatePropagation(); fontSearchTerm = ''"
            >cancel</mat-icon
          >
      </mat-form-field>
      @if(!fontService.hasAllFonts()){
        <button #loadFontButton mat-stroked-button style="width: 100%;" (click)="loadFontButton.disabled = true; loadAllFonts()">Load All Fonts</button>
      }
    </div>
    <mat-divider></mat-divider>
  
  @if(fonts && fonts.length > 0) {
    <div class="menu-content" auto-animate>
      @for (font of fonts | fontFilter:fontSearchTerm || ''; track font; let idx =
      $index) {
        <div
          class="font-settings pointer"
          [attr.style]="'font-family:' + font.name + ' !important' | safeStyle"
          (click)="fontService.addUsedFont(font); setFont(font.name)"
        >
          <span class="font-name">{{ font.name }}</span>
        </div>
        @if(idx != fonts.length - 1) {
          <mat-divider></mat-divider>
        }
        @if(idx == fonts.length - 1) {
          <!--<div class="spacer"></div>-->
        }
      }
    </div>
  }@else {
    <div class="progress-section">
        <mat-progress-bar style="max-width: 90%;" mode="indeterminate"></mat-progress-bar>
    </div>
  }