<div mat-dialog-title class="dialog-title">
    @if(selectedPack){
        <button class="button" style="margin: 6px !important;" mat-mini-fab (click)="selectedPack = undefined; searchTerm = ''" matTooltip="Back" matTooltipPosition="below">
            <mat-icon>reply</mat-icon>
        </button>
    }
    @if(!selectedPack){
        <div class="title-holder">
            <h2>{{getTitle()}}</h2>
            <h3>{{getSubtitle()}}</h3>
        </div>
    }
    @if(selectedPack){<h2>{{selectedPack.name}} ({{searchResults && searchResults.length > 0 ? searchResults.length : selectedPack && selectedPack.stickers.length}})</h2>}
</div>
@if(stickerPacks.length > 0){
    <div class="search-holder">
        <mat-form-field class="search-field" appearance="outline">
            <mat-label>Search for {{getTitle(true)}} (at least 3 chars.)</mat-label>
            <input matInput type="text" [(ngModel)]="searchTerm">
            @if (searchTerm) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm=''">
                <mat-icon>close</mat-icon>
            </button>
            }
        </mat-form-field>  
    </div>
}
<mat-dialog-content class="centered-dialog-content-vertical-top dialog-content">
    @if(stickerPacks.length == 0){
        <mat-spinner style="margin: 0 auto !important; margin-top: 25px !important;"></mat-spinner>
    }
    @if(stickerPacks.length > 0 && !selectedPack && searchResults.length == 0){
        <mat-list style="margin-top: -40px;">
            @for (pack of stickerPacks; track pack; let i = $index;) {
                <mat-list-item class="pointer list-item" (click)="selectedPack = pack">
                    <div matListItemIcon class="icon-holder" 
                        [style.backgroundColor]="pack.highContrast ? 'white' : null">
                        @if(!images[pack.stickers[0].name]){<mat-icon class="loading-icon">pending</mat-icon>}
                        @if(images[pack.stickers[0].name] == ImageLoadStatus.ERROR){<mat-icon>error</mat-icon>}
                        @if(images[pack.stickers[0].name] != ImageLoadStatus.ERROR){
                            <img  #img 
                                [style.position]="images[pack.stickers[0].name] == ImageLoadStatus.LOADED ? 'static' : 'absolute'"
                                (load)="images[pack.stickers[0].name] =  ImageLoadStatus.LOADED; img.style.opacity = '1'"
                                (error)="images[pack.stickers[0].name] =  ImageLoadStatus.ERROR"  
                                class="list-icon fade-img" 
                                [appLazyLoad]="uriFromSticker(pack.stickers[0])"
                                />
                        }
                    </div>
                    <div matListItemTitle style="margin-top: 10px;" class="focus-text-color">{{pack.name}}</div>
                    <div matListItemLine class="regular-text-color">{{pack.description}}</div>
                </mat-list-item>
                <br>
                @if(i != stickerPacks.length){<mat-divider></mat-divider>} 
            }
        </mat-list>
    } 
    @if(selectedPack && searchResults.length == 0){

                <mat-grid-list [cols]="config.type == AssetType.MEME || config.type == AssetType.GIF ? 2 : 3" rowHeight="1:1" gutterSize="15px">
                    @for (sticker of selectedPack.stickers; track sticker; let i = $index;) {
                        <mat-grid-tile  class="icon-holder-grid" 
                        (click)="selectSticker(sticker)"
                        [style.backgroundColor]="selectedPack.highContrast ? 'white' : null">
                            @if(images[sticker.name] != ImageLoadStatus.ERROR && selectedPack.name.includes('Most Used')){
                            <mat-chip-set style="position: absolute; top: 10px; right: 10px; z-index: 100;">
                                <mat-chip color="primary" class="chip">
                                    #{{i + 1}}
                                    <mat-icon class="ranking-icon" svgIcon="medal"></mat-icon>
                                </mat-chip> 
                             </mat-chip-set>
                            }
                            @if(!images[sticker.name]){<mat-icon class="loading-icon">pending</mat-icon>}
                            @if(images[sticker.name] == ImageLoadStatus.ERROR){<mat-icon>error</mat-icon>}
                            @if(images[sticker.name] != ImageLoadStatus.ERROR){
                                <img  #img 
                                    
                                    [style.position]="images[sticker.name] == ImageLoadStatus.LOADED ? 'static' : 'absolute'"
                                    [style.width]="images[sticker.name] ? null : '0px'"
                                    [appLazyLoad]="uriFromSticker(sticker)"
                                    (load)="images[sticker.name] =  ImageLoadStatus.LOADED; img.style.opacity = '1'"  
                                    (error)="images[sticker.name] =  ImageLoadStatus.ERROR"  
                                    class="grid-sticker pointer fade-img" 
                                    />
                            }
                        </mat-grid-tile>
                    }
                  </mat-grid-list>      

        }
    @if(stickerPacks.length > 0  && searchResults.length > 0){
        <mat-grid-list [cols]="config.type == AssetType.MEME || config.type == AssetType.GIF ? 2 : 3"  rowHeight="1:1" gutterSize="15px">
            @for (sticker of searchResults; track sticker; let i = $index;) {
                <mat-grid-tile class="icon-holder-grid" 
                (click)="selectSticker(sticker)"
                [style.backgroundColor]="getPackFromSticker(sticker)?.highContrast ? 'white' : null">
                   
                    @if(!images[sticker.name]){<mat-icon class="loading-icon">pending</mat-icon>}
                    @if(images[sticker.name] == ImageLoadStatus.ERROR){<mat-icon>error</mat-icon>}
                    @if(images[sticker.name] != ImageLoadStatus.ERROR){
                        <img  #img 
                            [style.position]="images[sticker.name] == ImageLoadStatus.LOADED ? 'static' : 'absolute'"
                            [appLazyLoad]="uriFromSticker(sticker)"
                            [style.width]="images[sticker.name] ? null : '0px'"
                            (load)="images[sticker.name] =  ImageLoadStatus.LOADED; img.style.opacity = '1'"  
                            (error)="images[sticker.name] =  ImageLoadStatus.ERROR"  
                            class="grid-sticker pointer fade-img" 
                            />
                    }
                </mat-grid-tile>
            }
        </mat-grid-list> 
    }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="cancel()">Cancel</button>
</mat-dialog-actions>