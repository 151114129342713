<h2 mat-dialog-title class="dialog-title">{{ !paidSuccessfully ? title : 'Success!' }}</h2>
@if(needsToUpgrade()){
  <div class="dialog-content">
    <strong class="subtle-gradient-text">You need to upgrade your account to use this feature, your current plan will be prorated as of today, and you will be immediately upgraded, tap the button below and update your subscription to Pro.</strong>
    <br>
    <br>
    <app-profile-button></app-profile-button>
  </div>
}@else{
  <div class="dialog-content">
    @if(elementsOptions.clientSecret && !paidSuccessfully && !paying && !paymentFailed){
    <form [formGroup]="paymentElementForm" class="payment-form">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Name</mat-label>
        <input #name matInput formControlName="name" />
        @if (paymentElementForm.get('name')?.hasError('required') &&
        paymentElementForm.get('name')?.touched) {
        <mat-error>Name is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" />
        @if (paymentElementForm.get('email')?.hasError('required') &&
        paymentElementForm.get('email')?.touched) {
        <mat-error>Email is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="full-width address-field" appearance="outline">
        <mat-label>Address</mat-label>
        <input matInput formControlName="address" />
        @if (paymentElementForm.get('address')?.hasError('required') &&
        paymentElementForm.get('address')?.touched) {
        <mat-error>Address is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>ZIP Code</mat-label>
        <input matInput formControlName="zipcode" />
        @if (paymentElementForm.get('zipcode')?.hasError('required') &&
        paymentElementForm.get('zipcode')?.touched) {
        <mat-error>ZIP Code is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>City</mat-label>
        <input matInput formControlName="city" />
        @if (paymentElementForm.get('city')?.hasError('required') &&
        paymentElementForm.get('city')?.touched) {
        <mat-error>City is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="full-width-field pointer" appearance="outline">
        <mat-label>Discount Code</mat-label>
        @if(applyingDiscount){
          <mat-spinner
            matSuffix
            diameter="35"
            style="margin-right: 10px"
          ></mat-spinner>
        }@else{
          <mat-icon matSuffix (click)="$event.stopPropagation(); applyDiscount(discount.value)">
            {{ discountMessage?.includes('Invalid') ? 'cancel' : (discountMessage ? 'check_circle' : 'check') }}
          </mat-icon>
        }
        <input #discount matInput formControlName="discountCode" />
        <!--<mat-error>City is required</mat-error>-->
        @if(discountMessage){
          <div class="subtle-gradient-text">{{discountMessage}}</div>
        }
      </mat-form-field>
    </form>
    }
    @if((!elementsOptions.clientSecret && !paidSuccessfully) || paying){
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
    @if(elementsOptions.clientSecret){
      @if(elementsOptions.clientSecret && !paidSuccessfully && !paying && !paymentFailed){
          <img #stripeImage class="fade-img" style="max-height: 40px; width: auto; margin-bottom: 15px;" (load)="stripeImage.style.opacity = '1'" src="https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/media.danktank.cloud/assets/stripe.svg">
      }
        <ngx-stripe-elements #spe [stripe]="stripe" [elementsOptions]="elementsOptions" [style.display]="paying || paymentFailed || paidSuccessfully ? 'none' : null">
          <ngx-stripe-payment #sp  [options]="paymentElementOptions"></ngx-stripe-payment>
        </ngx-stripe-elements>
    }
  </div>
}
@if(paidSuccessfully){
  <div class="payment-success">
    <div class="left-section">
      <img #successImg class="fade-img" (load)="successImg.style.opacity = '1'" src="https://dinbxwvmllmigojxxcce.supabase.co/storage/v1/object/public/media.danktank.cloud/assets/paid.svg" alt="Success Graphic" class="success-graphic">
    </div>
    <div class="right-section">
      <h2 class="success-title">Payment Successful!</h2>
      <p class="success-message">Thank you for your subscription. Your transaction was completed successfully.</p>
    </div>
  </div>
}
@if(paymentFailed || upgradeFailed){
  <div class="payment-success">
    <div class="left-section">
      <img #failureImage class="fade-img" (load)="failureImage.style.opacity = '1'" src="https://dinbxwvmllmigojxxcce.supabase.co/storage/v1/object/public/media.danktank.cloud/assets/payment_failed.svg" alt="Failed Graphic" class="success-graphic">
    </div>
    <div class="right-section">
      <h2 class="failed-title">Payment Failed!</h2>
      <p class="success-message">{{paymentFailedMessage}}</p>
    </div>
  </div>
}
@if(needsToUpgrade()){
  <div class="dialog-actions">
    @if(!upgradeFailed && !upgrading && !paidSuccessfully){
      <button mat-dialog-close
      mat-fab extended>
          CANCEL
      </button>
    }
    @if(upgradeFailed){
      <button
      mat-fab extended
          (click)="retry()">
          TRY AGAIN
      </button>
    }
    @if(paidSuccessfully){
      <button
      mat-fab extended
          (click)="continue()">
          NEXT
      </button>
    }
  </div>
}@else{
  <div class="dialog-actions">
    @if(elementsOptions.clientSecret && !paidSuccessfully && !paying && !paymentFailed){
        <button
        mat-fab extended
            (click)="pay()"
            [disabled]="paying || paymentElementForm.invalid">
            PAY {{data.type.toString().toLowerCase().includes('yearly') ? (((data.monthlyPrice || 0) * 12) | currency) + ' (YEARLY)'  : data.monthlyPrice | currency}}
        </button>
      }
      @if(paidSuccessfully){
        <button
        mat-fab extended
            (click)="continue()">
            NEXT
        </button>
      }
      @if(paymentFailed){
        <button
        mat-fab extended
            (click)="retry()">
            TRY AGAIN
        </button>
      }
  </div>
}
