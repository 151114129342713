import { Component, OnInit, Renderer2, Input, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-ad-embed',
  standalone: true,
  imports: [],
  templateUrl: './ad-embed.component.html',
  styleUrl: './ad-embed.component.scss'
})
export class AdEmbedComponent implements OnInit {

  @Input() key: string = '';
  @Input() height: number = 60;
  @Input() width: number = 468;
  
  private adContainerId = 'ad-container';

  constructor(private renderer: Renderer2, private zone: NgZone) { }

  ngOnInit(): void {
    this.loadScript();
    this.detectAdBlock();
  }

  loadScript() {
    const atOptions = {
      key: this.key,
      format: 'iframe',
      height: this.height,
      width: this.width,
      params: {},
    };

    this.zone.runOutsideAngular(() => {
      const scriptOptions = this.renderer.createElement('script');
      scriptOptions.type = 'text/javascript';
      scriptOptions.text = `atOptions = ${JSON.stringify(atOptions)}`;
      this.renderer.appendChild(document.getElementById(this.adContainerId), scriptOptions);

      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = environment.adURI;
      this.renderer.appendChild(document.getElementById(this.adContainerId), script);
    });
  }

  detectAdBlock() {
    // Delay the check to allow the script and iframe to load
    setTimeout(() => {
      const adContainer = document.getElementById(this.adContainerId);
      if (!adContainer || (adContainer && adContainer.innerHTML && !adContainer.innerHTML.includes('<iframe'))) {
        this.showFallback();
      }
    }, 3000); // Adjust timing as needed
  }

  showFallback() {
    const adContainer = document.getElementById(this.adContainerId);
    if (adContainer) {
      adContainer.innerHTML = ''; // Clear any existing content
  
      // Add CSS animation for the background to move horizontally
      const style = `
        @keyframes moveBackgroundHorizontally {
          0% {
            background-position: 0 0;
          }
          100% {
            background-position: 200% 0; /* Controls horizontal movement */
          }
        }
  
        /* Mouseover animation */
        .fallback-message {
          transition: transform 0.3s ease;
        }
        .fallback-message:hover {
          transform: scale(1.05); /* Scale up slightly on hover */
        }
      `;
  
      const styleTag = document.createElement('style');
      styleTag.innerHTML = style;
      document.head.appendChild(styleTag);
  
      adContainer.style.background = 'repeating-linear-gradient(45deg, #f7f7f7, #f7f7f7 10px, #dcdcdc 10px, #dcdcdc 20px)';
      adContainer.style.backgroundSize = '200% 100%'; // Ensure the gradient stretches fully horizontally
      adContainer.style.animation = 'moveBackgroundHorizontally 10s linear infinite'; // Slower horizontal animation
      adContainer.style.display = 'flex';
      adContainer.style.alignItems = 'center';
      adContainer.style.justifyContent = 'center';
      adContainer.style.padding = '0 20px';  // Adjust padding for the sides only
      adContainer.style.borderRadius = '12px'; 
      adContainer.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
      adContainer.style.maxWidth = `${this.width}px`;
      adContainer.style.margin = 'auto';
      adContainer.style.height = `${this.height}px`;  // Ensure height remains fixed
  
      // Add a more elegant message with updated styling
      adContainer.innerHTML = `
        <p class="fallback-message" style="
          font-size: calc(5px + 1vw); 
          color: #333; 
          font-family: 'Arial', sans-serif; 
          margin: 0; 
          padding: 15px; 
          background-color: #fff; 
          border-radius: 8px; 
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); 
          text-align: center;
          max-width: 90%; /* Ensure text doesn't exceed the container */
          max-height: 85%;
          box-sizing: border-box;">
          To enhance your experience, please consider disabling your ad blocker and refreshing the page. Thank you!
        </p>`;
    }
  }
}