<h2 mat-dialog-title class="dialog-title">Drafts</h2>
<mat-dialog-content class="dialog-content" style="margin-top: 10px;">
        @if(savedMemes?.length == 0){
            <strong><p class="subtle-gradient-text">You haven't saved any drafts yet</p></strong>
        }
        @for (savedMeme of savedMemes; track savedMeme; let idx = $index;) {
            <div class="draft-row">
                <div class="draft-container pointer" (click)="openMeme(savedMeme)">
                  <span class="draft-text">{{savedMeme.name}}</span>
                  <mat-icon class="draft-arrow">arrow_forward_ios</mat-icon>
                </div>
                <mat-icon class="delete-icon pointer" (click)="deleteMeme(savedMeme)">delete</mat-icon>
              </div>
        }   
</mat-dialog-content> 
<mat-dialog-actions align="end">
    <button mat-fab extended mat-dialog-close>
      Got it!
    </button>
  </mat-dialog-actions>
  