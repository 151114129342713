// context-menu.service.ts
import { ComponentFactoryResolver, ComponentRef, Inject, Injectable, PLATFORM_ID, ViewContainerRef } from '@angular/core';
import { ContextMenuComponent } from '../component/context-menu/context-menu.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { fromEvent, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

export interface ContextMenuAction {
  label: string;
  icon: string;
  scope?: any;
  callback?: () => void;
}

export interface ContextMenuCoordinates {
  x: number;
  y: number;
}


@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {
  private contextMenu?: ContextMenuComponent
  private backdrop: HTMLDivElement | null = null;


  private activeMenu ?: ComponentRef<ContextMenuComponent>
  private activeMenuParent ?: ViewContainerRef
  private activeMenuCloseSubscription ?: Subscription

  constructor(@Inject(PLATFORM_ID) private platformId: Object, ){
    if(isPlatformBrowser(this.platformId)){
      fromEvent<MouseEvent>(document, 'contextmenu').subscribe((event : MouseEvent) => {
        event.preventDefault()
        this.removeMenu()
      })
    }
  }

  showContextMenuWithElement(element: ViewContainerRef, actions: ContextMenuAction[], coordinates?: ContextMenuCoordinates) {
    setTimeout(() => {
      this.activeMenuParent = element
      this.activeMenu = element.createComponent<ContextMenuComponent>(ContextMenuComponent);
      const menu: ContextMenuComponent = this.activeMenu.instance;
    
      if (coordinates) {
        const el = menu.elRef.nativeElement;
        el.style.position = 'absolute';
        el.style.top = `${coordinates.y}px`;
        el.style.left = `${coordinates.x}px`;
      }
    
      
      menu.openWithActions(actions)
      
      
      this.activeMenuCloseSubscription = menu.menuClosed.subscribe(() => {
        this.removeMenu()
      })

    }, 100)

  }

  public removeMenu(){

    if(this.activeMenuParent){
      this.activeMenuParent.remove()
    }

    if(this.activeMenuCloseSubscription){
      this.activeMenuCloseSubscription.unsubscribe()
    }

  }

}