import { ApplicationConfig, CSP_NONCE, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
//import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { environment } from "../environments/environment";
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { initializeApp } from 'firebase/app';
import { provideClientHydration } from '@angular/platform-browser';
import { cacheInterceptor } from './interceptor/cache.interceptor';
//import { AdsenseModule } from 'ng2-adsense';
import { provideNgxStripe } from 'ngx-stripe';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { provideServiceWorker } from '@angular/service-worker';



export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptors([cacheInterceptor])),
    provideAnimationsAsync(),
    provideShareButtonsOptions(shareIcons()),
    provideFirebaseApp(() => initializeApp(environment.firebase)), provideClientHydration(),
    provideServiceWorker('ngsw-worker.js', { enabled: environment.production }),
    provideNgxStripe(environment.stripe_publishable_key), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          }), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          })
  ]
}


/*
importProvidersFrom(AdsenseModule.forRoot({
                adClient: 'ca-pub-3881922342269683',
                adSlot: 3883422887,
                adFormat: 'auto',
                fullWidthResponsive: true,
                adtest: "on"
              }),)
              */