import { Observable, Subject } from "rxjs";
import { FontService } from "../service/font.service";
import { Prop, Sticker, TemplateAsset, Viewport } from "../service/sticker.service";
import { ToolbarService } from "../service/toolbar.service";
import { Asset, AssetType, GridSection } from "../vo/GridSection";
import { NgZone } from "@angular/core";

export function createAssetsFromJSON(
    gs: GridSection,
    toolbarService: ToolbarService,
    fontService: FontService,
    sticker: Sticker | undefined,
    zone: NgZone,
    isPlatformBrowser: boolean = false,
    s?: Subject<boolean>
): Observable<boolean> {
    const sub: Subject<boolean> = s || new Subject();

    if (gs.media && isPlatformBrowser && !gs.media.loaded) {
        handleMediaLoading(gs, toolbarService, fontService, sticker, zone, isPlatformBrowser, sub);
        return sub;
    }

    const stkr: Sticker | undefined = sticker || gs.media?.sticker;
    if (stkr && stkr.template?.width && stkr.template.height) {
        setGridSectionAspectRatio(gs, stkr.template.width, stkr.template.height);
    } else if (sticker?.template?.aspectRatio?.includes(":")) {
        // Handle other aspect ratio formats (commented logic could go here)
    } else {
        setDefaultAspectRatio(gs);
    }

    const timeoutDuration: number = isPlatformBrowser ? 500 : 0;
    setTimeout(() => {
        handleFixedFields(gs, toolbarService, stkr);
        processTemplateAssets(gs, toolbarService, fontService, sticker, stkr);
        gs.activeAsset = undefined;
        sub.next(true);
    }, timeoutDuration);

    if (!sticker) sub.next(false);
    return sub;
}

function handleMediaLoading(
    gs: GridSection,
    toolbarService: ToolbarService,
    fontService: FontService,
    sticker: Sticker | undefined,
    zone: NgZone,
    isPlatformBrowser: boolean,
    sub: Subject<boolean>
) {
    zone.runOutsideAngular(() => {
        setTimeout(() => {
            zone.run(() => {
                createAssetsFromJSON(gs, toolbarService, fontService, sticker, zone, isPlatformBrowser, sub);
            });
        }, 100);
    });
}

function setGridSectionAspectRatio(gs: GridSection, width: number, height: number) {
    const { horAR, verAR } = getSimplifiedAspectRatio(width, height);
    gs.horizontalAspectRatio = horAR;
    gs.verticalAspectRatio = verAR;
}

function setDefaultAspectRatio(gs: GridSection) {
    gs.horizontalAspectRatio = 1;
    gs.verticalAspectRatio = 1;
}

function handleFixedFields(gs: GridSection, toolbarService: ToolbarService, stkr?: Sticker) {
    const width = gs.widthInPX || 0;
    const height = gs.heightInPX || 0;

    if (!stkr?.fixedFields) return;

    stkr.fixedFields.forEach(field => {
        let yPosition: string;
        switch (field) {
            case "TOP":
                yPosition = "0";
                break;
            case "MIDDLE":
                yPosition = "40";
                break;
            case "BOTTOM":
                yPosition = "82";
                break;
            default:
                return;
        }

        const asset = gs.addAsset(AssetType.TEXT, toolbarService, false);
        asset.data.width = width * 0.99;
        asset.data.height = 40;
        asset.data.transform = updateTransform(asset.data.transform || '', width, height, yPosition);
    });
}

function processTemplateAssets(
    gs: GridSection,
    toolbarService: ToolbarService,
    fontService: FontService,
    sticker?: Sticker,
    stkr?: Sticker
) {
    const currentViewPort: Viewport = { width: gs.widthInPX || 0, height: gs.heightInPX || 0 };

    stkr?.template?.assets?.forEach((templateAsset: TemplateAsset) => {
        const asset : Asset = gs.addAsset(AssetType.TEXT, toolbarService, false);
        const width = gs.widthInPX || 0;
        const height = gs.heightInPX || 0;

        asset.data.text = templateAsset.value;
        asset.data.color = "black";
        asset.data.textStrokeWidth = 0; // Reset

        if (!sticker?.template?.viewport) throw new Error("Must define viewport on every template".toUpperCase());

        let diff = 0;
        const sizeProp = templateAsset.props.find((p: Prop) => p.prop === "size");
        if (sizeProp) {
            const sizeValue = Number(sizeProp.value);
            const adjustedSize = adjustNumber(currentViewPort, sticker.template.viewport, sizeValue);
            diff = adjustedSize - sizeValue;
            asset.data.size = adjustedSize;
            asset.data.height = adjustedSize + 10;
        } else {
            throw new Error("Must define size property in template".toUpperCase());
        }

        applyAssetProps(asset, templateAsset.props, width, height, diff, fontService, currentViewPort, sticker.template.viewport);
    });
}

function applyAssetProps(
    asset: Asset,
    props: Prop[],
    width: number,
    height: number,
    diff: number,
    fontService: FontService,
    currentViewPort: Viewport,
    targetViewport: Viewport
) {
    props.forEach((prop: Prop) => {
        switch (prop.prop) {
            case "width":
                asset.data.width = width * (Number(prop.value) / 100);
                break;
            case "x":
            case "y":
                asset.data.transform = updateTransformWithDOMMatrix(asset.data.transform || '', prop.prop, prop.value, width, height, diff);
                break;
            case "color":
                asset.data.color = String(prop.value);
                break;
            case "backgroundColor":
                asset.data.backgroundColor = String(prop.value);
                break;
            case "fontFamily":
                fontService.loadFont({ name: String(prop.value) });
                fontService.addUsedFont({ name : String(prop.value)})
                asset.data.fontFamily = String(prop.value);
                break;
            case "rotation":
                asset.data.transform = updateTransformWithDOMMatrix(asset.data.transform || '', "rotation", Number(prop.value), width, height, diff);
                break;
            case "textStrokeWidth":
                const strokeVal = Number(prop.value);
                asset.data.textStrokeWidth = adjustNumber(currentViewPort, targetViewport, strokeVal);
                break;
            case "centerHorizontally":
                asset.data.centerHorizontally = prop.value === "true";
                break;
        }
    });
}

function updateTransform(
    transform: string,
    width: number,
    height: number,
    yPosition: string
): string {
    transform = updateTransformWithDOMMatrix(transform, "y", yPosition, width, height, 0);
    return updateTransformWithDOMMatrix(transform, "x", "1", width, height, 0);
}

function updateTransformWithDOMMatrix(
    transform: string,
    type: "x" | "y" | "rotation",
    value: string | number,
    width: number,
    height: number,
    diff: number
): string {
    let matrix = new DOMMatrix(transform);
    let rotationString = '';

    switch (type) {
        case "x":
            matrix.e = width * (Number(value) / 100);
            break;
        case "y":
            matrix.f = (height * (Number(value) / 100)) + (diff / 2);
            break;
        case "rotation":
            rotationString = `rotate(${Number(value)}deg)`;
            matrix = matrix.rotate(Number(value));
            break;
    }

    return `${matrix.toString()} ${rotationString}`.trim();
}

export function adjustNumber(currentViewport: Viewport, targetViewport?: Viewport, number: number = 0): number {
    if (targetViewport) {
        const scalingFactor = Math.max(
            currentViewport.width / targetViewport.width,
            currentViewport.height / targetViewport.height
        );
        return number * scalingFactor;
    } else {
        return number;
    }
}

function getSimplifiedAspectRatio(width: number, height: number): { horAR: number, verAR: number } {
    const divisor = gcd(width, height);
    return { horAR: width / divisor, verAR: height / divisor };
}

function gcd(a: number, b: number): number {
    return b === 0 ? a : gcd(b, a % b);
}