<div
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="contextMenu"
  style="width: 1px; height: 1px; background-color: transparent;"
></div>

<mat-menu #contextMenu class="custom-menu" [overlapTrigger]="true" [xPosition]="'after'" [yPosition]="'below'">
    @for (action of actions; track $index) {
        <button mat-menu-item (click)="doAction(action)">
            <mat-icon>{{action.icon}}</mat-icon>
            {{action.label}}
        </button>
        @if($index != (actions?.length || 0) - 1){
            <mat-divider></mat-divider>
        }
    }
</mat-menu>