@if(presetsService.minified && activePrimaryNavgiation){
<div class="close-icon-handle fade-in"
    (click)="openSecondaryAction ? openSecondaryAction = undefined : setActivePrimaryNavgiation(undefined)"
    [style.backgroundColor]="openSecondaryAction ? '#282325' : undefined"
    [style.top.px]="openSecondaryAction && (openSecondaryAction.action?.updateType == UpdateType.SLIDER || openSecondaryAction.action?.updateType == UpdateType.HORIZONTAL_LIST)  ? -85 : -35">
    <mat-icon class="minimized-close-icon fade-in">
        cancel
    </mat-icon>
</div>
}
<div
  class="secondary-navigation"
  [style.z-index]="presetsService.minified ? 100 : 0"
  [style.opacity]="activePrimaryNavgiation ? 1 : 1"
  [style.top.px]="secondaryNavigationTop"
  [style.height.px]="secondaryNavigationHeight"
>
        @if(appService.gridSection?.activeAsset){
            <div class="secondary-navigation-header">
                <mat-icon class="collapse-button pointer" (click)="unsetActiveAsset()">expand_circle_down</mat-icon>
                @if(appService.gridSection?.activeAsset?.type == AssetType.TEXT){
                    <div class="asset-type">Text</div>
                }
                @if(appService.gridSection?.activeAsset?.type == AssetType.STICKER){
                    <div class="asset-type">Sticker</div>
                }
                @if(appService.gridSection?.activeAsset?.type == AssetType.MEME){
                    <div class="asset-type">Meme</div>
                }
                @if(appService.gridSection?.activeAsset?.type == AssetType.EMOJI){
                    <div class="asset-type">Emoji</div>
                }
                @if(appService.gridSection?.activeAsset?.type == AssetType.TEXT_BUBBLE){
                    <div class="asset-type">Text bubble</div>
                }
                @if(appService.gridSection?.activeAsset?.type == AssetType.UPLOAD_IMAGE){
                    <div class="asset-type">Upload image</div>
                }
                @if(appService.gridSection?.activeAsset?.type == AssetType.SHAPE){
                    <div class="asset-type">Shape</div>
                }
                @if(appService.gridSection?.activeAsset?.type == AssetType.GIF){
                    <div class="asset-type">Gif</div>
                }
                <div class="flex-expand"></div>
                <mat-icon class="delete-button regular-text-color pointer" (click)="deleteActiveAsset()">delete</mat-icon>
            </div>
        }
    <div class="secondary-action" [style.height.px]="openSecondaryAction && showSecondary ? 50 : 0">
        @if(openSecondaryAction?.action?.updateType == UpdateType.SLIDER){
            <mat-slider class="slider-action" [min]="openSecondaryAction?.action?.updateMin" [max]="openSecondaryAction?.action?.updateMax" [step]="openSecondaryAction?.action?.updateStep" showTickMarks (click)="$event.stopImmediatePropagation()">
                <input #sliderAction matSliderThumb [ngModel]="getPropByPickable(openSecondaryAction)"
                    (input)="updatePropWithPickable(openSecondaryAction, sliderAction.value)" />
            </mat-slider>
        }@else if(openSecondaryAction?.action?.updateType == UpdateType.BUTTON_GROUP){
            <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [ngModel]="getPropByPickable(openSecondaryAction)">
                @for (pickable of openSecondaryAction?.data; track $index) {
                    <mat-button-toggle [value]="pickable.data" (click)="updatePropWithPickable(openSecondaryAction, pickable.data)">
                        <mat-icon class="menu-option-icon">
                            {{pickable.icon}}
                        </mat-icon>
                    </mat-button-toggle>
                }
            </mat-button-toggle-group>
        }@else if(openSecondaryAction?.action?.updateType == UpdateType.HORIZONTAL_LIST){
            <div class="font-list-holder" 
                (wheel)="onWheel($event)" 
                (mousedown)="onMouseDown($event)" 
                (mousemove)="onMouseMove($event)" 
                (mouseup)="onMouseUp($event)" 
                (mouseleave)="onMouseLeave($event)">    
                    @for (font of openSecondaryAction?.data; track $index) {
                        <div class="font-holder menu-option pointer" [style.fontFamily]="font.name" (click)="updatePropWithPickable(openSecondaryAction, font.name)">
                            {{font.name}}
                        </div>
                    }
            </div>
        }
    </div>
    <div [style.top.px]="appService.gridSection?.activeAsset ? 30 : 0" [style.opacity]="secondaryNavigationCanScrollRight ? 1 : 0" class="secondary-navigation-overlay-right"></div>
    <div [style.top.px]="appService.gridSection?.activeAsset ? 30 : 0" [style.opacity]="secondaryNavigationCanScrollLeft ? 1 : 0" class="secondary-navigation-overlay-left"></div>
    <div #secondaryNavigation
    (wheel)="onWheel($event)" 
    (mousedown)="onMouseDown($event)" 
    (mousemove)="onMouseMove($event)" 
    (mouseup)="onMouseUp($event)" 
    (mouseleave)="onMouseLeave($event)"
     class="secondary-navigation-content" [class]="secondaryNavigationCanScrollLeft || secondaryNavigationCanScrollRight ? 'justify-secondary-content-scroll' : 'justify-secondary-content-no-scroll'">
            @for (pickable of activePrimaryNavgiation?.data; track $index) {
                @if(!pickable.action || !pickable.action.shouldShowScope || (pickable.action.shouldShowScope && pickable.action.shouldShowCallback.call(pickable.action.shouldShowScope))){
                    @if(pickable.displayType == DisplayType.SVG){
                        <div #svgDiv class="secondary-menu-option pointer" (click)="secondaryNavigationClicked(pickable)">
                            <div class="svg pointer" [innerHTML]="pickable.icon"></div>
                        </div>
                    }@else if(pickable.displayType == DisplayType.ICON){
                        <div #iconDiv [class]="pickable == openSecondaryAction ? 'secondary-menu-option-selected' : null" class="secondary-menu-option pointer" (click)="$event.stopImmediatePropagation(); secondaryNavigationClicked(pickable, colorTrigger, multipeSelectTrigger, fontTrigger, iconDiv)">
                            <!--start regular menu -->
                            <div class="menu-trigger" #multipeSelectTrigger="matMenuTrigger" [matMenuTriggerFor]="multipleSelectMenu"></div>
                            <mat-menu class="growable-dropdown" [hasBackdrop]="false" #multipleSelectMenu yPosition="above">
                                @if(multipeSelectTrigger.menuOpen && openSecondaryAction?.action?.updateType == UpdateType.MENU){
                                    @for (pickable of openSecondaryAction?.data; track pickable; let idx = $index) {
                                        <button mat-menu-item (click)="updatePropWithPickable(pickable, pickable.data)" (click)="updatePropWithPickable(openSecondaryAction, pickable.data)">
                                            @if(pickable.icon.includes('_svg')){
                                                <mat-icon [svgIcon]="pickable.icon.split('_svg')[0]"></mat-icon>
                                            }@else{
                                                <mat-icon class="menu-option-icon">{{pickable.icon}}</mat-icon>
                                            }
                                            <span class="subtle-gradient-text">{{pickable.name}}</span>
                                        </button>
                                        @if(idx != openSecondaryAction?.data.length - 1){
                                            <mat-divider></mat-divider>
                                        }
                                    }
                                }@else if(multipeSelectTrigger.menuOpen && openSecondaryAction?.action?.updateType == UpdateType.FONT_DECORATIONS){
                                    <app-text-decoration></app-text-decoration>
                                }
                            </mat-menu>
                            <!--start color menu-->
                            <mat-menu [hasBackdrop]="false" #colorMenu yPosition="above">
                                @if(colorTrigger.menuOpen){
                                    <color-chrome [color]="(appService.gridSection?.backgroundColor || '') == 'transparent' ? 'white' : (appService.gridSection?.backgroundColor || 'white')"  (onChange)="updatePropWithPickable(pickable, getRGBWithAlphaFromColorChangeEvent($event))" (click)="$event.stopImmediatePropagation()"></color-chrome>
                                }
                            </mat-menu>
                            <div class="menu-trigger" #colorTrigger="matMenuTrigger" [matMenuTriggerFor]="colorMenu"></div>
                            <!--start font menu-->
                            <mat-menu [hasBackdrop]="false" #fontMenu yPosition="above">
                                @if(pickable.action && pickable.action.updateType == UpdateType.FONT_PICKER && fontTrigger.menuOpen){
                                    <app-font-picker></app-font-picker>
                                }
                            </mat-menu>
                            <div class="menu-trigger" #fontTrigger="matMenuTrigger" [matMenuTriggerFor]="fontMenu"></div>

                            <!--start the icon and text-->
                            <mat-icon class="menu-option-icon">
                                {{pickable.icon}}
                            </mat-icon>
                            <span class="menu-option-text-label subtle-gradient-text">{{pickable.name}}</span>
                        </div>
                    }
                }
            }
    </div>
</div>
<div class="bottom-toolbar"
>
    <div [style.opacity]="bottomToolbarCanScrollRight ? 1 : 0" class="secondary-navigation-overlay-right"></div>
    <div [style.opacity]="bottomToolbarCanScrollLeft ? 1 : 0" class="secondary-navigation-overlay-left"></div>
    <div
    (wheel)="onWheel($event)" 
    (mousedown)="onMouseDown($event)" 
    (mousemove)="onMouseMove($event)" 
    (mouseup)="onMouseUp($event)" 
    (mouseleave)="onMouseLeave($event)"
     #bottomToolbar  class="bottom-toolbar-content" [class]="bottomToolbarCanScrollRight || bottomToolbarCanScrollLeft ? 'justify-bottom-toolbar-content-scroll' : 'justify-bottom-toolbar-content-no-scroll'">
        @for (pickable of presetsService.currentNavigation; track $index) {
            @if(!pickable.action || !pickable.action.shouldShowScope || (pickable.action.shouldShowScope && pickable.action.shouldShowCallback && pickable.action.shouldShowCallback.call(pickable.action.shouldShowScope))){
                <!--<div #mainNavigation [class]="pickable.action?.deepFried == true ? 'psychedelic-container' : undefined" [class]="activePrimaryNavgiation == pickable ? 'menu-option-selected' : undefined" class="menu-option pointer" (click)="setActivePrimaryNavgiation(pickable, mainNavigation)">-->
                    <div #mainNavigation 
                    [ngClass]="{
                        'psychedelic-container': pickable.action?.deepFried,
                        'menu-option-selected': activePrimaryNavgiation === pickable && !pickable.action?.deepFried,
                        'pointer': true,
                        'menu-option-deep-fried': true,
                        'menu-option': !pickable.action?.deepFried
                    }" 
                    (click)="setActivePrimaryNavgiation(pickable, mainNavigation)">
                    @if(pickable.icon.includes('image:')){
                        <img #logo [src]="pickable.icon.split('image:')[1]" class="menu-logo-option fade-img" (load)="logo.style.opacity = '1'">
                    }@else{
                        <mat-icon class="menu-option-icon" mat-mini-fab>
                            {{pickable.icon}}
                        </mat-icon>
                    }
                    <span class="menu-option-text-label subtle-gradient-text">{{pickable.name}}</span>
                </div>
            }
        }
    </div>
</div>

@if(appService.gridSection && appService.gridSection.media && appService.gridSection.media.mediaType == MediaType.VIDEO && appService.gridSection.media.loaded){
    <app-scrubber #scrubber (cdkDragReleased)="hasDragged = true" class="fade-in scrubber" cdkDrag
     [style.bottom.px]="hasDragged ? undefined : openSecondaryAction && showSecondary ? 200 : (activePrimaryNavgiation ? 150 : 75)"
    ></app-scrubber>
}