<h1 mat-dialog-title class="dialog-title" >Sign in</h1>
<div mat-dialog-content class="dialog-content">
  <strong class="dialog-description subtle-gradient-text">
    @if(feature && feature != PaidFeature.UPGRADE){
    {{feature.toString()}} is a premium feature and requires login to use.  
    <br><br>
    }
     Choose your preferred method to sign in:
  </strong>
  <div class="social-buttons">
    <button mat-fab extended class="social-button"  (click)="signInWithGoogle()">
      <img src="https://dinbxwvmllmigojxxcce.supabase.co/storage/v1/object/public/media.danktank.cloud/icons/google.png" alt="Google" class="social-png" />
      <span class="button-text">Sign in with Google</span>
    </button>
    <!-- <button mat-fab extended class="social-button"  (click)="signInWithFacebook()">
      <mat-icon class="social-icon">facebook</mat-icon>
      <span class="button-text">Sign in with Facebook</span>
    </button>
    <button mat-fab extended class="social-button"  (click)="signInWithApple()">
      <mat-icon class="social-icon">apple</mat-icon>
      <span class="button-text">Sign in with Apple</span>
    </button> -->
  </div>
</div>