<h2 mat-dialog-title class="dialog-title">
  Upgrade Your Plan
</h2>
<div class="dialog-content">
  <div class="paid-feature subtle-gradient-text">
    <strong>{{paidFeature?.toString()}} requires you to ugrade</strong>
    <div class="spacer"></div>
    <button mat-stroked-button (click)="noThanks()">No thanks</button>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="feature">
        <th mat-header-cell *matHeaderCellDef>Feature</th>
        <td mat-cell *matCellDef="let element" class="default-cell" [class]="element.basic == 'button:basic' ? 'toggle-cell' : null">
          @if(element.basic == 'button:basic') {
            <div class="toggle-container">
    <mat-slide-toggle (change)="updatePrices($event.checked)" [(ngModel)]="yearly"></mat-slide-toggle>
    <span>Yearly (15% discount)</span>
</div>
          } @else {
            <span class="subtle-gradient-text cell-text" >{{ element.feature }}</span>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="free">
        <th mat-header-cell class="centered-header" *matHeaderCellDef>Free Plan</th>
        <td mat-cell *matCellDef="let element" [class]="getCellClass(element.free)" class="button-cell">
          @if(element.free && element.free.length > 0 && !element.free.includes('$') && !element.free.includes('Free')){<mat-icon>{{element.free == 'Yes' ? 'check_circle' : 'cancel'}}</mat-icon>}
        </td>
      </ng-container>

      @if(!needsPro){
        <ng-container matColumnDef="basic">
          <th mat-header-cell class="centered-header" *matHeaderCellDef>Basic Plan</th>
          <td mat-cell *matCellDef="let element" [class]="getCellClass(element.basic)" class="button-cell">
            @if(element.basic == 'button:basic') {
              <button mat-mini-fab (click)="selectPlan('basic')" matTooltip="Subscribe" matTooltipPosition="above">
                <mat-icon>shopping_cart</mat-icon>
              </button>
            } @else {
              @if(element.basic && element.basic.length > 0 && !element.basic.includes('$') && !element.basic.includes('Free')){<mat-icon>{{element.basic == 'Yes' ? 'check_circle' : 'cancel'}}</mat-icon>}
              @else{<span class="subtle-gradient-text cell-text" [class]="element.basic.includes('$') ? 'price-text' : null">{{ element.basic }}</span>}
            }
          </td>
        </ng-container>
      }

      <ng-container matColumnDef="pro">
        <th mat-header-cell class="centered-header" *matHeaderCellDef>Pro Plan</th>
        <td mat-cell *matCellDef="let element" [class]="getCellClass(element.pro)" class="button-cell">
          @if(element.pro == 'button:pro') {
            <button mat-mini-fab  (click)="selectPlan('pro')" matTooltip="Subscribe" matTooltipPosition="above">
              <mat-icon>shopping_cart</mat-icon>
            </button>
          } @else {
            @if(element.pro && element.pro.length > 0 && !element.pro.includes('$') && !element.pro.includes('Free')){<mat-icon>{{element.pro == 'Yes' ? 'check_circle' : 'cancel'}}</mat-icon>}
            @else{<span class="subtle-gradient-text cell-text" [class]="element.pro.includes('$') ? 'price-text' : null">{{ element.pro }}</span>}
          }
        </td>
      </ng-container>

      <!-- Table rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>