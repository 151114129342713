import { ApplicationRef, ChangeDetectorRef, Component, DestroyRef, Inject, KeyValueChangeRecord, NgZone, PLATFORM_ID } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DankTankAuthService, SubscriptionUser } from '../../service/dank-tank-auth.service';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { MultiUserData, UserProfileComponent } from '../user-profile/user-profile.component';
import { User } from '@supabase/supabase-js';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastService } from '../../service/toast.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { first } from 'rxjs';
import { PaidFeature, PaymentService } from '../../service/payment.service';
import { Action, AnalyticsService, AppScreen, ScreenAction } from '../../service/analytics.service';

@Component({
  selector: 'app-logged-in-user',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, CommonModule, MatDividerModule, MatIconModule, MatProgressSpinnerModule, MatTooltipModule],
  templateUrl: './logged-in-user.component.html',
  styleUrl: './logged-in-user.component.scss'
})
export class LoggedInUserComponent {

  public isReady: boolean = false

  constructor(private analytics : AnalyticsService, cdr : ChangeDetectorRef, appRef: ApplicationRef, @Inject(PLATFORM_ID) private platformId: Object, public authService: DankTankAuthService, public paymentService : PaymentService,  private dialog: MatDialog, private destroyRef: DestroyRef, private toast: ToastService) {
    if (isPlatformBrowser(platformId)) {
      this.authService.stableSubject.subscribe(() => {
          this.authService.userSubject.asObservable().pipe(takeUntilDestroyed(destroyRef)).subscribe((user: User | null) => {
            setTimeout(() => {
              this.isReady = true
              cdr.detectChanges()
            }, 2000);
            if (user && user.user_metadata) {
              setTimeout(() => {
                //if(this.dialog.openDialogs.length == 0){
                  toast.show([{ message: "View your profile or logout using the user menu on the top right", action: "Got it!" }])
                //}
              }, 0);
            }
          })
        })
    }
  }

  openProfile(user: User, subscriptionUser: SubscriptionUser | null | undefined) {
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.MODAL_OPENED, info : "User Profile"}
    this.analytics.track(action)
    const multiUser: MultiUserData = { user, subscriptionUser }
    this.dialog.open(UserProfileComponent, { data: multiUser, width: '90vw', maxWidth: '600px' })
  }

  public login() {
    if(!this.isReady)return
    this.authService.authThen(this.callback, this, [], PaidFeature.UPGRADE)
  }

  //auth needs a callback
  private callback() {
    if(this.authService.userSubject.value)
      this.openProfile(this.authService.userSubject.value, this.authService.subscriptionUserSubject.value?.data)
  }
}
