import { CommonModule } from '@angular/common'; 
import { AfterViewChecked, AfterViewInit, Component, DestroyRef, ElementRef, HostListener, ViewChild } from '@angular/core';
import { PresetsService } from '../../service/presets.service';
import { MatIconModule } from '@angular/material/icon';
import { DisplayType, Pickable, UpdateDestination, UpdateType } from '../../vo/Pickers';
import { filter, fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppService } from '../../service/app.service';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { ColorChromeModule } from 'ngx-color/chrome';
import { ColorEvent, toState } from 'ngx-color';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { HistoryRecordType, HistoryService } from '../../service/history.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ToolbarService } from '../../service/toolbar.service';
import { FontPickerComponent } from '../font-picker/font-picker.component';
import { ScrubberComponent } from '../scrubber/scrubber.component';
import { AssetType, GridSection, MediaType } from '../../vo/GridSection';
import { DragDrop, DragDropModule, DragRef } from '@angular/cdk/drag-drop';
import { TextDecorationComponent } from '../text-decoration/text-decoration.component';
import { MatDialog } from '@angular/material/dialog';
import { Action, AnalyticsService, AppScreen, ScreenAction } from '../../service/analytics.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontService } from '../../service/font.service';

@Component({
  selector: 'app-bottom-toolbar',
  standalone: true,
  imports: [MatFormFieldModule, CommonModule, TextDecorationComponent, DragDropModule, ScrubberComponent, FontPickerComponent, MatButtonToggleModule, MatIconModule, MatMenuModule, ColorChromeModule, MatSliderModule, FormsModule, MatDividerModule],
  templateUrl: './bottom-toolbar.component.html',
  styleUrl: './bottom-toolbar.component.scss'
})
export class BottomToolbarComponent implements AfterViewInit {

  public readonly MediaType = MediaType
  public readonly AssetType = AssetType

  public readonly UpdateType = UpdateType
  public readonly DisplayType = DisplayType
  @ViewChild('secondaryNavigation', { read: ElementRef }) secondaryNavigation?: ElementRef
  @ViewChild('bottomToolbar', { read: ElementRef }) bottomToolbar?: ElementRef
  public hasDragged : boolean = false

  constructor(private fontService : FontService, private analyticsService : AnalyticsService, private matDialog : MatDialog, private dragDrop : DragDrop, private toolbarService : ToolbarService, private historyService : HistoryService, public appService : AppService, public presetsService : PresetsService, private elRef : ElementRef, private destroyRef : DestroyRef){
    this.presetsService.currentNavigationSubject.asObservable().pipe(takeUntilDestroyed(destroyRef)).subscribe((value : Pickable[]) => {
      if(value && value.length > 0){
        this.openSecondaryAction = undefined
        this.setActivePrimaryNavgiation(undefined)
        if(this.activeTrigger)this.activeTrigger.closeMenu()
          if(!this.presetsService.minified)value.forEach((pickable : Pickable) => {
            if(pickable.isDefault){
              setTimeout(() => {
                this.setActivePrimaryNavgiation(pickable, undefined, false)
              }, 100);
            }
          })
        }else{
          this.setActivePrimaryNavgiation(undefined)
        }
    })
  }

  ngAfterViewInit(): void {    
    fromEvent<MouseEvent>(this.elRef.nativeElement.parentElement.parentElement.parentElement, 'click').pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((e : MouseEvent) => {
      const target : HTMLElement = e.target as HTMLElement
      const me = this.elRef.nativeElement as HTMLElement;
      if(target){
        if(!me.contains(target)){
          if(this.matDialog.openDialogs.length == 0)this.setActivePrimaryNavgiation(undefined)
        }else{
          if(this.activeTrigger)this.activeTrigger.closeMenu()
        }
      }
      fromEvent<KeyboardEvent>(document, 'keydown').pipe(takeUntilDestroyed(this.destroyRef), filter(e => e.key === 'Escape')).subscribe(() => this.setActivePrimaryNavgiation(undefined));
    });



    
  }

  private _activePrimaryNavgiation ?: Pickable
  public setActivePrimaryNavgiation(val : Pickable | undefined, div ?: HTMLDivElement, setToUndefined : boolean = true){
      div?.scrollIntoView({ behavior: 'smooth', inline: 'center' })
      this.openSecondaryAction = undefined
      const old = this._activePrimaryNavgiation
      
      setTimeout(() => {
        if(val == old){
          if(setToUndefined)this._activePrimaryNavgiation = undefined
        }else{
          if(val?.action && val.action.callback && !val.data){
            val.action.callback.call(val.action.scope, val.data)
          }else{
            this._activePrimaryNavgiation = val
          }
        }
      }, this._activePrimaryNavgiation != undefined ? 0 : 0);
      if(setToUndefined)this._activePrimaryNavgiation = undefined
  }

  public get showSecondary():boolean{
    const toReturn = this.openSecondaryAction != undefined && (this.openSecondaryAction.action?.updateType == UpdateType.SLIDER || this.openSecondaryAction.action?.updateType == UpdateType.BUTTON_GROUP || this.openSecondaryAction.action?.updateType == UpdateType.HORIZONTAL_LIST)
    return toReturn
  }

  public openSecondaryAction ?: Pickable
  private activeTrigger ?: MatMenuTrigger
  public secondaryNavigationClicked(pickable : Pickable, colorTrigger?:MatMenuTrigger, multipleChoiceTrigger?:MatMenuTrigger, fontTrigger?:MatMenuTrigger, div ?: HTMLDivElement){
    div?.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    //if there is an action, but no data, we'll assume that they want the value of a change sent back to the callback
    if(pickable?.action){
      if(pickable.action.callback && pickable.data){
        if(pickable.data){
          pickable.action.callback.call(pickable.action.scope, pickable.data)
        }else{
          //pickable.action.callback.call(pickable.action.scope, undefined)
        }
        setTimeout(() => {
          this.setActivePrimaryNavgiation(undefined)
        }, 100);
      }else if(pickable.action.updateType){
        if(this.activeTrigger)this.activeTrigger.closeMenu()
          //this.openSecondaryAction = undefined
        this.openSecondaryAction = pickable
        switch(pickable.action.updateType){
          case UpdateType.COLORS:
            this.activeTrigger = colorTrigger
            colorTrigger?.openMenu()
            break
          case UpdateType.SLIDER:
          case UpdateType.HORIZONTAL_LIST:
            console.log()
            break
          case UpdateType.MENU:
          case UpdateType.FONT_DECORATIONS:
            this.activeTrigger = multipleChoiceTrigger
            multipleChoiceTrigger?.openMenu()
            break
          case UpdateType.FONT_PICKER:
            this.activeTrigger = fontTrigger
            fontTrigger?.openMenu()
            break
        }
      }
    }else{
      throw new Error('You forgot to config an action for your pickable')
    }
  }

  getPropByPickable(pickable ?: Pickable): any {
    if (!pickable)return null
    // Access the property based on the pickable object
    switch (pickable.action?.updateDestination) {
      case UpdateDestination.GRID:
        const gridReturn = pickable.action.updateProp ? (this.appService.gridSection as any)[pickable.action.updateProp] : null
        return gridReturn
      case UpdateDestination.ASSET:
        const toReturn = pickable.action.updateProp ? (this.appService.gridSection?.activeAsset?.data as any)[pickable.action.updateProp] : null
        return toReturn
      case UpdateDestination.MEDIA:
        const gs : GridSection | undefined = this.appService.gridSection ? this.findMediaRecursively(this.appService.gridSection) : undefined
        return pickable.action.updateProp && gs ? (gs?.media as any)[pickable.action.updateProp] : null
    }
  }

  findMediaRecursively(section: GridSection): GridSection | undefined {
    // Check if the current section has a defined media
    if (section.media) {
      return section;
    }
  
    // If children exist, iterate through them recursively
    if (section.children && section.children.length > 0) {
      for (let child of section.children) {
        const result = this.findMediaRecursively(child);
        if (result) {
          return result;  // Return as soon as a defined media is found
        }
      }
    }
  
    // Return null if no defined media is found
    return undefined;
  }

  public updatePropWithPickable(pickable ?: Pickable, val ?: any){
    if(!pickable || !val)return
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.SET_PROPERTY, info : pickable.action?.updateProp}
    this.analyticsService.track(action)
    
    switch(pickable.action?.updateDestination){
      case UpdateDestination.ASSET:
        if(pickable.action.updateProp && !pickable.action.callback && !pickable.action.scope){
          //this is a hack fix it later
          if(pickable.action.updateProp == 'fontFamily'){
            if(this.appService.gridSection?.activeAsset?.data){
              this.appService.gridSection.activeAsset.data.fontFamily = val
              this.fontService.addUsedFont({name : val})
            }
          }
          if(this.appService.gridSection?.activeAsset)if (pickable.action.updateProp == 'textStrokeColor' && (this.appService.gridSection?.activeAsset.data.textStrokeWidth == 0 || this.appService.gridSection.activeAsset.data.textStrokeWidth == undefined)) this.appService.gridSection.activeAsset.data.textStrokeWidth = 1
          if(this.appService.gridSection?.activeAsset)if (pickable.action.updateProp == 'borderColor' && (this.appService.gridSection?.activeAsset.data.borderWidth == 0 || this.appService.gridSection?.activeAsset.data.borderWidth == undefined)) this.appService.gridSection.activeAsset.data.borderWidth = 1
          const toSet = pickable.action.updateType === UpdateType.SLIDER ? Number(val) : val;
          if(this.appService.gridSection?.activeAsset?.data){
            (this.appService.gridSection?.activeAsset.data as any)[pickable.action.updateProp] = toSet
          }
          this.toolbarService.assetPropertyUpdatedSubject.next({ prop: pickable.action?.updateProp, value: toSet })
        }else if(pickable.action.callback && pickable.action.scope){
          pickable.action.callback.call(pickable.action.scope, val)
        }
        break
      case UpdateDestination.GRID:
        if(pickable.action.updateProp && !pickable.action.callback && !pickable.action.scope){
          const toSet = pickable.action.updateType == UpdateType.SLIDER ?  Number(val) : val;
          (this.appService.gridSection as any)[pickable.action.updateProp] = toSet
        }else if(pickable.action.callback && pickable.action.scope){
          pickable.action.callback.call(pickable.action.scope, val)
        }
        break
      case UpdateDestination.MEDIA:
        if(pickable.action.updateProp && !pickable.action.callback && !pickable.action.scope){
          //const toSet = pickable.action.updateType == UpdateType.SLIDER ?  Number(val) : val;
          //(this.appService.gridSection?.media as any)[pickable.action.updateProp] = toSet
          if(this.appService.gridSection)this.updateRecursively(val, pickable, this.appService.gridSection)
        }else if(pickable.action.callback && pickable.action.scope){
          pickable.action.callback.call(pickable.action.scope, val)
        }
        break
      default:
        if(pickable.action?.callback && pickable.action.scope){
          pickable.action.callback.call(pickable.action.scope, val)
        }else{
          throw new Error("You forgot to set an update destination, you also have no scope or callback defined")
        }
    }
    this.historyService.createHistoryEntry(HistoryRecordType.ASSET_PROPERTY, pickable.action?.updateProp)
  }

  updateRecursively(val: any, pickable: any, section: GridSection): void {
    // Apply the update to the current section
    const toSet = pickable.action.updateType === UpdateType.SLIDER ? Number(val) : val;
    if(section.media)(section.media as any)[pickable.action.updateProp] = toSet;
  
    // Recursively apply the update to all children
    if (section.children && section.children.length > 0) {
      section.children.forEach((child: any) => this.updateRecursively(val, pickable, child));
    }
  }

  public getRGBWithAlphaFromColorChangeEvent(event: ColorEvent) {
    let hexValue = event.color.hex || '';
    
    if (hexValue.length === 7) {
      const alphaHex = Math.round(event.color.rgb.a * 255).toString(16).padStart(2, '0');
      hexValue += alphaHex;
    }
    
    return hexValue;
  }

  public get activePrimaryNavgiation() : Pickable | undefined{
    return this._activePrimaryNavgiation
  }

  public get secondaryNavigationCanScrollLeft(): boolean {
    if (!this.secondaryNavigation) return false;

    const element = this.secondaryNavigation.nativeElement as HTMLElement;
    return element.scrollLeft > 0;
  }

  public get secondaryNavigationCanScrollRight(): boolean {
    if (!this.secondaryNavigation) return false;

    const element = this.secondaryNavigation.nativeElement as HTMLElement;
    return element.scrollWidth > element.clientWidth + element.scrollLeft;
  }

  public get bottomToolbarCanScrollLeft(): boolean {
    if (!this.bottomToolbar) return false;

    const element = this.bottomToolbar.nativeElement as HTMLElement;
    return element.scrollLeft > 0;
  }

  public get bottomToolbarCanScrollRight(): boolean {
    if (!this.bottomToolbar) return false;

    const element = this.bottomToolbar.nativeElement as HTMLElement;
    return element.scrollWidth > element.clientWidth + element.scrollLeft;
  }




  public deleteActiveAsset(){
    this.presetsService.delete()
  }

  public unsetActiveAsset(){
    this.presetsService.back()
  }





  //////scrolling

private isDragging = false;
private startX = 0;
private scrollLeft = 0;
private lastX = 0;
private velocity = 0;
private momentumFrame: number | null = null;


  onWheel(event: WheelEvent): void {
    event.preventDefault();
    const target = event.currentTarget as HTMLElement; 
    const delta : number = Math.abs(event.deltaY) > 0 ? event.deltaY : event.deltaX
    target.scrollLeft += delta;
    //console.log(target, "Delta", delta, "Scroll Left", target.scrollLeft)
  }


onMouseDown(event: MouseEvent): void {
  this.isDragging = true;
  const target = event.currentTarget as HTMLElement; 
  this.startX = event.pageX - target.offsetLeft;
  this.scrollLeft = target.scrollLeft;
  this.lastX = event.pageX;
  target.style.cursor = 'grabbing';
  target.classList.add('no-select');
}

onMouseMove(event: MouseEvent): void {
  if (!this.isDragging) return;
  const target = event.currentTarget as HTMLElement; 
  const x = event.pageX - target.offsetLeft;
  const walk = (x - this.startX) * 2;
  target.scrollLeft = this.scrollLeft - walk;

  // Calculate velocity (difference in X position over time)
  this.velocity = event.pageX - this.lastX;
  this.lastX = event.pageX;
}

onMouseUp(event: MouseEvent): void {
  this.isDragging = false;
  const target = event.currentTarget as HTMLElement; 
  target.style.cursor = 'grab';
  target.classList.remove('no-select');

  // Apply momentum
  this.applyMomentum(target);
}

onMouseLeave(event: MouseEvent): void {
  this.isDragging = false;
  const target = event.currentTarget as HTMLElement; 
  target.style.cursor = 'grab';
  target.classList.remove('no-select');

  // Apply momentum if the user leaves while dragging
  this.applyMomentum(target);
}

private applyMomentum(target: HTMLElement): void {
  if (this.momentumFrame) {
    cancelAnimationFrame(this.momentumFrame);
}

  const decay = 0.95; // The decay rate for the momentum effect
  const momentum = () => {
    if (Math.abs(this.velocity) > 0.1) {
      target.scrollLeft -= this.velocity;
      this.velocity *= decay; // Reduce the velocity gradually
      this.momentumFrame = requestAnimationFrame(momentum);
    }
  };

  momentum();
}

  public get secondaryNavigationTop(): number {
    if (this.presetsService.minified) {
        if (this.openSecondaryAction && this.showSecondary) {
          if(this.appService.gridSection?.activeAsset){
            return -80;
          }else{
            return -50;
          }
        } else {
          if(this.appService.gridSection?.activeAsset){
            return -30;
          }else{
            return 0;
          }
        }
    } else {
        if (this.openSecondaryAction && this.showSecondary) {
          if(this.appService.gridSection?.activeAsset){
            return -155;
          }else{
            return -125;
          }
        } else if (this.activePrimaryNavgiation) {
          if(this.appService.gridSection?.activeAsset){
            return -105;
          }else{
            return -75;
          }
        } else {
          const diff : number = this.activePrimaryNavgiation ? 0 : 0
          if(this.appService.gridSection?.activeAsset){
            return -30 - diff;
          }else{
            return 0 - diff;
          }
        }
    }
}

public get secondaryNavigationHeight() : number {
  //openSecondaryAction && showSecondary ? 125 : (activePrimaryNavgiation ? 75 : 0)
  if(this.openSecondaryAction && this.showSecondary){
    if(this.appService.gridSection?.activeAsset){
      return 155
    }else{
      return 125
    }
  }else{
    if(this.activePrimaryNavgiation){
      if(this.appService.gridSection?.activeAsset){
        return 105
      }else{
        return 75
      }
    }else{
      if(this.appService.gridSection?.activeAsset){
        return 30
      }else{
        return 0
      }
    }
  }
  return 0
}
  
}