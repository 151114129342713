import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, DoCheck, ElementRef, EventEmitter, HostBinding, HostListener, Inject, Input, NgZone, OnInit, Output, PLATFORM_ID, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { GridSection, Orientation, Media, MediaType, Mode, AssetType, Asset } from '../../vo/GridSection'
import { Observable, Subscription, fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MediaMatcher } from '@angular/cdk/layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatMenu, MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import { MatError, MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule } from "@angular/forms";
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from "@angular/material/input";
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { FileUploaderComponent, ImageData, UploadType } from '../file-uploader/file-uploader.component';
import {
  MatBottomSheet,
  MatBottomSheetModule,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MediaAttacherComponent } from '../media-attacher/media-attacher.component';
import { EditMode, EditModeData, PropChange, ToolbarService } from '../../service/toolbar.service';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { ImageCropperComponent, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { AssetComponent } from '../asset/asset.component';
import { TextAssetComponent } from '../asset/text-asset/text-asset.component';
import { StickerAssetComponent } from '../asset/sticker-asset/sticker-asset.component';
import { ImageEditorComponent } from '../image-editor/image-editor.component';
import { StickerBrowserComponent } from '../sticker-browser/sticker-browser.component';
import { TextBubbleAssetComponent } from '../asset/text-bubble-asset/text-bubble-asset.component';
import { DeviceDetectorService } from '../../service/device-detector.service';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { PaidFeature, PaymentService, SubscriptionStatus, SubscriptionType } from '../../service/payment.service';
import { CommonModule } from '@angular/common';
import { ToastService } from '../../service/toast.service';
import { FontService } from '../../service/font.service';
import { Router } from '@angular/router';
import { ScrubberComponent } from '../scrubber/scrubber.component';
import { ScrubberService } from '../../service/scrubber.service';
import { NgxMoveableModule } from 'ngx-moveable';
import { HistoryRecordType, HistoryService } from '../../service/history.service';
import { Action, AnalyticsService, AppScreen, ScreenAction } from '../../service/analytics.service';


export enum Stages {
  CHOOSE_TYPE = 'Choose Image Type',
  UPLOAD_IMAGE = 'Upload Image',
  UPLOAD_GIF = 'Upload GIF',
  UPLOAD_MP4 = 'Upload MP4',
  MEMMES = 'Memes',
  GIFS = 'GIFs',
  STOCK_IMAGES = 'Stock Images',

}

interface RGBColor {
  r: number;
  g: number;
  b: number;
}


@Component({
  selector: 'app-grid-section',
  standalone: true,
  imports: [NgxMoveableModule, ScrubberComponent, MatProgressSpinnerModule, CommonModule, TextBubbleAssetComponent, TextAssetComponent, StickerAssetComponent, AssetComponent, ImageCropperComponent, MatBottomSheetModule, FileUploaderComponent, MatChipsModule, MatIconModule, MatProgressBarModule, MatExpansionModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule, MatProgressSpinnerModule, MatIconModule, MatCardModule, MatMenuModule, MatSelectModule, FormsModule, MatFormFieldModule, MatError, TextFieldModule, MatInputModule, ReactiveFormsModule],
  templateUrl: './grid-section.component.html',
  styleUrl: './grid-section.component.scss'
})
export class GridSectionComponent implements AfterViewInit, OnInit, DoCheck {

  public readonly PaidFeature = PaidFeature

  @HostBinding('class.root-grid') get isRootGrid() {
    return this.data && this.data.isRootGrid;
  }

  @HostBinding('class.not-root-grid') get notRootGrid() {
    return !this.data || (this.data && !this.data.isRootGrid)
  }

  private _data?: GridSection
  @Input() set data(gs: GridSection) {
    if (this.data) this.data.forehead = 0
    this._data = gs
  }

  public get data(): GridSection | undefined {
    return this._data
  }

  readonly SubscriptionType = SubscriptionType
  readonly SubscriptionStatus = SubscriptionStatus
  readonly Orientation = Orientation
  readonly Mode = Mode
  readonly AssetType = AssetType
  readonly MediaType = MediaType

  private mouseMoveObservable?: Subscription

  public mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  public editing = false



  @ViewChildren(GridSectionComponent, { read: ElementRef }) gridSections!: QueryList<GridSectionComponent>;

  @Output() videoReady = new EventEmitter<HTMLVideoElement>()
  @Output() imageReady = new EventEmitter<HTMLImageElement>()
  @ViewChildren('video') videos?: QueryList<ElementRef<HTMLVideoElement>>;
  @ViewChildren('backgroundImg') images?: QueryList<ElementRef<HTMLImageElement>>;
  @ViewChild('imageTrigger') public imageTrigger?: MatMenuTrigger


  public blendMode: string = 'normal'

  constructor(private analytics : AnalyticsService, private historyService : HistoryService, private sheet: MatBottomSheet, private scrubberService: ScrubberService, private fontService: FontService, private toast: ToastService, public authService: DankTankAuthService, public paymentService: PaymentService, @Inject(PLATFORM_ID) private platformId: Object, private zone: NgZone, private cdr: ChangeDetectorRef, public deviceDetector: DeviceDetectorService, private dialog: MatDialog, private sanitizer: DomSanitizer, private toolbarService: ToolbarService, public elRef: ElementRef, private destroyRef: DestroyRef, private changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private bottomSheet: MatBottomSheet, public router: Router) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);


    this.toolbarService.assetPropertyUpdatedSubject.asObservable().pipe(takeUntilDestroyed(destroyRef)).subscribe((d: PropChange) => {
      cdr.detectChanges()
      if (d.prop == 'blendMode') {
        this.blendMode = d.value
      }
    })


  }

  onSelect(e: any) {
    console.log()
  }

  onDragStart(e: any) {
    console.log()
  }

  onSelectStart(e: any) {
    console.log()
  }

  onResize(e: any) {
    if (this.data){
      this.data.forehead = e.height
      this.historyService.createHistoryEntry(HistoryRecordType.SECTION_PROPERTY, 'forehead')
    }
  }

  public resizing: boolean = false
  public resizeStart() {
    this.resizing = true
  }
  resizeEnd() {
    this.resizing = false
  }

  calcValue(percent: number, pixels: number): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`calc(${percent}% + ${pixels}px)`);
  }


  ngOnInit(): void {
    this.toolbarService.editModeSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (data: EditModeData) => {
        this.editing = data.mode == EditMode.IMAGE && data.editing == this.data
      }
    })
  }

  private mediaValue?: string
  public loading: boolean = false
  ngDoCheck(): void {
    const mv = this.data?.media?.mediaLocation
    if (mv != this.mediaValue) {
      if (mv && mv.length > 0) {
        this.loading = true
        this.iconColor = "black"
      } else {
        this.loading = false
      }
    }
    this.mediaValue = mv
  }

  ngAfterViewInit(): void {
    if (this.videos && this.videos.length > 0) {
      // Emit the first video element if the list is already populated
      this.videoReady.emit(this.videos.first.nativeElement)
    }

    if (this.images && this.images.length > 0) {
      // Emit the first image element if the list is already populated
      this.imageReady.emit(this.images.first.nativeElement);
    }

    // Subscribe to future changes
    const changes: Observable<any> | undefined = this.videos?.changes
    changes?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(changes => {
      const currentVideos: ElementRef[] = changes.toArray();
      if (currentVideos.length > 0) {
        this.videoReady.emit(currentVideos[0].nativeElement);
      }
    })

    const imgChanges: Observable<any> | undefined = this.images?.changes
    imgChanges?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(changes => {
      const currentImages: ElementRef[] = changes.toArray();
      if (currentImages.length > 0) {
        this.imageReady.emit(currentImages[0].nativeElement);
      }
    })
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          if (!this.mobileQuery.matches) fromEvent<MouseEvent>(document, 'mouseup').pipe(takeUntilDestroyed(this.destroyRef)).subscribe(e => {
            this.stopDrag()
          })
        });
      })
      this.zone.runOutsideAngular(() => {
        setInterval(() => {
          this.zone.run(() => {
            //if (this.data) this.data.widthInPX = this.elRef.nativeElement.offsetWidth
            //if (this.data) this.data.heightInPX = this.elRef.nativeElement.offsetHeight
            if (this.data) {
              let element: any
              if (this.videos && this.videos.first) {
                element = this.videos.first.nativeElement
              }
              if (this.images && this.images.first) {
                element = this.images.first.nativeElement
              }
              const toSetWidth = element ? element.offsetWidth : 0
              const toSetHeight = element ? element.offsetHeight : 0
              this.data.widthInPX = toSetWidth
              this.data.heightInPX = toSetHeight
            }
          })
        }, 200);
      });
    }
  }

  private getEventType(): 'touchmove' | 'mousemove' {
    const test = this.deviceDetector.isTouchOnlyDevice()
    return this.deviceDetector.isTouchOnlyDevice() ? 'touchmove' : 'mousemove';
  }


  public startDrag(idx: number, event: MouseEvent | TouchEvent) {
    if (this.data?.mode == Mode.VIEWING) return
    if (event.type != 'mousedown') event.preventDefault()
    const originalHeightPercents: Array<number> = [];
    const originalWidthPercents: Array<number> = [];
    this.gridSections.forEach((gridSection: any) => {
      const parentRect = gridSection.nativeElement.parentElement.getBoundingClientRect()
      const rect = gridSection.nativeElement.getBoundingClientRect()
      originalHeightPercents.push((rect.height / parentRect.height) * 100)
      originalWidthPercents.push((rect.width / parentRect.width) * 100)
    })
    const eventType: any = this.mobileQuery.matches ? TouchEvent : MouseEvent
    this.mouseMoveObservable = fromEvent<typeof eventType>(this.elRef.nativeElement, this.getEventType()).subscribe((e: TouchEvent | MouseEvent) => {
      const toResize: any | undefined = this.gridSections.get(idx)
      const evt = e instanceof MouseEvent ? e : e.touches[0]
      if (toResize) {
        switch (this.data?.orientation) {
          case Orientation.VERTICAL:

            const childRect = toResize.nativeElement.getBoundingClientRect();
            const parentRect = toResize.nativeElement.parentElement.getBoundingClientRect()
            const top: number = childRect.top - parentRect.top
            const totalHeight: number = this.elRef.nativeElement.offsetHeight
            const mouseY: number = evt.clientY - parentRect.top
            let percent: number = ((mouseY - (top)) / totalHeight) * 100
            percent = percent >= 10 ? percent : 10
            percent = percent < 100 - ((originalHeightPercents.length) * 10) ? percent : 100 - ((originalHeightPercents.length) * 10)
            this.data?.children.forEach((child: GridSection, index: number) => {
              if (index != idx) {
                //console.log(percent)
                if (percent > originalHeightPercents[idx]) {
                  const nextPercent = originalHeightPercents[index] - (percent - originalHeightPercents[idx])
                  if (index == idx + 1) {
                    child.height = nextPercent
                  } else {
                    child.height = originalHeightPercents[index]
                  }
                } else {
                  //const prevOrNextPercent = idx == 0 ? originalHeightPercents[index] + (originalHeightPercents[idx] - percent) : originalHeightPercents[index] + (originalHeightPercents[idx] - percent)
                  const prevOrNextPercent = originalHeightPercents[index] + (originalHeightPercents[idx] - percent)
                  const prevOrNext: number = idx + 1
                  if (index == prevOrNext) {
                    if(index == 2){
                      //console.log(prevOrNextPercent)
                    }
                    child.height = prevOrNextPercent
                  } else {
                    child.height = originalHeightPercents[index]
                  }
                }
              } else {
                //console.log("PERCENT", percent)
                child.height = percent;
              }
            })


            break;
          case Orientation.HORIZONTAL:
            const childRectH = toResize.nativeElement.getBoundingClientRect();
            const parentRectH = toResize.nativeElement.parentElement.getBoundingClientRect()
            const leftH: number = childRectH.left - parentRectH.left
            const totalWidthH: number = this.elRef.nativeElement.offsetWidth
            const mouseXH: number = evt.clientX - parentRectH.left
            let percentH: number = ((mouseXH - (leftH)) / totalWidthH) * 100
            percentH = percentH >= 10 ? percentH : 10
            percentH = percentH < 100 - ((originalWidthPercents.length) * 10) ? percentH : 100 - ((originalWidthPercents.length) * 10)
            this.data?.children.forEach((child: GridSection, index: number) => {
              if (index != idx) {
                //console.log(percent)
                if (percentH > originalWidthPercents[idx]) {
                  const nextPercent = originalWidthPercents[index] - (percentH - originalWidthPercents[idx])
                  if (index == idx + 1) {
                    child.width = nextPercent
                  } else {
                    child.width = originalWidthPercents[index]
                  }
                } else {
                  //const prevOrNextPercent = idx == 0 ? originalWidthPercents[index] + (originalWidthPercents[idx] - percentH) : originalWidthPercents[index] - (originalWidthPercents[idx] - percentH)
                  const prevOrNextPercent = originalWidthPercents[index] + (originalWidthPercents[idx] - percentH)
                  const prevOrNext: number = idx + 1
                  if (index == prevOrNext) {
                    child.width = prevOrNextPercent
                  } else {
                    child.width = originalWidthPercents[index]
                  }
                }
              } else {
                child.width = percentH;
              }
            })
            break
        }

      }
    })
  }

  public stopDrag() {
    this.mouseMoveObservable?.unsubscribe()

  }



  public get Stages() { return Stages }
  private _currentStage?: Stages;
  public breadcrumbs: Array<Stages> = [];

  public get currentStage(): Stages | undefined {
    return this._currentStage;
  }

  public set currentStage(s: Stages | undefined) {
    this._currentStage = s;
  }

  public next(nextStage?: Stages) {
    if (this.currentStage && this.currentStage != nextStage && !this.breadcrumbs.includes(this.currentStage) && !this.breadcrumbs.includes(nextStage || this.currentStage)) this.breadcrumbs.push(this.currentStage);
    switch (this.currentStage) {
      case Stages.CHOOSE_TYPE:
        if (!nextStage) throw new Error('Must send next stage in this case')
        this.currentStage = nextStage || this._currentStage;
        break;
      default:
        this.currentStage = Stages.CHOOSE_TYPE;
        break;
    }
    if (!this.breadcrumbs.includes(this.currentStage)) this.breadcrumbs.push(this.currentStage);
    console.log()
  }

  public back(s: Stages, event: MouseEvent) {
    event.stopPropagation()
    if (this.currentStage == s) return;
    const idx = this.breadcrumbs.indexOf(s);
    this.breadcrumbs.length = idx + 1;
    this.currentStage = s;

  }

  public reset() {
    this.breadcrumbs = []
    this.currentStage = undefined
  }


  //////////////////////////////ACTION STUFF
  public deleteBackgroundAndAssets() {
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.CLICK, info : "Delete background and assets"}
    this.analytics.track(action)
    if (this.data) {
      this.data.name = undefined
      this.data.media = undefined
      this.data.assets = []
      this.data.activeAsset = undefined
      this.fontService.clearUsedFonts()
    }
    //this.action(Stages.MEMMES)
  }

  public deleteBackground() {
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.CLICK, info : "Delete background"}
    this.analytics.track(action)
    //this.router.navigate(['generator', 'meme', 'deleteBackground'])
    if (this.data) this.data.media = undefined;
  }

  public setGifsAction() {
    this.action(Stages.GIFS)
  }

  setUploadMP4Action() {
    this.action(Stages.UPLOAD_MP4)
  }

  @ViewChild(MatMenuTrigger) trigger !: MatMenuTrigger;
  action(stage: Stages) {
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.CLICK, info : `Grid Menu Item Clicked ${stage.toString()}`}
    this.analytics.track(action)
    const mw = this.deviceDetector.isPortrait() ? '95vw' : '500px'
    switch (stage) {
      case Stages.UPLOAD_IMAGE:
        this.bottomSheet.open(MediaAttacherComponent, { data: { uploadType: UploadType.IMAGE, section: this.data } })
        if (this.trigger) this.trigger.closeMenu()
        this.reset();
        break
      case Stages.UPLOAD_GIF:
        this.bottomSheet.open(MediaAttacherComponent, { data: { uploadType: UploadType.GIF, section: this.data } })
        if (this.trigger) this.trigger.closeMenu()
        this.reset();
        break
      case Stages.UPLOAD_MP4:
        this.bottomSheet.open(MediaAttacherComponent, { data: { uploadType: UploadType.VIDEO, section: this.data } })
        if (this.trigger) this.trigger.closeMenu()
        this.reset();
        break
      case Stages.MEMMES:
      case Stages.GIFS:
        this.toast.show([{ message: "Choose your favorite meme content, then add assets to make it come alive!", action: "Got it!" }])
        const m: Media = {loaded : false, blur: 0, sepia: 0, grey: 0, hue: 0, saturation: 1, brightness: 1, mediaType: stage == Stages.GIFS ? MediaType.VIDEO : MediaType.LOCAL_IMAGE, mediaLocation: '', originalLocation: '' }
        const asset: Asset = { type: stage == Stages.GIFS ? AssetType.GIF : AssetType.MEME, data: { x: 0, y: 0, visible: true, editing: false }, convertToMedia: m, section: this.data }
        if (this.data) this.data.media = m
        this.dialog.open(StickerBrowserComponent, {
          data: asset,
          panelClass: 'panel-class',
          minHeight: this?.data?.rootGridSectionHeight + 'px',
          minWidth: mw,
          maxHeight: '80vh'
        })
        if (this.trigger) this.trigger.closeMenu()
        this.reset();
        break
    }
    this.reset()
  }









  /////////////////////////UPLOADER STUFF
  imageChosen(img: ImageData) {
    if (this.trigger) this.trigger.closeMenu()
    //if(this.data)this.data.media = {mediaType : MediaType.LOCAL_IMAGE, mediaLocation : img.dataURL || ''}
    this.reset()
  }


  startEditing() {
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.CLICK, info : "Size and Color"}
    this.analytics.track(action)
    const h = (this?.data?.rootGridSectionWidth || 0) > (this?.data?.rootGridSectionHeight || 0) ? this?.data?.rootGridSectionHeight + 'px' : '75%'
    this.dialog.open(ImageEditorComponent, { data: this.data?.media })
  }

  startOver() {
    this.action(Stages.MEMMES)
  }



  //////CONTINUATION OF AWFUL HACK

  public snapshotMode: boolean = false
  public snapshotImage?: string
  public setSnapshotMode(imageURI: string) {
    this.snapshotMode = true
    this.snapshotImage = imageURI
  }

  public stopSnapshotMode() {
    this.snapshotMode = false
    this.snapshotImage = undefined
  }







  /////////////IMAGE STUFF

  public iconColor: string = 'black';

  public bgImageLoaded(): void {
    if (this.snapshotImage) return
    setTimeout(() => {
      if (this.images && this.images.first) {
        const img = this.images.first.nativeElement as HTMLImageElement;

        // Ensure the crossOrigin attribute is set
        img.crossOrigin = 'Anonymous';

        if (!img.complete || img.naturalWidth === 0) {
          console.error('Image not fully loaded or has zero width:', img);
          return;
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        if (this.data?.media?.sticker?.template) {
          this.data.media.sticker.template.width = img.naturalWidth
          this.data.media.sticker.template.height = img.naturalHeight
        }else{
          if(this.data?.media){
            if(this.data.media.sticker){
              this.data.media.sticker.template = {width : img.naturalWidth, height : img.naturalHeight, forehead : 0, aspectRatio : undefined}
            }else{
              this.data.media.sticker = { name: '', storageReference : '', type : 'meme', template : {width : img.naturalWidth, height : img.naturalHeight, forehead : 0, aspectRatio : undefined}}
            }
          }
        }
        if(this.data?.media)this.data.media.loaded = true
        this.loading = false


        try {
          ctx.drawImage(img, 0, 0);

          const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
          const primaryColor = this.getPrimaryColor(imgData);
          const oppositeColor = this.getPolarOppositeColor(primaryColor);
          //this.iconColor = this.rgbToCssColor(oppositeColor);
          this.iconColor = this.rgbToHex(oppositeColor)
          console.log()
        } catch (error) {
          console.error('Error drawing image on canvas:', error);
        }
      }
    }, 1);
  }

  public bgMediaLoaded(): void {
    setTimeout(() => {
      if (this.videos && this.videos.first) {
        const mediaElement = this?.videos?.first.nativeElement as HTMLVideoElement;

        if (this.data) this.scrubberService.setActiveVideo(mediaElement, this.data)
        //this.bottomSheet.open(ScrubberComponent, {hasBackdrop : false})


        mediaElement.crossOrigin = 'Anonymous';

        if (mediaElement.readyState < 2) { // `2` indicates HAVE_CURRENT_DATA
          console.error('Media not fully loaded:', mediaElement);
          return;
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        canvas.width = mediaElement.videoWidth;
        canvas.height = mediaElement.videoHeight;

        if (this.data?.media?.sticker?.template) {
          this.data.media.sticker.template.width = mediaElement.videoWidth
          this.data.media.sticker.template.height = mediaElement.videoHeight
        }else{
          if(this.data?.media){
            if(this.data.media.sticker){
              this.data.media.sticker.template = {width : mediaElement.videoWidth, height : mediaElement.videoHeight, forehead : 0, aspectRatio : undefined}
            }else{
              this.data.media.sticker = { name: '', storageReference : '', type : 'meme', template : {width : mediaElement.videoWidth, height : mediaElement.videoHeight, forehead : 0, aspectRatio : undefined}}
            }
          }
        }
        if(this.data?.media)this.data.media.loaded = true
        this.loading = false

        try {
          ctx.drawImage(mediaElement, 0, 0, canvas.width, canvas.height);
          const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
          const primaryColor = this.getPrimaryColor(imgData);
          const oppositeColor = this.getPolarOppositeColor(primaryColor);
          this.iconColor = this.rgbToHex(oppositeColor);
          console.log();
        } catch (error) {
          console.error('Error drawing video frame on canvas:', error);
        }
      }
    }, 10);
  }

  private getPrimaryColor(imgData: Uint8ClampedArray): RGBColor {
    const colorCount: { [key: string]: number } = {};
    let maxCount = 0;
    let primaryColor: RGBColor = { r: 0, g: 0, b: 0 };

    for (let i = 0; i < imgData.length; i += 4) {
      const r = imgData[i];
      const g = imgData[i + 1];
      const b = imgData[i + 2];
      const colorKey = `${r},${g},${b}`;

      colorCount[colorKey] = (colorCount[colorKey] || 0) + 1;

      if (colorCount[colorKey] > maxCount) {
        maxCount = colorCount[colorKey];
        primaryColor = { r, g, b };
      }
    }

    return primaryColor;
  }

  private getPolarOppositeColor(color: RGBColor): RGBColor {
    return {
      r: 255 - color.r,
      g: 255 - color.g,
      b: 255 - color.b
    };
  }

  private rgbToHex(color: RGBColor): string {
    const toHex = (component: number) => component.toString(16).padStart(2, '0')
    return `#${toHex(color.r)}${toHex(color.g)}${toHex(color.b)}`
  }

}
