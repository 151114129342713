@if(paymentService.isPremium == false){
    @if(appService.gridSection){
        <div class="ad-holder" (click)="resetAsset()"
         [ngStyle]="{
            height: (!appService.gridSection || paymentService.isPremium) ? '0px' : 
                (adSize | async) === AdSize.SMALL ? '70px' :
                (adSize | async) === AdSize.MEDIUM ? '80px' :
                (adSize | async) === AdSize.LARGE ? '110px' : 'auto'
            }">
            @if((adSize | async) == AdSize.SMALL){
                <app-ad-embed
                    [key]="smallAdKey"
                    [height]="50"
                    [width]="320">
                </app-ad-embed>
            }
            @if((adSize | async) == AdSize.MEDIUM){
                <app-ad-embed
                    [key]="mediumAdKey"
                    [height]="60"
                    [width]="468">
                </app-ad-embed>
            }
            @if((adSize | async) == AdSize.LARGE){
                <app-ad-embed
                    [key]="largeAdKey"
                    [height]="90"
                    [width]="728">
                </app-ad-embed>
            }
        </div>
    }
}