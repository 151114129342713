import { AfterViewInit, Component, DestroyRef, ElementRef, Inject, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { fromEvent, take } from 'rxjs';
import { SEODataResponse, SEOService } from './service/seo.service';
import { MetaService } from './service/meta.service';
import { StickerService } from './service/sticker.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { environment } from '../environments/environment';
import { FontService } from './service/font.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContextMenuAction, ContextMenuService } from './service/context-menu.service';
import { DeepLinkService } from './service/deep-link.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'meme-creator-app';

  constructor(private deepLinks : DeepLinkService, private contextMenuService : ContextMenuService, private destroyRef : DestroyRef, public elRef : ElementRef, public vcRef: ViewContainerRef, private http : HttpClient, private sanitizer : DomSanitizer, private iconRegistry : MatIconRegistry, private fontService: FontService){
    //https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/media.danktank.cloud/assets/payment_failed.svg?t=2024-08-09T03%3A56%3A41.850Z
    this.registerIcon(`${environment.storage}media.danktank.cloud/assets/medal.svg`, 'medal');
    this.registerIcon(`${environment.storage}media.danktank.cloud/assets/stripe.svg`, 'stripe');
    this.registerIcon(`${environment.storage}media.danktank.cloud/icons/solid_border.svg`, 'solid_border');
    this.registerIcon(`${environment.storage}media.danktank.cloud/icons/dotted_border.svg`, 'dotted_border');
    this.registerIcon(`${environment.storage}media.danktank.cloud/icons/dashed_border.svg`, 'dashed_border');
    this.fontService.loadFont({ name: 'Anton' });
  }

  private registerIcon(iconUrl : string, iconName : string){
    this.http.get(iconUrl, { responseType: 'text' })
      .pipe(take(1))
      .subscribe({
        next: svgContent => {
          const trustedSvg = this.sanitizer.bypassSecurityTrustHtml(svgContent);
          this.iconRegistry.addSvgIconLiteral(iconName, trustedSvg);
        },
        error: err => {
          console.error(`Failed to load icon ${iconName} from ${iconUrl}:`, err);
        }
      });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    fromEvent<MouseEvent>(this.elRef.nativeElement, 'contextmenu')
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((event: MouseEvent) => {
      event.preventDefault();

      const actions : ContextMenuAction[] = [{label : "About DankTank", scope : this, callback : this.showAbout, icon : 'info'}]
      const coordinates = { x: event.clientX, y: event.clientY };

      this.contextMenuService.showContextMenuWithElement(this.vcRef, actions, coordinates)
    })
  }

  public showAbout(){
    this.deepLinks.showAbout()
  }
}