import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { TopToolbarComponent } from '../top-toolbar/top-toolbar.component';
import { MainContentComponent } from '../main-content/main-content.component';
import { BottomToolbarComponent } from '../bottom-toolbar/bottom-toolbar.component';
import { AppService } from '../../service/app.service';
import { EditMode, ToolbarService } from '../../service/toolbar.service';
import { AdComponent } from '../ad/ad.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-mmodular-dashboard',
  standalone: true,
  imports: [AdComponent, TopToolbarComponent, MainContentComponent, BottomToolbarComponent, AdComponent],
  templateUrl: './mmodular-dashboard.component.html',
  styleUrl: './mmodular-dashboard.component.scss'
})
export class MmodularDashboardComponent {

  constructor(private appService : AppService, private toolbarService : ToolbarService, @Inject(PLATFORM_ID) private platformId: Object){

  }

  resetAsset() {
    if (this.appService.gridSection) this.appService.gridSection.activeAsset = undefined
    this.toolbarService.editModeSubject.next({ mode: EditMode.BASIC })
  }

  public get shouldShowAd():boolean{
    return isPlatformBrowser(this.platformId)
  }

}
