<div class="image-upload-container">

  <mat-expansion-panel mat-dialog-title class="collapse-panel" [expanded]="!imageData.dataURL" style="width: 100% !important;">
      <div class="image-upload-label" (click)="stopMenuPropagation($event)">
          <mat-label>Select Image</mat-label>
          <button mat-button color="primary" (click)="triggerFileInput($event)">Browse</button>
      </div>
      <input [accept]="accepts" type="file" multiple hidden #fileInput (change)="onFileSelected($event)">
      <div class="drop-zone" [class.drag-over]="isDragOver" (click)="triggerFileInput($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
          <mat-icon>upload</mat-icon>
          @if(deviceDetector.isTouchOnlyDevice()){
            <span>Click to browse</span>
          }@else {
            <span>Drag and drop or click to browse</span>
          }
      </div>
  </mat-expansion-panel>

  <mat-expansion-panel mat-dialog-title class="collapse-panel remove-mat-expansion-panel-padding" [expanded]="imageData.dataURL">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>Success</mat-card-title>
      </mat-card-header>
      @if(data.uploadType == UploadType.GIF || data.uploadType == UploadType.IMAGE){
        <img mat-card-image style="margin-top: 10px;" [src]="imageData.dataURL">
      }
      @if(data.uploadType == UploadType.VIDEO){
        <video mat-card-image style="margin-top: 10px;" [src]="imageData.dataURL" loop="true" autoplay="true"></video>
      }
      <mat-card-content></mat-card-content>
      <mat-card-actions align="end">
        <button mat-button (click)="clearImage($event)">Clear</button>
        <button mat-button (click)="chooseImage()">Use</button>
      </mat-card-actions>
    </mat-card>
  </mat-expansion-panel>
</div>