<div mat-dialog-title class="dialog-title">
  <div class="title-holder">
    <h2>Popular Templates</h2>
    <h3>The top memes on DankTank</h3>
  </div>
</div>
<mat-dialog-content class="centered-dialog-content-vertical-top dialog-content">
  @if(!loaded){
    <mat-spinner style="margin: 0 auto !important; margin-top: 25px !important"></mat-spinner>
  }@else{
    <mat-list>
      @for (meme of popularMemes; track meme; let i = $index;) {
          <mat-list-item class="pointer list-item" (click)="addGrid(meme)">
              <div matListItemIcon class="icon-holder">
                  @if(!images[meme.storageReference]){<mat-icon class="loading-icon">pending</mat-icon>}
                  @if(images[meme.storageReference] == ImageLoadStatus.ERROR){<mat-icon>error</mat-icon>}
                  @if(images[meme.storageReference] != ImageLoadStatus.ERROR){
                      <img  #img 
                          [style.position]="images[meme.storageReference] == ImageLoadStatus.LOADED ? 'static' : 'absolute'"
                          (load)="images[meme.storageReference] =  ImageLoadStatus.LOADED; img.style.opacity = '1'"
                          (error)="images[meme.storageReference] =  ImageLoadStatus.ERROR"  
                          class="list-icon fade-img" 
                          [appLazyLoad]="uriFromSticker(meme)"
                          />
                  }
              </div>
              <div matListItemTitle class="subtle-gradient-text"><strong class="focus-text-color">#{{i + 1}}</strong> <span class="regular-text-color">   {{gettickerFromMeme(meme)?.name}}</span></div>
          </mat-list-item>
          @if(i != popularMemes.length){<mat-divider class="divider"></mat-divider>} 
      }
    </mat-list>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Das Wassup</button>
</mat-dialog-actions>