import { Component, Input, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { AppService } from '../../service/app.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DeepLinkService } from '../../service/deep-link.service';
import { ActivatedRoute } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { PaidFeature, PaymentService } from '../../service/payment.service';
import { MatDivider } from '@angular/material/divider';
import { Action, AnalyticsService, AppScreen, ScreenAction } from '../../service/analytics.service';
import { Asset, AssetType, GridSection, Media, MediaType } from '../../vo/GridSection';
import { StickerBrowserComponent } from '../sticker-browser/sticker-browser.component';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from '../../service/device-detector.service';
import { ModalServiceService } from '../../service/modal-service.service';

@Component({
  selector: 'app-app-menu',
  standalone: true,
  imports: [MatDivider, ClipboardModule, MatMenuModule, MatMenuModule, MatIconModule, MatButtonModule, MatTooltipModule, MatChipsModule, MatProgressSpinnerModule],
  templateUrl: './app-menu.component.html',
  styleUrl: './app-menu.component.scss'
})
export class AppMenuComponent {

  public readonly PaidFeature = PaidFeature

  @ViewChild('infoButton') infoButton!: MatMenuTrigger

  private _saving : boolean = false
  @Input() public set saving (val : boolean) {
    this._saving = val
    if(val){
      this.infoButton.openMenu()
    }else{
      this.infoButton.closeMenu()
    }
  }

  public get saving() : boolean {
    return this._saving
  }

  @Input() savedMemes : number = 0

  private _isLandingToolbar : boolean = false
  @Input() public set isLandingToolbar ( val : boolean) {
    this._isLandingToolbar = val
  } 

  public get isLandingToolbar() : boolean {
    return this._isLandingToolbar
  }



  constructor(private modal : ModalServiceService, private deviceDetector : DeviceDetectorService, private dialog : MatDialog, private analytics : AnalyticsService, public appService : AppService, public deepLinks : DeepLinkService, route : ActivatedRoute, public authService : DankTankAuthService, public paymentService : PaymentService){
    deepLinks.route = route
  }

  public menuOpened(){
    const action : Action = {appScreen : AppScreen.HOME, action : ScreenAction.APP_MENU_OPEN}
    this.analytics.track(action)
  }

  public getDataModel():string{
    return ''
  }

  public contactUs(){
    this.deepLinks.showContactUs()
  }

  public showHowTo(){
    this.deepLinks.showHowTo()
  }

  public showAbout(){
    this.deepLinks.showAbout()
  }

  public startGuidedTour(){

  }

  public showPopularMemes(){
    this.deepLinks.showPopularMemes()
  }

  public showLegal(){
    this.deepLinks.showLegal()
  }

  public showDeveloperTools() : boolean{
    return false
  }

  public showSavedMemes(){
    this.deepLinks.showSavedMemes()
  }

  private openMemeBackgroundBrowser(assetType : AssetType, mediaType : MediaType){
    const action : Action = {appScreen : AppScreen.LANDING_PAGE, action : ScreenAction.MODAL_OPENED, info : `${assetType} Browser`}
    this.analytics.track(action)
    const gs : GridSection = new GridSection()
    gs.verticalAspectRatio = gs.horizontalAspectRatio = 1
    const m : Media = {loaded : false, blur : 0, sepia : 0, grey : 0, hue : 0, saturation : 1, brightness: 1, mediaType : mediaType, mediaLocation : '', originalLocation : ''}
    gs.media = m
      const asset : Asset = {type : assetType, data : {x : 0, y :0, visible : true, editing : false}, convertToMedia : m, section : gs}
      this.modal.open(StickerBrowserComponent, { 
        data : asset,
        panelClass : 'panel-class',
      })
  }

  public showMemes(){
    const action : Action = {appScreen : AppScreen.LANDING_PAGE, action : ScreenAction.MODAL_OPENED, info : "MEMES"}
    this.analytics.track(action)
    this.openMemeBackgroundBrowser(AssetType.MEME, MediaType.LOCAL_IMAGE)
  }

  public openGif(){
    const action : Action = {appScreen : AppScreen.LANDING_PAGE, action : ScreenAction.MODAL_OPENED, info : "GIFS"}
    this.analytics.track(action)
    this.openMemeBackgroundBrowser(AssetType.GIF, MediaType.VIDEO)
  }

  

}
