import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, ElementRef, Inject, NgZone, viewChild, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImageResponse, SocialService } from '../../service/social.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AssetType, GridSection, Media, MediaType } from '../../vo/GridSection';
import { DialogRef } from '@angular/cdk/dialog';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { createAssetsFromJSON } from '../../util/AssetCreator';
import { Sticker, StickerPack, StickerPayload, StickerService, StickerServiceResponse } from '../../service/sticker.service';
import { take } from 'rxjs';
import { LazyLoadDirective } from '../../directive/lazy-load.directive';
import { Router } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';
import { environment } from '../../../environments/environment';
import { DankTankAuthService } from '../../service/dank-tank-auth.service';
import { PaidFeature, PaymentService } from '../../service/payment.service';

export interface PopularMeme{
  storageReference : string,
  views : number
}

enum ImageLoadStatus {
  LOADED = "LOADED",
  ERROR = "ERROR",
}


type ImageStatusMap = Record<string, ImageLoadStatus>

@Component({
  selector: 'app-popular-memes', 
  standalone: true,
  imports: [MatChipsModule, LazyLoadDirective, MatMenuModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule, MatDialogModule, MatFormFieldModule, FormsModule, MatInputModule, MatButtonModule, MatListModule],
  templateUrl: './popular-memes.component.html',
  styleUrl: './popular-memes.component.scss'
})
export class PopularMemesComponent implements AfterViewInit { 

  @ViewChild('continueMenu') menu ?: MatMenu

    public readonly ImageLoadStatus = ImageLoadStatus

    public loaded : boolean = false
    public popularMemes : PopularMeme[] = []

    public readonly images: ImageStatusMap = {

    }

    private memePacks ?: StickerPack[]

    constructor(private ngZone : NgZone, private cdr : ChangeDetectorRef, private router : Router, private stickerService : StickerService, private ref : DialogRef, private socialService : SocialService, private destoryRef : DestroyRef, @Inject(MAT_DIALOG_DATA) public data: DashboardComponent, private authService : DankTankAuthService, private subscriptionService : PaymentService){

    }

    ngAfterViewInit(): void {
      this.popularMemes = []

      this.socialService.imageViews()

      this.socialService.viewsSubject.asObservable().pipe(take(1)).subscribe({
        next : (d : ImageResponse) =>{
          if(d.data){
            for(let prop in (d.data as any)){
              this.popularMemes.push({storageReference : prop.split('+').join('.'), views : (d.data as any)[prop]})
            }
            this.popularMemes.sort((a, b) => b.views - a.views)
            
            this.stickerService.getMemePackData().pipe(take(1)).subscribe({
              next : (stickerData : StickerServiceResponse) =>{
                if(stickerData.data){
                    this.memePacks = stickerData.data?.collection
                    this.addTypeToStickers(stickerData.data.collection, 'meme')
                    this.stickerService.getGifPackData().pipe(take(1)).subscribe({
                      next : ((gifData : StickerServiceResponse) => {
                        if(gifData.data){
                          this.addTypeToStickers(gifData.data.collection, 'gif')
                          this.memePacks = this.memePacks?.concat(gifData.data?.collection || [])
                          this.loaded = true
                        }
                      })
                    })
                }
              }
            })
          }
        }
      })
    }

    /*
    public uriFromSticker(meme : PopularMeme, isFull ?: boolean): string {
      if (isFull) {
        return `https://media.danktank.cloud/memes%2Ffull%2F${meme.storageReference}`
      } else {
        return `https://media.danktank.cloud/memes%2Fthumb%2F${meme.storageReference}`
      }
      return ""
    }
      */

    public uriFromSticker(meme : PopularMeme, isFull ?: boolean): string {
      const sticker : Sticker | undefined = this.gettickerFromMeme(meme)
      if (!sticker) return ''
      if (sticker.type == 'meme') {
        if (isFull) {
          return `${environment.storage}media.danktank.cloud/memes%2Ffull%2F${sticker.storageReference}`
        } else {
          return `${environment.storage}media.danktank.cloud/memes%2Fthumb%2F${sticker.storageReference}`
        }
      }else if (sticker.type == 'gif'){
        if (isFull) {
          return `${environment.storage}media.danktank.cloud/gifs%2Fmp4%2F${sticker.storageReference}.mp4?alt=media`
        } else {
          return `${environment.storage}media.danktank.cloud/gifs%2Fthumb%2F${sticker.storageReference}.webp?alt=media`
        }
      }
      return ""
    }

    private addTypeToStickers(packs : StickerPack[], type : 'gif' | 'meme'){
      for(let i = 0; i < (packs?.length || 0); i ++){
        const pack = packs?.[i]
        for(let j = 0; j < (pack?.stickers.length || 0); j ++){
          const sticker = pack?.stickers?.[j]
          sticker.type = type
        }
      }
    }

    public gettickerFromMeme(meme : PopularMeme) : Sticker | undefined{
      for(let i = 0; i < (this.memePacks?.length || 0); i ++){
        const pack = this?.memePacks?.[i]
        for(let j = 0; j < (pack?.stickers.length || 0); j ++){
          const sticker = pack?.stickers?.[j]
          if(sticker?.storageReference.includes(meme.storageReference)){
            console.log("FOUND STICKER")
            return sticker
          }
        }
      }
      return undefined
    }

    public addGrid(popularMeme : PopularMeme){

      this.toOpenSticker = this.gettickerFromMeme(popularMeme)

      if(this.gettickerFromMeme(popularMeme)?.type == 'gif'){
        this.authService.authThen(this.openLater, this, this.subscriptionService.getPro(), PaidFeature.GIFS)
      }else{
        this.openLater()
      }

    }

    private toOpenSticker : Sticker | undefined

    private openLater(){
      if(this.toOpenSticker){

        const format : string = this.toOpenSticker.type == 'gif' ? 'gif' : 'meme'

        this.router.navigate(['generator', 'meme', this.toOpenSticker?.name], { queryParams: { format: format } })
      
        this.ref.close()
      }
    }

}
