import { AfterViewInit, Component, DestroyRef, ElementRef, Output, ViewChild } from '@angular/core';
import { ContextMenuAction, ContextMenuCoordinates } from '../../service/context-menu.service';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { EventEmitter } from 'stream';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { Action } from 'rxjs/internal/scheduler/Action';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-context-menu',
  standalone: true,
  imports: [MatMenuModule, MatButtonModule, MatIconModule, MatDividerModule],
  templateUrl: './context-menu.component.html',
  styleUrl: './context-menu.component.scss'
})
export class ContextMenuComponent implements AfterViewInit {

  @ViewChild('menuTrigger') protected menuTrigger?: MatMenuTrigger

  public menuClosed = new Subject();

  constructor(public elRef : ElementRef, private destroyRef : DestroyRef){}

  protected actions ?: ContextMenuAction[]

  public openWithActions(actions : ContextMenuAction[]){

    this.actions = actions

    requestAnimationFrame(() => {
      this.menuTrigger?.openMenu()
    })
  }

  ngAfterViewInit(): void {
    this.menuTrigger?.menuClosed.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.menuClosed.next(undefined)
    });
  }

  public doAction(action : ContextMenuAction){
    if(action.callback && action.scope)action.callback.call(action.scope);
  }

}
