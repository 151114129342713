import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, ElementRef, ViewChild } from '@angular/core';
import { AssetComponent } from '../asset.component';
import { Mode } from '../../../vo/GridSection';
import { EditMode, ToolbarService } from '../../../service/toolbar.service';
import { from, fromEvent, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { TextInputBottomSheetComponent } from '../../text-input-bottom-sheet/text-input-bottom-sheet.component';

@Component({
  selector: 'app-text-asset',
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatDividerModule, MatInputModule, FormsModule],
  templateUrl: './text-asset.component.html',
  styleUrl: './text-asset.component.scss'
})
export class TextAssetComponent extends AssetComponent implements AfterViewInit {
  
  @ViewChild('txtAsset') public txtAsset ?: any
  @ViewChild('menuTrigger') menuTrigger?: MatMenuTrigger
  private originalScrollPosition: number = 0

  public menuOpened : boolean = false

  
  override ngAfterViewInit(): void {
    super.ngAfterViewInit()
    this.onMoveableAssetSet.emit(this.txtAsset.nativeElement)
    this.keepRatio = false

    /*
    fromEvent(document,  'mousedown').pipe(takeUntilDestroyed(this.destroyRef)).subscribe((e : any) => {
      this.txtAsset.nativeElement.focus()
    })
      */
    

  }

  onEnterPress(e: Event): void {
    const event : KeyboardEvent = (e as KeyboardEvent)
    if (!event.shiftKey) {
      event.preventDefault(); // Prevent the default Enter key behavior (submit)
      // Your submit logic here
    }
    this.menuTrigger?.closeMenu()
  }

  onShiftEnterPress(e: Event): void {
    const event : KeyboardEvent = (e as KeyboardEvent)
    event.preventDefault(); // Prevent the default Enter key behavior (newline)
    const textarea = event.target as HTMLTextAreaElement;
    const currentValue = textarea.value;
    const caretPosition = textarea.selectionStart || 0;
    const newValue = currentValue.substring(0, caretPosition) + '\n' + currentValue.substring(caretPosition);
    textarea.value = newValue;
    // Optionally, you might want to adjust the textarea height based on content size
  }

  override edit(){
    super.edit()
    //if(this.data?.data.text?.toLowerCase() == 'edit me')this.data.data.text = ""
    //this.selectElementContents()
    const scrollElement = this.txtAsset.nativeElement;
    if(this.isPortrait()){
      this.bottomSheet.open(TextInputBottomSheetComponent, {data : this.data, backdropClass : 'clear-backdrop'}).afterDismissed().pipe(take(1)).subscribe(() => {
        //this.txtAsset.nativeElement.scrollTop = this.originalScrollPosition
        //console.log(scrollElement.)
      })
    }else{
      this.menuTrigger?.openMenu()
    }
    this.reselectText()
  }

  override updateActiveAsset(): void {
    super.updateActiveAsset()
    if(this.data){
      if(!this.menuOpened)this.toolbarService.editModeSubject.next({mode : EditMode.TEXT, asset : this.data})
    }
  }

  @ViewChild('txtAsset') txt ?: any;
   selectElementContents() {
    const element = this.txt.nativeElement;
    element.contentEditable = true;
    element.focus();

    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(element);
    range.collapse(false); // Collapse to the end

    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  set text(val : string){
    if(this.data?.data)this.data.data.text = val
    this.reselectText()
  }
  
  //still dont get why we need this
  public reselectText(recursive : boolean = false){
    setTimeout(() => {
      this.resetActiveAsset(this.menuOpened)
      this.updateActiveAsset()
      if(!recursive)setTimeout(() => {
        this.reselectText(true)
      }, 105);
    }, 1);
  }

  get text() : string{
    return this.data?.data.text || ''
  }

  public focusMenu(textArea : HTMLTextAreaElement){
    setTimeout(() => {
      textArea.focus()
    });
  }

  updateText(val ?: string){
    if(this.data?.data){
      const txt : string = val ? val : this.txtAsset.nativeElement.innerText
      if(txt && txt.length > 0){
        this.data.data.text = txt
      }else{
        this.data.data.text = 'EDIT ME'
        this.cdr.detectChanges()
      }
    }
  }



  isPortrait(): boolean {
    const mediaQueryList = window.matchMedia('(orientation: portrait)');
    return mediaQueryList.matches;
  }

  get triggerLocation(): { x: number, y: number } {
    const matrix = new DOMMatrix(this.data?.data.transform);

    const tx : number = matrix.m41
    const ty : number = matrix.m42
    
    if(this.isPortrait()){
      return {x : ((this.data?.data?.x || 0) * -1) + tx, y : ((this.data?.data?.y || 0) * -1) + ty}
    }else{
      return {x : tx, y : ty}
    }
  }

}
