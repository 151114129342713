import { Injectable } from '@angular/core';
import { GridSection } from '../vo/GridSection';
import { parseGridSection } from '../util/ParseUtil';
import { EditMode, ToolbarService } from './toolbar.service';
import { FontService } from './font.service';
import { KeyboardService, KeyboardShortcut } from './keyboard.service';
import { AppService } from './app.service';
import { Action, AnalyticsService, AppScreen, ScreenAction } from './analytics.service';

export enum HistoryRecordType {
  ASSET_PROPERTY = 1,
  MEDIA = 2,
  ASSET = 3,
  SECTION_PROPERTY = 4
}

export interface HistoryRecord {
  type: HistoryRecordType;
  property?: string;
  data: GridSection;
}

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  public history: HistoryRecord[] = [];
  public historyIndex: number = -1; // Start with -1 for no initial state
  private queuedHistoryEntry?: HistoryRecord;

  constructor(
    private analytics: AnalyticsService,
    private appService: AppService,
    private toolbarService: ToolbarService,
    private fontService: FontService,
    private keyboardService: KeyboardService
  ) {
    this.keyboardService.registerCallback(KeyboardShortcut.UNDO, this.undo, this);
    this.appService.initialGridReady.asObservable().subscribe(() => {
      this.createHistoryEntry(HistoryRecordType.SECTION_PROPERTY);
    });
  }

  public createHistoryEntry(type: HistoryRecordType, property?: string) {
    const gs = this.appService.gridSection;
    gs?.prepareSerialize();
    const serialized = JSON.parse(JSON.stringify(this.appService.gridSection));
    const toAdd: GridSection = parseGridSection(serialized, this.toolbarService, this.fontService);
    gs?.reapplyCyclicals();
    toAdd.reapplyCyclicals();

    const lastHistoryItem = this.history[this.history.length - 1];
    
    if (lastHistoryItem && property !=undefined && lastHistoryItem.property != undefined && lastHistoryItem.property === property) {
        this.history[this.history.length - 1] = { type, data: toAdd || new GridSection(), property };
    } else {
        this.history.length = this.historyIndex + 1
        this.history.push({ type, data: toAdd || new GridSection(), property });
        this.historyIndex++;
    }
}

  public queueHistoryEntry(type: HistoryRecordType, property?: string) {
    const gs = this.appService.gridSection;
    gs?.prepareSerialize();
    const serialized = JSON.parse(JSON.stringify(this.appService.gridSection));
    const toAdd: GridSection = parseGridSection(serialized, this.toolbarService, this.fontService);
    gs?.reapplyCyclicals();
    this.queuedHistoryEntry = { type, data: toAdd || new GridSection(), property };
  }

  public applyQueuedHistoryEntry(property: string) {
    const lastHistoryItem = this.history[this.history.length - 1];
    if (!lastHistoryItem || lastHistoryItem.property !== property) {
      if (this.queuedHistoryEntry) {
        this.history.length = this.historyIndex + 1; // Trim history to current index
        this.history.push(this.queuedHistoryEntry);
        this.historyIndex++;
      }
    }
  }

  public outputHistory(){
    this.history.forEach((item : HistoryRecord) => {
      console.log(item.data)
    })
  }

  public undo() {
    if (this.historyIndex > 0) {
      this.outputHistory()
      this.historyIndex--;
      const gs = this.history[this.historyIndex].data;
      setTimeout(() => {
        this.appService.gridSectionSimple = this.getClone(gs);
        this.toolbarService.editModeSubject.next({ mode: EditMode.BASIC });
        this.outputHistory()
      }, 100);
      //this.analytics.track({ appScreen: AppScreen.HOME, action: ScreenAction.CLICK, info: 'Undo' });
    }
  }

  public redo() {
    if (this.historyIndex < this.history.length - 1) {
      this.outputHistory()
      this.historyIndex++;
      const gs = this.history[this.historyIndex].data;
      setTimeout(() => {
        this.appService.gridSectionSimple = this.getClone(gs);
        this.toolbarService.editModeSubject.next({ mode: EditMode.BASIC });
        this.outputHistory()
      }, 100);
    }
  }

  public reverse() {
    for (let i = this.history.length - 1; i >= 0; i--) {
      if (this.history[i] && this.history[i].type === HistoryRecordType.ASSET) {
        this.history.length = i;
        break;
      }
    }
  }

  public getClone(gs : GridSection) : GridSection{
    gs?.prepareSerialize();
    const serialized = JSON.parse(JSON.stringify(gs));
    const toReturn: GridSection = parseGridSection(serialized, this.toolbarService, this.fontService);
    gs?.reapplyCyclicals();
    toReturn.reapplyCyclicals();
    return toReturn
  }
}