@if (data) {
@if(data.isRootGrid){
@if(authService.subscriptionUserSubject.value == null ||
(authService.subscriptionUserSubject.value.data?.subscription?.type == SubscriptionType.NONE ||
(authService.subscriptionUserSubject.value.data?.subscription?.status != SubscriptionStatus.ACTIVE && authService.subscriptionUserSubject.value.data?.subscription?.status != SubscriptionStatus.FREE))){
<img #watermark [style.width.px]="data.rootGridSectionWidth * .25 > 150 ? 150 : data.rootGridSectionWidth * .25"
    src="https://hhopznfvcfwimeaxsktv.supabase.co/storage/v1/object/public/media.danktank.cloud/logos/logo-300_x_300.png"
    (load)="watermark.style.opacity = '1'" class="watermark no-pointer no-select">
}
}

<div #assets class="content no-pointer" style="pointer-events: none;" [style.mix-blend-mode]="blendMode">
    @if((data.assets.length || 0 > 0) && !loading){
    @for (asset of data.assets; track asset; let i = $index;) {
    <app-asset #parent [data]="asset" [style.left.px]="asset.data.x" [style.top.px]="asset.data.y" class="asset"
        [style.z-index]="data.assets.indexOf(asset) + 1" [style.mix-blend-mode]="blendMode">
        @if(asset.type == AssetType.TEXT){
        <app-text-asset #assetChild [data]="asset"
            [style.display]="asset.data.display"
            (onMoveableAssetSet)="parent.moveableAsset = $event; parent.assetChild = assetChild"></app-text-asset>
        }
        @if(asset.type == AssetType.STICKER || asset.type == AssetType.MEME || asset.type == AssetType.EMOJI ||
        asset.type == AssetType.UPLOAD_IMAGE || asset.type == AssetType.SHAPE){
        <app-sticker-asset #assetChild [data]="asset"
            [style.display]="asset.data.display"
            (onMoveableAssetSet)="parent.moveableAsset = $event; parent.assetChild = assetChild"></app-sticker-asset>
        }
        @if(asset.type == AssetType.TEXT_BUBBLE){
        <app-text-bubble-asset #assetChild [data]="asset" style="width: 10000px; height: 1000px;"
            [style.display]="asset.data.display"
            (onMoveableAssetSet)="parent.moveableAsset = $event; parent.assetChild = assetChild"></app-text-bubble-asset>
        }
    </app-asset>
    }
    }
</div>
@if (data && data.children.length == 0 && data.media?.mediaLocation) {
@if(!editing){
        @if(data.mode != Mode.VIEWING && !loading && data.isRootGrid){
            <ngx-moveable
                class="fade-in"
                [origin]="true"
                [edge]="true"
                [target]="forehead"
                [resizable]="true"
                (resizeStart)="resizeStart()"
                (resizeEnd)="resizeEnd()"
                (resize)="onResize($event)"
                [renderDirections]='["s"]'
                [useResizeObserver]="true"
                [useMutationObserver]="true"
                [hideDefaultLines]="true"
            ></ngx-moveable>
        }
        <div #forehead  [style.height.px]="data.forehead" class="forehead"></div>
            
        @if((snapshotImage || data.media?.mediaLocation) && data.media?.mediaType == MediaType.LOCAL_IMAGE ||
        data.media?.mediaType == MediaType.LOCAL_ANIMATED_GIF || snapshotMode == true){
        <img #backgroundImg class="fade-img transition-media no-select" [class]="data.mode == Mode.DEVELOPMENT ? 'no-pointer' : 'no-pointer'" draggable="false"
            [style.opacity]="loading ? 0 : 1"
            (load)="bgImageLoaded(); backgroundImg.alt = data.media?.name + ' meme on DankTank meme creator'"
            [style.filter]="'grayscale('+data.media?.grey+') sepia('+data.media?.sepia+') hue-rotate('+data.media?.hue+'deg) saturate('+data.media?.saturation+') brightness('+data.media?.brightness+') blur('+data.media?.blur+'px)'"
            class="media" [src]="snapshotImage || data.media?.mediaLocation">
        }
        @if(data.media?.mediaType == MediaType.VIDEO){
        <video #video (loadeddata)=" bgMediaLoaded()" class="media transition-media" playsinline="true" autoplay="true" crossorigin="anonymous"
            [style.opacity]="loading ? 0 : 1"
            [class]="data.mode == Mode.DEVELOPMENT ? 'pointer' : 'no-pointer'" [style.display]="snapshotMode ? 'none' : null"
            [style.filter]="'grayscale('+data.media?.grey+') sepia('+data.media?.sepia+') hue-rotate('+data.media?.hue+'deg) saturate('+data.media?.saturation+') brightness('+data.media?.brightness+') blur('+data.media?.blur+'px)'"
            class="media" [src]="data.media?.mediaLocation"></video>
        }
}
}
@if(loading && data.mode != Mode.VIEWING){<mat-spinner [diameter]="50"
    class="loader"></mat-spinner>}
@if (data && data.children.length == 0 && !data.media?.mediaLocation && data.mode != Mode.VIEWING) {
<mat-icon [matMenuTriggerFor]=" menu" (click)="next()" [style.color]="data.borderColor"
    [style.font-size.px]="data.rootGridSectionWidth * .1 > 48 ? 48 : (data.rootGridSectionWidth * .1 < 10 ? 10 : data.rootGridSectionWidth * .1)"
    class="big-icon pointer add-image" style="z-index: 1001;" >
    add_photo_alternate
</mat-icon>
<mat-menu #menu class="centered-dropdown" yPosition="above" (closed)="reset()">
    @if(breadcrumbs.length > 0){
    <mat-chip-set class="breadcrumbs">
        @for (breadcrumb of breadcrumbs; track breadcrumb; let i = $index;) {
        <mat-chip class="breadcrumb" (click)="back(breadcrumb, $event)">{{breadcrumb?.toString()}}</mat-chip>
        @if(i != breadcrumbs.length - 1){
        <mat-icon class="breadcrumb-text">chevron_right</mat-icon>
        }
        }
    </mat-chip-set>
    }

    @if(currentStage == Stages.CHOOSE_TYPE){
    <mat-form-field appearance="outline" (click)="$event.stopPropagation();">
        <mat-label>Image</mat-label>
        <mat-select [value]="currentStage">
            <mat-option (click)="action(Stages.MEMMES)"
                [value]="Stages.MEMMES">{{Stages.MEMMES.toString()}}</mat-option>
            @if(data.isRootGrid){
            <mat-option (click)="authService.authThen(setGifsAction, this, this.paymentService.getPro(), PaidFeature.GIFS)"
                [value]="Stages.GIFS">{{Stages.GIFS.toString()}}</mat-option>
            }
            <mat-option (click)="action(Stages.UPLOAD_IMAGE)"
                [value]="Stages.UPLOAD_IMAGE">{{Stages.UPLOAD_IMAGE.toString()}}</mat-option>
            @if(data.isRootGrid){
            <!--
            <mat-option (click)="action(Stages.UPLOAD_GIF)"
                [value]="Stages.UPLOAD_GIF">{{Stages.UPLOAD_GIF.toString()}}</mat-option>
                -->
            <mat-option (click)="authService.authThen(setUploadMP4Action, this, this.paymentService.getPro(), PaidFeature.GIFS)"
                [value]="Stages.UPLOAD_MP4">{{Stages.UPLOAD_MP4.toString()}}</mat-option>
            }
        </mat-select>
    </mat-form-field>
    }
</mat-menu>
}
@if(data.media && data.media.mediaLocation && data.mode != Mode.VIEWING){


<mat-icon #imageTrigger="matMenuTrigger" class="delete hover-icon big-icon"
    [matMenuTriggerFor]="imageMenu" matTooltip="Edit Image" matTooltipPosition="below"
    [style.font-size.px]="data.rootGridSectionWidth * .1 > 48 ? 48 : (data.rootGridSectionWidth * .1 < 10 ? 10 : data.rootGridSectionWidth * .1)"
    [style.color]="iconColor" style="-webkit-text-stroke: .5px black">
    edit
</mat-icon>
<mat-menu #imageMenu style="z-index: 1001;">
    @if(data.media.mediaType == MediaType.LOCAL_IMAGE){
    <button mat-menu-item (click)="startEditing()">
        <mat-icon>edit</mat-icon>
        <span>Edit Size</span>
    </button>
    }
    @if(data.isRootGrid){
    <button mat-menu-item (click)="deleteBackgroundAndAssets()">
        <mat-icon>refresh</mat-icon>
        <span>Delete Background and Assets</span>
    </button>
    }
    <button mat-menu-item (click)="deleteBackground()">
        <mat-icon>delete</mat-icon>
        <span>Delete Background</span>
    </button>
</mat-menu>

}
}


@if((data && data.children) ? data.children.length > 0 : false){
<div [class]="data?.orientation == Orientation.HORIZONTAL ? 'horizontal-parent' : 'vertical-parent'">
    @for (gridSection of data?.children; track gridSection; let idx = $index) {
        <app-grid-section style="z-index: -5000 !important;" [style.backgroundColor]="data?.backgroundColor"
            [style.width]="data?.mode == Mode.VIEWING && data?.orientation == Orientation.HORIZONTAL && idx == (data?.children?.length || 0) - 1 ? calcValue(gridSection.width, 20 - gridSection.borderWidth) : gridSection.width +  '%'"
            [style.height]="data?.mode == Mode.VIEWING && data?.orientation == Orientation.VERTICAL && idx == (data?.children?.length || 0) - 1 ? calcValue(gridSection.height, 20 - gridSection.borderWidth) : gridSection.height + '%'"
            [data]="gridSection" class="grid-section">
        </app-grid-section>
        @if(data && idx != data.children.length - 1 && !deviceDetector.isTouchOnlyDevice()){
            <div (mousedown)="startDrag(idx, $event)" (mouseup)="stopDrag()" [style.backgroundColor]="data.borderColor"
                [style.width]="data && data.orientation == Orientation.HORIZONTAL ? data.mode == Mode.VIEWING  ? '0px' : '20px' : '100%'"
                [style.maxWidth]="data && data.orientation == Orientation.HORIZONTAL ? data.mode == Mode.VIEWING  ? '0px' :  '20px' : '100%'"
                [style.height]="data && data.orientation == Orientation.HORIZONTAL ? '100%' : data.mode == Mode.VIEWING  ? '0px' :  '20px'"
                [style.maxHeight]="data && data.orientation == Orientation.HORIZONTAL ? '100%' : data.mode == Mode.VIEWING  ? '0px' :  '20px'"
                class="handle">
                @if(data.mode == Mode.DEVELOPMENT){
                <mat-icon
                    [class]="data.orientation == Orientation.HORIZONTAL ? 'h-dragger' : 'v-dragger'">drag_indicator</mat-icon>
                }
            </div>
            @if(data.mode == Mode.VIEWING){
                <div [style.borderColor]="data.borderColor"
                    [style.width]="data && data.orientation == Orientation.HORIZONTAL ? '0px' : '100%'"
                    [style.height]="data && data.orientation == Orientation.HORIZONTAL ? '100%' : '0px'"
                    [style.borderWidth.px]="data.borderWidth" [style.borderTopStyle]="data.borderStyle"
                    [style.borderLeftStyle]="data.borderStyle"></div>
            }
        }
        @if(data && idx != data.children.length - 1 && deviceDetector.isTouchOnlyDevice()){
            <div (touchstart)="startDrag(idx, $event)" (touchend)="stopDrag()" [style.backgroundColor]="data.borderColor"
                [style.width]="data && data.orientation == Orientation.HORIZONTAL ? data.mode == Mode.VIEWING  ? '0px' : '20px' : '100%'"
                [style.maxWidth]="data && data.orientation == Orientation.HORIZONTAL ? data.mode == Mode.VIEWING  ? '0px' :  '20px' : '100%'"
                [style.height]="data && data.orientation == Orientation.HORIZONTAL ? '100%' : data.mode == Mode.VIEWING  ? '0px' :  '20px'"
                [style.maxHeight]="data && data.orientation == Orientation.HORIZONTAL ? '100%' : data.mode == Mode.VIEWING  ? '0px' :  '20px'"
                class="handle">

                @if(data.mode == Mode.DEVELOPMENT){
                    <mat-icon
                        [class]="data.orientation == Orientation.HORIZONTAL ? 'h-dragger' : 'v-dragger'">drag_indicator</mat-icon>
                }
            </div>

            @if(data.mode == Mode.VIEWING){
                <div [style.borderColor]="data.borderColor"
                    [style.width]="data && data.orientation == Orientation.HORIZONTAL ? '0px' : '100%'"
                    [style.height]="data && data.orientation == Orientation.HORIZONTAL ? '100%' : '0px'"
                    [style.borderWidth.px]="data.borderWidth" [style.borderTopStyle]="data.borderStyle"
                    [style.borderLeftStyle]="data.borderStyle"></div>
            }
        }
    }
</div>
}