import { Injectable } from '@angular/core';
import { DeviceDetectorService } from './device-detector.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { DialogConfig, DialogRef } from '@angular/cdk/dialog';

@Injectable({
  providedIn: 'root'
})
export class ModalServiceService {

  constructor(private deviceDetector : DeviceDetectorService, private dialog : MatDialog) { }

    public open<T, D = any>(component: ComponentType<T>, config?: MatDialogConfig<D>): MatDialogRef<T, D> {

      if (!config) {
          config = {}
      }

      const wh = this.getResponsiveConfig()

      config.maxHeight = config.maxWidth = config.minWidth = config.minHeight = undefined
      config.width = config.maxWidth = wh.width
      config.height = config.maxHeight = wh.height
      


      return this.dialog.open<T, D>(component, config)
  }

  private getResponsiveConfig(): { width: string; height: string } {
    const width = window.innerWidth
    const height = window.innerHeight
  
    if (width < 768) {
      return {
        //this is because safari mobile's dumb fucking bottom address bar blocks almost 100 pixels of screen space in modals?????
        //height: this.deviceDetector.isMobileSafari() ? 'calc(100vh - 80px)' : '100vh',
        height: '100%',
        width: '100vw'
      }
    } else if (width >= 768 && width < 1024) {
      return {
        height: '90vh', // 90% of viewport height for tablets
        width: '90vw'   // 90% of viewport width for tablets
      }
    } else {
      return {
        height: '80vh', // 60% of viewport height for large screens
        width: '60vw'   // 80% of viewport width for large screens
      }
    }
  }
  
}
