<div class="root-grid-holder" #rootGridHolder id="rootGridHolder">
    @if(appService.gridSection && appService.ready){
    <app-grid-section id="rootGrid" #rootGrid 
        (videoReady)="appService.video = $event" (imageReady)="appService.image = $event"
        [class]="!rootGrid.resizing ? 'root-grid-transitions' : null"
        [style.borderRadius.px]="10"
        [style.backgroundColor]="appService.gridSection.backgroundColor" [style.borderStyle]="appService.gridSection.borderStyle"
        [style.width.px]="appService.getMeasurements(rootGrid, rootGridHolder).width" [style.height.px]="appService.getMeasurements(rootGrid, rootGridHolder).height"
        [style.borderColor]="appService.gridSection.borderColor" [style.borderWidth.px]="appService.gridSection.borderWidth"
        style="border-style: solid;" [data]="appService.gridSection" class="root-grid">
    </app-grid-section>
    }

</div> 

<!--        (videoReady)="video = $event" (imageReady)="image = $event"     -->