import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, Output, EventEmitter, ViewChild, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-inline-svg',
  standalone: true,
  imports: [],
  templateUrl: './inline-svg.component.html',
  styleUrls: ['./inline-svg.component.scss']
})
export class InlineSVGComponent implements OnInit, AfterViewInit {

  private _svgUrl?: string;
  @Input() set svgUrl(val: string | undefined) {
    this._svgUrl = val;
    if (this.ready) {
      this.loadSvg();
    }
  }
  get svgUrl(): string | undefined {
    return this._svgUrl;
  }
  @Input() svgFilter?: string;
  @Output() load = new EventEmitter<boolean>();
  @ViewChild('svgContainer', { static: true }) svgContainer?: ElementRef<HTMLDivElement>;

  private ready: boolean = false;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.ready = true;
  }

  ngAfterViewInit(): void {
    if (this.svgUrl) {
      this.loadSvg();
    }
  }

  loadSvg(): void {
    if (!this.svgUrl || !this.svgContainer) {
      return;
    }
  
    this.http.get(this.svgUrl, { responseType: 'text' }).subscribe((svgData) => {
      if (this.svgContainer) {
        this.svgContainer.nativeElement.innerHTML = svgData;
        this.svgContainer.nativeElement.style.opacity = '1';
  
        // Set pointer events to none for the container
        this.svgContainer.nativeElement.style.pointerEvents = 'none';
  
        // Handle aspect ratio (consider both max-width and max-height):
        const svg = this.svgContainer.nativeElement.querySelector('svg');
  
        if (svg) {
          const svgWidth = svg.clientWidth
          const svgHeight = svg.clientHeight
  
          if (svgWidth > 0 && svgHeight > 0) {
           if(svgWidth > svgHeight){
            svg.style.width = '100%'
            svg.style.height = 'auto'
           }else{
            svg.style.width = 'auto'
            svg.style.height = '100%'
           }

          } else {
            svg.style.widows = svg.style.height = '100%x`x'
            // Handle cases where SVG doesn't have explicit width/height
            console.warn('SVG does not have explicit width or height attributes. Consider adding them for better aspect ratio control.');
          }
  
          // Find all paths and shapes for pointer events (optional):
          const paths = this.svgContainer.nativeElement.querySelectorAll('path, circle, rect, polygon');
          paths.forEach((path: Element) => {
            (path as SVGElement).style.pointerEvents = 'auto';
            (path as SVGElement).style.cursor = 'pointer';
          });
  
          this.load.emit(true);
        } else {
          console.warn('Failed to find SVG element within container.');
        }
      } else {
        console.warn('svgContainer is null or undefined');
      }
    });
  }
}