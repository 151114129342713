import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Asset, GridSection } from '../../vo/GridSection';
import { DeviceDetectorService } from '../../service/device-detector.service';

export interface ImageData {
  dataURL?: string;
  fileName?: string;
  uploadType : UploadType
  section ?: GridSection
  asset ?: Asset
}

export enum UploadType{
  IMAGE = 1,
  VIDEO = 2,
  GIF = 3
}

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [MatFormFieldModule, MatCardModule, MatIconModule, MatButtonModule, MatInputModule, MatExpansionModule],
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
  imageData: ImageData  
  isDragOver = false
  public accepts : string;
  readonly UploadType = UploadType

  @Output() imageChosen = new EventEmitter<ImageData>();

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: ImageData, public deviceDetector : DeviceDetectorService){
    this.imageData = data
    switch(data.uploadType){
      case UploadType.GIF:
        this.accepts = '.gif'
        break
      case UploadType.IMAGE:
        this.accepts = 'image/*'
        break
      case UploadType.VIDEO:
        this.accepts = 'video/mp4'
        break
    }
  }

  chooseImage() {
    this.imageChosen.emit(this.imageData);
    this.clearImage();
  }
  
  onFileSelected(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.imageData.fileName = file.name;

      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.imageData.dataURL = e.target?.result as string;
      }
      reader.onerror = (e) => {
        console.error('Error reading file:', e);
      }
    } else if (event.dataTransfer && event.dataTransfer.files[0]) { // Handling dropped files
      const file = event.dataTransfer.files[0];
      this.imageData.fileName = file.name;

      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.imageData.dataURL = e.target?.result as string;
      };
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault(); // Prevent default drag behavior
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault(); // Prevent default behavior (Prevent file from being opened)
    this.isDragOver = false;
    this.onFileSelected(event); // Handle dropped file the same way
  }

  clearImage(event?: MouseEvent) {
    event?.stopPropagation();
    this.imageData.dataURL = undefined;
    this.imageData.fileName = undefined;
  }

  @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;

  triggerFileInput(event: MouseEvent) {
    event.stopPropagation();
    this.fileInput?.nativeElement.click();
  }

  stopMenuPropagation(event: Event) {
    event.stopImmediatePropagation(); // Prevent menu closure on clicks
  }
}