@if(authService.getUser() | async; as user){
        <img 
        #userButton="matMenuTrigger" class="da-button" mat-fab [matTooltip]="user.user_metadata['full_name']" [matMenuTriggerFor]="userMenu"
        #avatar referrerpolicy="no-referrer" class="fade-img rounded-img pointer" (load)="avatar.style.opacity = '1'" [src]="user.user_metadata['avatar_url']">
        <!--
        <button #userButton="matMenuTrigger" class="da-button" mat-fab [matTooltip]="user.user_metadata['full_name']" [matMenuTriggerFor]="userMenu">
            <img #avatar referrerpolicy="no-referrer" class="fade-img rounded-img" (load)="avatar.style.opacity = '1'" [src]="user.user_metadata['avatar_url']">
        </button>
        -->
        <mat-menu #userMenu class="profile-dropdown"  xPosition="before">
            <img #avatarBig referrerpolicy="no-referrer" class="fade-img rounded-img-big" (load)="avatarBig.style.opacity = '1'" [src]="user.user_metadata['avatar_url']">
            <mat-divider></mat-divider>
            <div class="welcome-text subtle-gradient-text">
                Welcome, {{user.user_metadata['full_name'].split(' ')[0]}}
            </div>
            <mat-divider></mat-divider>
            @if(authService.subscriptionUserSubject.asObservable() | async; as subscriptionUser){
            <button mat-fab extended (click)="openProfile(user, subscriptionUser.data)">
                User Profile
                <mat-icon>account_circle</mat-icon>
            </button>
            <mat-divider></mat-divider>
        }
            <button mat-fab extended (click)="authService.logout()" style="width: 100% !important;">
                Logout
                <mat-icon>logout</mat-icon>
            </button>
        </mat-menu>
}@else {
    @if(isReady){
        <mat-icon class="pointer"  mat-fab matTooltip="Login" (click)="login()">account_circle</mat-icon>
    }@else{
        <mat-spinner diameter="25"></mat-spinner>
    }

}